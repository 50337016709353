import React from 'react';
import Wizard, { WizardItem } from '../../components/Wizard';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
// import Label from '../../components/bootstrap/forms/Label';
import Input from '../../components/bootstrap/forms/Input';
import Textarea from '../../components/bootstrap/forms/Textarea';
import Select from '../../components/bootstrap/forms/Select';

const CreateProfile = () => {
	return (
		<div className='container py-20'>
			<h1 className='py-3'>Create Your Profile</h1>
			<Wizard isHeader={true} stretch={true}>
				<WizardItem title='Basic Details'>
					<div className='md:grid grid-cols-2 gap-5 md:mx-5'>
						<div className='pb-5'>
							<h5>Name</h5>
							<FormGroup>
								<Input size='lg' type='text' placeholder='Enter Your Name' />
							</FormGroup>
						</div>

						<div className='pb-5'>
							<h5>Phone Number</h5>
							<FormGroup>
								<Input
									size='lg'
									type='number'
									placeholder='Enter Your Phone Number'
								/>
							</FormGroup>
						</div>
					</div>

					<div className='md:mx-5'>
						<div className='pb-5'>
							<h5>Bio</h5>
							<FormGroup>
								{/* <Textarea
									className='h-5'
									size={'lg'}
									type='text'
									placeholder='Enter Your Name'
								/> */}
								<Textarea size='lg' type='text' placeholder='Enter Your Name' />
							</FormGroup>
						</div>
					</div>

					<div className='md:grid grid-cols-2 gap-5 md:mx-5'>
						<div className='pb-5'>
							<h5>Gender</h5>
							<FormGroup>
								<Select
									placeholder='Choose Your Gender'
									value={['Male', 'Female']}
									size='lg'
									list={['Male', 'Female']}
								/>
							</FormGroup>
						</div>

						<div className='pb-5'>
							<h5>Date of Birth</h5>
							<FormGroup>
								<Input
									size='lg'
									type='date'
									placeholder='Enter Your Phone Number'
								/>
							</FormGroup>
						</div>
					</div>

					<div className='md:grid grid-cols-2 gap-5 md:mx-5'>
						<div className='pb-5'>
							<h5>Height</h5>
							<FormGroup>
								<Input size='lg' type='number' placeholder='Enter Your Height' />
							</FormGroup>
						</div>

						<div className='pb-5'>
							<h5>Weight</h5>
							<FormGroup>
								<Input size='lg' type='number' placeholder='Enter Your Weight' />
							</FormGroup>
						</div>
					</div>

					<div className='md:grid grid-cols-2 gap-5 md:mx-5'>
						<div className='pb-5'>
							<h5>Income Range</h5>
							<FormGroup>
								<Input
									size='lg'
									type='range'
									min={0}
									max={100}
									step={10}
									placeholder='Enter Your Height'
								/>
							</FormGroup>
						</div>

						<div className='pb-5'>
							<h5>RelationShip Status</h5>
							<Select
								placeholder='Choose Your RelationShip Status'
								value={['Male', 'Female']}
								size='lg'
								list={['Male', 'Female']}
							/>
						</div>
					</div>
				</WizardItem>
				<WizardItem title='Personal Details'>
					<div className='md:mx-5'>
						<div className='pb-5'>
							<h5>Images</h5>
							<FormGroup>
								<Input size='lg' type='file' />
							</FormGroup>
						</div>
					</div>

					<div className='md:grid grid-cols-2 gap-5 md:mx-5'>
						<div className='pb-5'>
							<h5>Religion</h5>
							<FormGroup>
								<Input size='lg' type='text' placeholder='Enter Your Religion' />
							</FormGroup>
						</div>

						<div className='pb-5'>
							<h5>Mother Toungue</h5>
							<FormGroup>
								<Input
									size='lg'
									type='text'
									placeholder='Enter Your Mother Toungue'
								/>
							</FormGroup>
						</div>
					</div>

					<div className='md:grid grid-cols-2 gap-5 md:mx-5'>
						<div className='pb-5'>
							<h5>Caste</h5>
							<Select
								placeholder='Choose Your Caste'
								value={['Male', 'Female']}
								size='lg'
								list={['Male', 'Female']}
							/>
						</div>

						<div className='pb-5'>
							<h5>Sub Caste</h5>
							<FormGroup>
								<Select
									placeholder='Choose Your Sub Caste'
									value={['Male', 'Female']}
									size='lg'
									list={['Male', 'Female']}
								/>
							</FormGroup>
						</div>
					</div>

					<div className='md:grid grid-cols-2 gap-5 md:mx-5'>
						<div className='pb-5'>
							<h5>Gotra</h5>
							<Select
								placeholder='Choose Your Gotra'
								value={['Male', 'Female']}
								size='lg'
								list={['Male', 'Female']}
							/>
						</div>

						<div className='pb-5'>
							<h5>Star</h5>
							<FormGroup>
								<Select
									placeholder='Choose Your Star'
									value={['Male', 'Female']}
									size='lg'
									list={['Male', 'Female']}
								/>
							</FormGroup>
						</div>
					</div>

					<div className='md:grid grid-cols-2 gap-5 md:mx-5'>
						<div className='pb-5'>
							<h5>Kuja Dosh</h5>
							<Select
								placeholder='Choose Your Response'
								value={['Male', 'Female']}
								size='lg'
								list={['Male', 'Female']}
							/>
						</div>
					</div>
				</WizardItem>
			</Wizard>
		</div>
	);
};

export default CreateProfile;
