import React, { useContext, useRef, useState, useEffect } from 'react';

// import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AsideBar from '../customLayout/AsideBar';
// import CustomHeader from '../customLayout/Header';
import ThemeContext from '../../contexts/themeContext';
import DashboardHeader from '../_layout/_headers/DashboardHeader';
import axios from 'axios';
import Button from '../../components/bootstrap/Button';
import Wizard, { WizardItem } from '../../components/Wizard';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
// import Label from '../../components/bootstrap/forms/Label';
import Input from '../../components/bootstrap/forms/Input';
import Textarea from '../../components/bootstrap/forms/Textarea';
import Select from '../../components/bootstrap/forms/Select';
import Alert, { AlertHeading } from '../../components/bootstrap/Alert';
import Card, { CardBody } from '../../components/bootstrap/Card';
import Icon from '../../components/icon/Icon';
import Progress from '../../components/bootstrap/Progress';
import { Options } from '../../components/bootstrap/Option';

import states from '../../data/states.json';
import districts from '../../data/states-and-districts.json';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import imageCompression from 'browser-image-compression';

import upload from '../../assets/custom/images/addimage.png';
import {
	CircularProgressbar,
	CircularProgressbarWithChildren,
	buildStyles,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { ShimmerContentBlock } from 'react-shimmer-effects';

// Animation
import { easeQuadInOut } from 'd3-ease';
import AnimatedProgressProvider from './AnimatedProgressProvider';
import ChangingProgressProvider from './ChangingProgressProvider';

import Chart from '../../components/extras/Chart.tsx';

import { ThreeDots } from 'react-loader-spinner';

// C:\Users\thril\Desktop\BRAMHIN MATRIMONY\Bramhin-Matrimony-Frontend\src\components\extras\Chart.tsx
const Dashboard = () => {
	const navigate = useNavigate();

	const [profileDataFromDB, setProfileDataFromDB] = useState({
		name: '',
		dateOfBirth: '',
		phone: '',
		address: '',
		experience: {
			workTitle: '',
		},
	});

	const [staticData, setStaticData] = useState({
		education: [],
		occupation: [],
		subCastes: [],
		gotras: [],
		nakshatras: [],
		zodaicSigns: [],
		languages: [],
	});

	const stateList = states
		.filter((e) => {
			return e.country_name == 'India';
		})
		.map((e) => e.name);

	const getDistrictsList = () => {
		try {
			if (profileBuildingDetails.state) {
				return districts.states.filter((e) => {
					return e.state == profileBuildingDetails.state;
				})[0].districts;
			} else {
				return [];
			}
		} catch {
			return [];
		}
	};

	let baseUrl = '';
	const [userId, setUserId] = useState('');
	switch (process.env.REACT_APP_STATUS) {
		case 'DEV':
			baseUrl = process.env.REACT_APP_DEV_URL;
			break;
		case 'STAGE':
			baseUrl = process.env.REACT_APP_STAGE_URL;
			break;
		case 'PROD':
			baseUrl = process.env.REACT_APP_PROD_URL;
			break;
	}

	useEffect(() => {
		const getTokenDataAndNavigate = async () => {
			try {
				const tokenFromLocalStorage = localStorage.getItem('token');
				const result = await axios.post(`${baseUrl}/api/v1/user/verifyToken`, {
					token: tokenFromLocalStorage,
				});
				if (result.data === 'invalid') {
					navigate('/login');
				}
				setUserId(result.data);
			} catch {
				navigate('/login');
			}
		};
		getTokenDataAndNavigate();
	}, []);

	useEffect(() => {
		const getStaticDetails = async () => {
			try {
				const result = await axios.get(`${baseUrl}/api/v1/static/getStaticData`);
				if (result) {
					setStaticData({
						...staticData,
						education: result.data.education,
						occupation: result.data.occupation,
						subCastes: result.data.subCastes,
						gotras: result.data.gotras,
						nakshatras: result.data.nakshatras,
						zodaicSigns: result.data.zodaicSigns,
						languages: result.data.languages,
					});
				}
			} catch (err) {
				console.log(err);
			}
		};
		getStaticDetails();
	}, []);

	const { asideStatus } = useContext(ThemeContext);

	const [selectedImage1, setSelectedImage1] = useState(upload);
	const [selectedImage2, setSelectedImage2] = useState(upload);
	const [selectedImage3, setSelectedImage3] = useState(upload);
	const [selectedImage4, setSelectedImage4] = useState(upload);
	const [selectedImage5, setSelectedImage5] = useState(upload);
	const fileInputRef1 = useRef(null);
	const fileInputRef2 = useRef(null);
	const fileInputRef3 = useRef(null);
	const fileInputRef4 = useRef(null);
	const fileInputRef5 = useRef(null);

	const [dImages, setDImages] = useState([]);

	const [showLoader, setShowLoader] = useState(false);

	const [profileBuildingDetails, setProfileBuildingDetails] = useState({
		name: '',
		phoneNumber: '',
		alternatePhone: '',
		bio: '',
		gender: '',
		dateOfBirth: '',
		height: NaN,
		weight: NaN,
		incomeRange: '',
		relationshipStatus: '',
		address: '',
		country: '',
		state: '',
		district: '',
		city: '',
		pincode: '',
		images: '',
		education: '',
		workTitle: '',
		company: '',
		religion: 'HINDU',
		motherToungue: '',
		caste: 'BRAHMIN',
		subCaste: '',
		gotra: '',
		star: '',
		kujaDosh: false,
		fatherDetails: {
			name: '',
			occupation: '',
			age: '',
			isAlive: true,
		},
		motherDetails: {
			name: '',
			occupation: '',
			age: '',
			isAlive: true,
		},
		siblingDetails: [
			{
				name: '',
				occupation: '',
				age: '',
				gender: '',
				isAlive: true,
				isMarried: '',
			},
		],
		setShowLoader: setShowLoader,
	});

	useEffect(() => {
		try {
			async function getAndSetProfileData() {
				const headers = {
					authorization: localStorage.getItem('token'),
				};
				const result = await axios.get(`${baseUrl}/api/v1/profile/getProfileDetails`, {
					headers,
				});
				const recievedData = result.data.result;
				if (recievedData) {
					setProfileDataFromDB(recievedData);

					recievedData.images && recievedData?.images[0]
						? setSelectedImage1(recievedData.images[0])
						: '';
					recievedData.images && recievedData?.images[1]
						? setSelectedImage2(recievedData.images[1])
						: '';
					recievedData.images && recievedData?.images[2]
						? setSelectedImage3(recievedData.images[2])
						: '';
					recievedData.images && recievedData?.images[3]
						? setSelectedImage4(recievedData.images[3])
						: '';
					recievedData.images && recievedData?.images[4]
						? setSelectedImage5(recievedData.images[4])
						: '';

					setProfileBuildingDetails({
						...profileBuildingDetails,
						name: recievedData.name,
						phoneNumber: recievedData.phone,
						alternatePhone: recievedData.alternatePhone
							? recievedData.alternatePhone
							: '',
						bio: recievedData.bio,
						gender: recievedData.gender,
						dateOfBirth: recievedData.dateOfBirth,
						height: recievedData.height,
						weight: recievedData.weight,
						incomeRange: recievedData.incomeRange,
						relationshipStatus: recievedData.relationshipStatus,
						address: recievedData.address,
						country: recievedData.country,
						state: recievedData.state,
						district: recievedData.district,
						city: recievedData.city,
						pincode: recievedData.pinCode,
						images: recievedData.images,
						education: recievedData.education,
						workTitle: recievedData.experience
							? recievedData.experience.workTitle
							: null,
						company: recievedData.experience ? recievedData.experience.company : null,
						religion: 'HINDU',
						motherToungue: recievedData.motherToungue,
						caste: 'BRAHMIN',
						subCaste: recievedData.subCaste,
						gotra: recievedData.gotra,
						star: recievedData.star,
						kujaDosh: recievedData.kujaDosh,
						fatherDetails: recievedData.parents
							? recievedData.parents.fatherDetails
							: null,
						motherDetails: recievedData.parents
							? recievedData.parents.motherDetails
							: null,
						siblingDetails: recievedData.siblings ? recievedData.siblings : [],
					});
				}
			}
			getAndSetProfileData();
		} catch (error) {
			console.log(error);
			return Promise.resolve(null);
		}
	}, []);

	const handleImageClick1 = () => {
		fileInputRef1.current.click();
	};
	const handleImageClick2 = () => {
		fileInputRef2.current.click();
	};
	const handleImageClick3 = () => {
		fileInputRef3.current.click();
	};
	const handleImageClick4 = () => {
		fileInputRef4.current.click();
	};
	const handleImageClick5 = () => {
		fileInputRef5.current.click();
	};

	const handleImageUpload1 = async (event, index) => {
		const file = event.target.files[0];
		if (file) {
			const fileSizeLimit = 5 * 1024 * 1024; // 2MB in bytes

			const options = {
				maxSizeMB: 1,
				maxWidthOrHeight: 1920,
				useWebWorker: true,
			};

			if (file.size <= fileSizeLimit) {
				setShowLoader(true);
				const headers = { authorization: localStorage.getItem('token') };
				const compressedFile = await imageCompression(file, options);
				const formData = new FormData();
				formData.append('image', compressedFile);
				formData.append('index', 0);

				const images = await axios.post(
					`${baseUrl}/api/v1/profile/updateImages`,
					formData,
					{
						headers,
					},
				);

				if (images.status == 202) {
					setShowLoader(false);
					setSelectedImage1(images.data.images[0]);
					setProfileBuildingDetails({
						...profileBuildingDetails,
						images: images.data.images,
					});
				}
			} else {
				toast.warn('Image size must be less than 5MB.', {
					position: 'top-right',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'colored',
				});
			}
		}
	};
	const handleImageUpload2 = async (event, index) => {
		const file = event.target.files[0];
		if (file) {
			const fileSizeLimit = 5 * 1024 * 1024; // 2MB in bytes

			const options = {
				maxSizeMB: 1,
				maxWidthOrHeight: 1920,
				useWebWorker: true,
			};

			if (file.size <= fileSizeLimit) {
				setShowLoader(true);
				const headers = { authorization: localStorage.getItem('token') };
				const compressedFile = await imageCompression(file, options);
				const formData = new FormData();
				formData.append('image', compressedFile);
				formData.append('index', 1);

				const images = await axios.post(
					`${baseUrl}/api/v1/profile/updateImages`,
					formData,
					{
						headers,
					},
				);

				if (images.status == 202) {
					setShowLoader(false);
					setSelectedImage2(images.data.images[1]);
					setProfileBuildingDetails({
						...profileBuildingDetails,
						images: images.data.images,
					});
				}
			} else {
				toast.warn('Image size must be less than 5MB.', {
					position: 'top-right',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'colored',
				});
			}
		}
	};
	const handleImageUpload3 = async (event, index) => {
		const file = event.target.files[0];
		if (file) {
			const fileSizeLimit = 5 * 1024 * 1024; // 2MB in bytes

			const options = {
				maxSizeMB: 1,
				maxWidthOrHeight: 1920,
				useWebWorker: true,
			};

			if (file.size <= fileSizeLimit) {
				setShowLoader(true);
				const headers = { authorization: localStorage.getItem('token') };
				const compressedFile = await imageCompression(file, options);
				const formData = new FormData();
				formData.append('image', compressedFile);
				formData.append('index', 2);

				const images = await axios.post(
					`${baseUrl}/api/v1/profile/updateImages`,
					formData,
					{
						headers,
					},
				);
				if (images.status == 202) {
					setShowLoader(false);
					setSelectedImage3(images.data.images[2]);
					setProfileBuildingDetails({
						...profileBuildingDetails,
						images: images.data.images,
					});
				}
			} else {
				toast.warn('Image size must be less than 5MB.', {
					position: 'top-right',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'colored',
				});
			}
		}
	};
	const handleImageUpload4 = async (event, index) => {
		const file = event.target.files[0];
		if (file) {
			const fileSizeLimit = 5 * 1024 * 1024; // 2MB in bytes

			const options = {
				maxSizeMB: 1,
				maxWidthOrHeight: 1920,
				useWebWorker: true,
			};

			if (file.size <= fileSizeLimit) {
				setShowLoader(true);
				const headers = { authorization: localStorage.getItem('token') };
				const compressedFile = await imageCompression(file, options);
				const formData = new FormData();
				formData.append('image', compressedFile);
				formData.append('index', 3);

				const images = await axios.post(
					`${baseUrl}/api/v1/profile/updateImages`,
					formData,
					{
						headers,
					},
				);

				if (images.status == 202) {
					setShowLoader(false);
					setSelectedImage4(images.data.images[3]);
					setProfileBuildingDetails({
						...profileBuildingDetails,
						images: images.data.images,
					});
				}
			} else {
				toast.warn('Image size must be less than 5MB.', {
					position: 'top-right',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'colored',
				});
			}
		}
	};
	const handleImageUpload5 = async (event, index) => {
		const file = event.target.files[0];
		if (file) {
			const fileSizeLimit = 5 * 1024 * 1024; // 2MB in bytes

			const options = {
				maxSizeMB: 1,
				maxWidthOrHeight: 1920,
				useWebWorker: true,
			};

			if (file.size <= fileSizeLimit) {
				setShowLoader(true);
				const headers = { authorization: localStorage.getItem('token') };
				const compressedFile = await imageCompression(file, options);
				const formData = new FormData();
				formData.append('image', compressedFile);
				formData.append('index', 4);

				const images = await axios.post(
					`${baseUrl}/api/v1/profile/updateImages`,
					formData,
					{
						headers,
					},
				);
				if (images.status == 202) {
					console.log(images);
					setShowLoader(false);
					setSelectedImage5(images.data.images[4]);
					setProfileBuildingDetails({
						...profileBuildingDetails,
						images: images.data.images,
					});
				}
			} else {
				toast.warn('Image size must be less than 5MB.', {
					position: 'top-right',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'colored',
				});
			}
		}
	};

	const [profileCompletionStatus, setProfileCompletionStatus] = useState(0);

	useEffect(() => {
		if (profileBuildingDetails.name) {
			setProfileCompletionStatus(16);
		}
		if (profileBuildingDetails.relationshipStatus) {
			setProfileCompletionStatus(32);
		}
		if (profileBuildingDetails.city) {
			setProfileCompletionStatus(50);
		}
		if (profileBuildingDetails.images && profileBuildingDetails.images.length > 0) {
			setProfileCompletionStatus(62);
		}
		if (profileBuildingDetails.gotra) {
			setProfileCompletionStatus(75);
		}
		if (profileBuildingDetails.fatherDetails && profileBuildingDetails.fatherDetails.name) {
			setProfileCompletionStatus(100);
		}
	}, [profileBuildingDetails]);

	const [isProfileCompleted, setIsProfileCompleted] = useState('');

	useEffect(() => {
		const getAllProfiles = async () => {
			try {
				const headers = {
					authorization: localStorage.getItem('token'),
				};
				if (headers.authorization) {
					const responseFromApi = await axios.get(
						`${baseUrl}/api/v1/profile/getProfileDetails`,
						{
							headers,
						},
					);
					if (responseFromApi.data.result) {
						const { parents } = responseFromApi.data.result;
						if (parents != undefined) {
							setIsProfileCompleted('true');
						} else {
							setIsProfileCompleted('false');
						}
					} else {
						setIsProfileCompleted('false');
					}
				}
			} catch (e) {
				console.log(e);
				return Promise.resolve(null);
			}
		};
		getAllProfiles();
	}, []);

	const [userSubDetails, setUserSubDetails] = useState([]);
	const [activePackName, setActivePackName] = useState('');
	const [activePackData, setActivePackData] = useState({});

	useEffect(() => {
		const getUserSubscriptionDetails = async () => {
			try {
				const headers = {
					authorization: localStorage.getItem('token'),
				};
				const resultApi = await axios.get(
					`${baseUrl}/api/v1/userSubscription/getSubscriptionDetails`,
					{ headers },
				);

				for (let i = 0; i < resultApi.data.result.length; i++) {
					if (resultApi.data.result[i]._id === resultApi.data.ActiveSubscriptionPack) {
						setActivePackData(resultApi.data.result[i]);
						setActivePackName(resultApi.data.result[i].packageId.name);
					}
				}
				setUserSubDetails(resultApi.data);
			} catch (e) {
				console.log(e);
				return Promise.resolve(null);
			}
		};
		getUserSubscriptionDetails();
	}, []);

	let differenceDays = 0;

	if (userSubDetails.startDate != '') {
		const endDate = userSubDetails.endDate;
		const oneDay = 24 * 60 * 60 * 1000;
		differenceDays = Math.round((new Date(endDate) - new Date()) / oneDay);
	}

	if (isProfileCompleted == 'true') {
		const inputDate = profileDataFromDB.dateOfBirth;
		const parts = inputDate.split('-'); // Split the input date into parts

		const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;

		return (
			<div className='w-[100%]'>
				{/* <AsideBar /> */}
				{/* <div className={asideStatus ? `md:ml-[15rem] ` : `md:ml-[5rem] mt-[5rem] md:mt-0`}>  */}
				{/* <DashboardHeader />  */}
				<div className='md:ms-5 mx-2'>
					<div className='md:ml-5'>
						<div className={profileCompletionStatus !== 100 ? 'block' : 'hidden'}>
							<Card>
								<Alert isLight={true} shadow={'xl'} className='px-5'>
									<AlertHeading>
										<div className='flex items-center gap-5'>
											<Icon
												icon='PendingActions'
												color='danger'
												size={'3x'}
											/>
											<h5 className='pt-2'>
												Looks Like You Haven't Completed Your Profile.
												Please Complete Your Profile.
											</h5>
										</div>
									</AlertHeading>
								</Alert>
							</Card>
						</div>

						<div
							className={
								profileCompletionStatus === 100
									? 'lg:grid grid-cols-2 gap-2 ml-3 mt-5'
									: 'hidden'
							}>
							<Card>
								<CardBody>
									<div className='flex justify-between px-1 md:px-10'>
										<div>
											<div className='flex items-center gap-1'>
												<h3>{profileDataFromDB.name} </h3>
												<span className='-mt-2'>
													<Icon
														style={{ color: '#ffae00b5' }}
														icon='verified'
														size={'2x'}
													/>
												</span>
											</div>
											<p>
												{formattedDate},{' '}
												{profileDataFromDB.experience
													? profileDataFromDB.experience.workTitle
													: ''}
											</p>
											<div className='flex items-center gap-1'>
												<Icon
													icon='PhoneIphone'
													size={'lg'}
													// color='primary'
													style={{ color: '#700000' }}
												/>
												<p className='text-sm h-full my-2'>
													{profileDataFromDB.phone}
												</p>
											</div>
											<div className='flex items-center gap-1'>
												<Icon
													icon='Home'
													size={'lg'}
													style={{ color: '#700000' }}
												/>
												<p className='text-sm h-full my-2'>
													{profileDataFromDB.address}
												</p>
											</div>
										</div>
										<div
											style={{ position: 'relative', alignSelf: 'center' }}
											className='w-[10rem]'>
											<CircularProgressbarWithChildren
												value={100}
												styles={buildStyles({
													pathColor: '#ffae00b5',
													strokeWidth: 2,
												})}>
												<img
													src={
														profileBuildingDetails.images[0] != ''
															? profileBuildingDetails.images[0]
															: 'https://facit-modern.omtanke.studio/static/media/wanna7.c92d88f942e3d84190de.webp'
													}
													style={{
														width: '90%',
														height: '90%',
														borderRadius: '50%',
														padding: '0.2em',
													}}
													alt='USer Image'
													className='w-36 h-36 border-white border-0 rounded-full bg-purple-100'
												/>
											</CircularProgressbarWithChildren>

											<div
												style={{
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													fontSize: 12,
													textAlign: 'center',
													marginTop: -8,
													borderRadius: '25px',
													backgroundColor: '#700000',
													color: 'white',
													padding: '5px',
													width: 120,
													height: 30,
													position: 'absolute',
													top: '105%',
													left: '50%',
													transform: 'translate(-50%, -50%)',
													zIndex: '100',
												}}>
												<strong>100%</strong> completed
											</div>
										</div>
									</div>
								</CardBody>
							</Card>
							{activePackName == '' ? (
								<Card>
									<CardBody>
										<div className='flex flex-col justify-center items-center  gap-4 p-4'>
											<Icon
												color='warning'
												icon='CardMembership'
												size={'3x'}
											/>
											<h5>
												Hey Looks Like You Don't Have Active Subscription{' '}
											</h5>
											<Button
												style={{
													backgroundColor: '#ffae00b5',
													color: 'black',
												}}
												isOutline={false}
												className='min-w-min'
												onClick={() => {
													navigate('/subscriptions');
												}}>
												{' '}
												Buy Subscription Now
											</Button>
										</div>
									</CardBody>
								</Card>
							) : (
								<Card>
									<CardBody>
										<div className='flex gap-3 items-center justify-between px-1 md:px-10'>
											<div className='flex gap-3 items-center'>
												<Icon
													icon='Subscriptions'
													size={'3x'}
													style={{ color: '#ffae00b5' }}
												/>
												<h3>Subscriptions</h3>
											</div>
											<div className='border-2 text-xs border-green-500 text-green-400 font-semibold p-1 md:p-2 md:px-3 rounded-full'>
												{differenceDays} Days Left
											</div>
										</div>
										<div className='px-10 py-3'>
											<h5>Plan Details: {activePackName}</h5>
											<h5> Credits Left: {activePackData.creditsLeft}</h5>
											<h5>
												Booster Credits Left:
												{userSubDetails.TotalActiveBoosterPacksCredits}
											</h5>
										</div>
									</CardBody>
								</Card>
							)}
						</div>
					</div>
				</div>
			</div>
			//  </div>
		);
	} else if (isProfileCompleted == 'false') {
		return (
			<div className='w-[100%]'>
				{showLoader ? (
					<div className='fixed top-0 z-[99999] min-h-[100vh] bg-black'>
						<ThreeDots
							height='80'
							width='80'
							radius='9'
							color='#700000'
							ariaLabel='three-dots-loading'
							wrapperStyle={{ position: 'fixed', top: '50%', left: '50%' }}
							wrapperClassName=''
							visible={showLoader}
						/>
					</div>
				) : (
					<></>
				)}
				{/* <AsideBar /> */}
				{/* <div className={asideStatus ? `md:ml-[15rem] ` : `md:ml-[5rem] mt-[5rem] md:mt-0`}> */}
				{/* <DashboardHeader />  */}

				<div
					className={
						isProfileCompleted == 'false'
							? showLoader
								? 'md:ms-5 mx-2 opacity-50'
								: 'md:ms-5 mx-2'
							: 'hidden'
					}>
					<h1 className='py-3 ml-5'>Create Your Profile</h1>
					<div className='mx-5'>
						<Wizard
							isHeader={true}
							stretch={false}
							onNext={profileBuildingDetails}
							userID={userId}
							color={'warning'}>
							<WizardItem title='Basic Details'>
								<Alert color='light'>
									<div className='flex gap-3 items-center ml-3'>
										<Icon icon='PersonPin' size={'3x'} color='warning' />
										<h5>
											Let The Search Begin: : Share Your Name, Phone, and Bio!
										</h5>
									</div>
								</Alert>
								<Card>
									<CardBody>
										<div className='md:grid grid-cols-2 gap-5 md:mx-5'>
											<div className='pb-5'>
												<h5>How Shall We Call You?</h5>
												<FormGroup>
													<Input
														size={'lg'}
														type='text'
														value={profileBuildingDetails.name}
														onChange={(e) => {
															const newValue = e.target.value.replace(
																/\s/g,
																'',
															);
															setProfileBuildingDetails({
																...profileBuildingDetails,
																name: newValue,
															});
														}}
														placeholder='Enter Your Name'
													/>
												</FormGroup>
											</div>

											<div className='pb-5'>
												<h5>Your Reachable Contact Number</h5>
												<FormGroup>
													<Input
														size={'lg'}
														type='number'
														value={profileBuildingDetails.phoneNumber}
														onChange={(e) => {
															if (
																e.target.value >= 0 &&
																e.target.value != '-'
															) {
																setProfileBuildingDetails({
																	...profileBuildingDetails,
																	phoneNumber: e.target.value,
																});
															}
														}}
														placeholder='Enter Your Phone Number'
													/>
												</FormGroup>
											</div>
										</div>
										<div className='md:grid grid-cols-2 gap-5 md:mx-5'>
											<div className='pb-5'>
												<h5>Your Alternate Contact Number</h5>
												<FormGroup>
													<Input
														size={'lg'}
														type='number'
														value={
															profileBuildingDetails.alternatePhone
														}
														onChange={(e) => {
															if (
																e.target.value >= 0 &&
																e.target.value != '-'
															) {
																setProfileBuildingDetails({
																	...profileBuildingDetails,
																	alternatePhone: e.target.value,
																});
															}
														}}
														placeholder='Enter Your Alternate Phone Number'
													/>
												</FormGroup>
											</div>
										</div>

										<div className='md:mx-5'>
											<div className='pb-5'>
												<h5>Briefly Describe Yourself</h5>
												<FormGroup>
													{/* <Textarea
									className='h-5'
									size={'lg'}
									type='text'
									placeholder='Enter Your Name'
								/> */}
													<Textarea
														size={'lg'}
														type='text'
														placeholder='Enter Your Bio'
														value={profileBuildingDetails.bio}
														onChange={(e) =>
															setProfileBuildingDetails({
																...profileBuildingDetails,
																bio: e.target.value,
															})
														}
													/>
												</FormGroup>
											</div>
										</div>
									</CardBody>
								</Card>
							</WizardItem>
							<WizardItem title='Personal Profile Details'>
								<Alert color='light'>
									<div className='flex gap-3 items-center ml-3'>
										<Icon icon='LiveHelp' size={'3x'} color='warning' />
										<h5>The Portrait of You: Tell Us More About Yourself!</h5>
									</div>
								</Alert>

								<Card>
									<CardBody>
										<div className='md:grid grid-cols-2 gap-5 md:mx-5'>
											<div className='pb-5'>
												<h5>Your Gender Identity</h5>
												<FormGroup>
													<Select
														placeholder='Choose Your Gender'
														size='lg'
														id='gender'
														value={profileBuildingDetails.gender}
														list={[
															{ value: 'MALE', text: 'MALE' },
															{ value: 'FEMALE', text: 'FEMALE' },
														]}
														onChange={(e) =>
															setProfileBuildingDetails({
																...profileBuildingDetails,
																gender: e.target.value,
															})
														}
													/>
												</FormGroup>
											</div>

											<div className='pb-5'>
												<h5>When Is Your Birthday?</h5>
												<FormGroup>
													<Input
														size={'lg'}
														type='date'
														value={profileBuildingDetails.dateOfBirth}
														max={new Date().toISOString().split('T')[0]}
														onChange={(e) => {
															setProfileBuildingDetails({
																...profileBuildingDetails,
																dateOfBirth: e.target.value,
															});
														}}
													/>
												</FormGroup>
											</div>
										</div>

										<div className='md:grid grid-cols-2 gap-5 md:mx-5'>
											<div className='pb-5'>
												<h5>How Tall Are You? [ In ft ]</h5>
												<FormGroup>
													<Input
														size={'lg'}
														type='number'
														min='1'
														placeholder='Enter Your Height'
														value={profileBuildingDetails.height}
														onChange={(e) => {
															if (
																e.target.value >= 0 &&
																e.target.value != '-'
															) {
																setProfileBuildingDetails({
																	...profileBuildingDetails,
																	height: e.target.value,
																});
															}
														}}
													/>
												</FormGroup>
											</div>

											<div className='pb-5'>
												<h5>How Much Do You Weigh? [ In Kgs ]</h5>
												<FormGroup>
													<Input
														size={'lg'}
														type='number'
														placeholder='Enter Your Weight'
														value={profileBuildingDetails.weight}
														onChange={(e) => {
															if (
																e.target.value >= 0 &&
																e.target.value != '-'
															) {
																setProfileBuildingDetails({
																	...profileBuildingDetails,
																	weight: e.target.value,
																});
															}
														}}
													/>
												</FormGroup>
											</div>
										</div>

										<div className='md:grid grid-cols-2 gap-5 md:mx-5'>
											<div className='pb-5'>
												<h5>Your Annual Income Category</h5>
												<FormGroup>
													<Select
														placeholder='Choose Your Income Range'
														size='lg'
														id='gender'
														value={profileBuildingDetails.incomeRange}
														list={[
															{ value: '0 - 1L', text: '0 - 1L' },
															{ value: '1L - 3L', text: '1L - 3L' },
															{ value: '3L - 6L', text: '3L - 6L' },
															{ value: '6L - 10L', text: '6L - 10L' },
															{
																value: '10L - 20L',
																text: '10L - 20L',
															},
															{ value: '20L+', text: '20L+' },
														]}
														onChange={(e) =>
															setProfileBuildingDetails({
																...profileBuildingDetails,
																incomeRange: e.target.value,
															})
														}
													/>
												</FormGroup>
											</div>

											<div className='pb-5'>
												<h5>Your Current Relationship Status</h5>
												<Select
													placeholder='Choose Your Relationship Status'
													size='lg'
													id='gender'
													value={
														profileBuildingDetails.relationshipStatus
													}
													list={[
														{ value: 'SINGLE', text: 'SINGLE' },
														{ value: 'DIVORCED', text: 'DIVORCED' },
														{
															value: 'WIDOW / WIDOWER',
															text: 'WIDOW / WIDOWER',
														},
													]}
													onChange={(e) =>
														setProfileBuildingDetails({
															...profileBuildingDetails,
															relationshipStatus: e.target.value,
														})
													}
												/>
											</div>
										</div>
									</CardBody>
								</Card>
							</WizardItem>

							<WizardItem title='Residential Details'>
								<Alert color='light'>
									<div className='flex gap-3 items-center ml-3'>
										<Icon icon='LiveHelp' size={'3x'} color='warning' />
										<h5>
											Connecting Hearts, Connecting Places: Your Address
											Information!
										</h5>
									</div>
								</Alert>
								<Card>
									<CardBody>
										<div className='md:mx-5'>
											<div className='pb-5'>
												<h5>Where Do You Live?</h5>
												<FormGroup>
													<Textarea
														size={'lg'}
														type='text'
														placeholder='Enter Your Address'
														value={profileBuildingDetails.address}
														onChange={(e) =>
															setProfileBuildingDetails({
																...profileBuildingDetails,
																address: e.target.value,
															})
														}
													/>
												</FormGroup>
											</div>
										</div>

										<div className='md:grid grid-cols-2 gap-5 md:mx-5'>
											<div className='pb-5'>
												<h5>Your Country of Residence</h5>
												<FormGroup>
													<Select
														placeholder='Choose Your Country'
														size='lg'
														id='gender'
														value={profileBuildingDetails.country}
														list={[{ value: 'INDIA', text: 'INDIA' }]}
														onChange={(e) =>
															setProfileBuildingDetails({
																...profileBuildingDetails,
																country: e.target.value,
															})
														}
													/>
												</FormGroup>
											</div>

											<div className='pb-5'>
												<h5>State of Residence</h5>
												<FormGroup>
													<Select
														placeholder='Choose Your State'
														size='lg'
														id='gender'
														value={profileBuildingDetails.state}
														// list={[{ value: 'INDIA', text: 'INDIA' }]}
														list={stateList.map((e) => {
															return { value: e, text: e };
														})}
														onChange={(e) =>
															setProfileBuildingDetails({
																...profileBuildingDetails,
																state: e.target.value,
															})
														}
													/>
												</FormGroup>
											</div>
										</div>

										<div className='md:grid grid-cols-3 gap-5 md:mx-5'>
											<div className='pb-5'>
												<h5>Your Current District</h5>
												<FormGroup>
													<Select
														placeholder='Choose Your District'
														size='lg'
														id='gender'
														value={profileBuildingDetails.district}
														// list={[{ value: 'INDIA', text: 'INDIA' }]}
														list={getDistrictsList()?.map((e) => {
															return {
																value: e,
																text: e,
															};
														})}
														defaultValue={'NONE'}
														onChange={(e) =>
															setProfileBuildingDetails({
																...profileBuildingDetails,
																district: e.target.value,
															})
														}
													/>
												</FormGroup>
											</div>

											<div className='pb-5'>
												<h5>City You Live In</h5>
												<FormGroup>
													<Input
														size={'lg'}
														type='text'
														placeholder='Enter Your City'
														value={profileBuildingDetails.city}
														onChange={(e) => {
															setProfileBuildingDetails({
																...profileBuildingDetails,
																city: e.target.value,
															});
														}}
													/>
												</FormGroup>
											</div>

											<div className='pb-5'>
												<h5>Pincode</h5>
												<FormGroup>
													<Input
														size={'lg'}
														type='number'
														placeholder='Enter Your Pincode'
														value={profileBuildingDetails.pincode}
														onChange={(e) => {
															setProfileBuildingDetails({
																...profileBuildingDetails,
																pincode: e.target.value,
															});
														}}
													/>
												</FormGroup>
											</div>
										</div>
									</CardBody>
								</Card>
							</WizardItem>
							<WizardItem title='Display Pictures'>
								<Alert color='light'>
									<div className='flex gap-3 items-center ml-3'>
										<Icon icon='LiveHelp' size={'3x'} color='warning' />
										<h5>DP Magic: Add Some Flair to Your Profile!</h5>
									</div>
								</Alert>
								<Card>
									<CardBody>
										<div className='flex gap-3 flex-wrap justify-center'>
											<div className='inline py-2'>
												<img
													src={selectedImage1}
													alt='Selected'
													onClick={handleImageClick1}
													style={{ cursor: 'pointer' }}
													className='w-[300px] h-[200px] rounded border-1 border-black'
												/>
												<input
													type='file'
													ref={fileInputRef1}
													accept='image/*'
													onChange={handleImageUpload1}
													style={{ display: 'none' }}
													key={selectedImage1} // Add this key prop to reset the file input
												/>

												<h5 className='text-center py-2'>Image 1</h5>
											</div>

											<div className='inline py-2'>
												<img
													src={selectedImage2}
													alt='Selected'
													onClick={handleImageClick2}
													style={{ cursor: 'pointer' }}
													className='w-[300px] h-[200px] rounded border-1 border-black'
												/>
												<input
													type='file'
													ref={fileInputRef2}
													accept='image/*'
													onChange={handleImageUpload2}
													style={{ display: 'none' }}
													key={selectedImage2} // Add this key prop to reset the file input
												/>

												<h5 className='text-center py-2'>Image 2</h5>
											</div>

											<div className='inline py-2'>
												<img
													src={selectedImage3}
													alt='Selected'
													onClick={handleImageClick3}
													style={{ cursor: 'pointer' }}
													className='w-[300px] h-[200px] rounded border-1 border-black'
												/>
												<input
													type='file'
													ref={fileInputRef3}
													accept='image/*'
													onChange={handleImageUpload3}
													style={{ display: 'none' }}
													key={selectedImage3} // Add this key prop to reset the file input
												/>

												<h5 className='text-center py-2'>Image 3</h5>
											</div>

											<div className='inline py-2'>
												<img
													src={selectedImage4}
													alt='Selected'
													onClick={handleImageClick4}
													style={{ cursor: 'pointer' }}
													className='w-[300px] h-[200px] rounded border-1 border-black'
												/>
												<input
													type='file'
													ref={fileInputRef4}
													accept='image/*'
													onChange={handleImageUpload4}
													style={{ display: 'none' }}
													key={selectedImage4} // Add this key prop to reset the file input
												/>

												<h5 className='text-center py-2'>
													Image 4 [ Optional ]
												</h5>
											</div>

											<div className='inline py-2'>
												<img
													src={selectedImage5}
													alt='Selected'
													onClick={handleImageClick5}
													style={{ cursor: 'pointer' }}
													className='w-[300px] h-[200px] rounded border-1 border-black'
												/>
												<input
													type='file'
													ref={fileInputRef5}
													accept='image/*'
													onChange={handleImageUpload5}
													style={{ display: 'none' }}
													key={selectedImage5} // Add this key prop to reset the file input
												/>

												<h5 className='text-center py-2'>
													Image 5 [ Optional ]
												</h5>
											</div>
										</div>
									</CardBody>
								</Card>
							</WizardItem>
							<WizardItem title='Personal Details'>
								<Alert color='light'>
									<div className='flex gap-3 items-center ml-3'>
										<Icon icon='LiveHelp' size={'3x'} color='warning' />
										<h5>
											A Journey of Growth: Your Education and Professional
											Endeavors!
										</h5>
									</div>
								</Alert>
								<Card>
									<CardBody>
										<div className='md:mx-5'>
											<div className='pb-5'>
												<h5>Educational Background</h5>
												<FormGroup>
													<Select
														placeholder='Choose Your Answer'
														size='lg'
														id='gender'
														value={profileBuildingDetails.education}
														// list={[{ value: 'INDIA', text: 'INDIA' }]}
														list={staticData.education.map((e) => {
															return { value: e, text: e };
														})}
														onChange={(e) =>
															setProfileBuildingDetails({
																...profileBuildingDetails,
																education: e.target.value,
															})
														}
													/>
												</FormGroup>
											</div>
										</div>
										<div className='md:grid grid-cols-2 gap-5 md:mx-5'>
											<div className='pb-5'>
												<h5>Your Title at Work</h5>
												<FormGroup>
													<Input
														size={'lg'}
														type='text'
														placeholder='Enter Your Work Title'
														value={profileBuildingDetails.workTitle}
														onChange={(e) =>
															setProfileBuildingDetails({
																...profileBuildingDetails,
																workTitle: e.target.value,
															})
														}
													/>
												</FormGroup>
											</div>

											<div className='pb-5'>
												<h5>Company You Work For</h5>
												<FormGroup>
													<Input
														size={'lg'}
														type='text'
														placeholder='Enter Your Company'
														value={profileBuildingDetails.company}
														onChange={(e) =>
															setProfileBuildingDetails({
																...profileBuildingDetails,
																company: e.target.value,
															})
														}
													/>
												</FormGroup>
											</div>
										</div>

										<div className='md:grid grid-cols-2 gap-5 md:mx-5'>
											<div className='pb-5'>
												<h5>Select Your Religion</h5>
												<FormGroup>
													<Input
														size={'lg'}
														type='text'
														value={profileBuildingDetails.religion}
														disabled
														placeholder='Enter Your Religion'
													/>
												</FormGroup>
											</div>

											<div className='pb-5'>
												<h5>Your Family Language</h5>
												<Select
													placeholder='Enter Your Mother Tongue'
													value={profileBuildingDetails.motherToungue}
													size='lg'
													list={staticData.languages.map((e) => {
														return { value: e, text: e };
													})}
													onChange={(e) =>
														setProfileBuildingDetails({
															...profileBuildingDetails,
															motherToungue: e.target.value,
														})
													}
												/>
											</div>
										</div>

										<div className='md:grid grid-cols-2 gap-5 md:mx-5'>
											<div className='pb-5'>
												<h5>Choose Your Caste</h5>
												<Input
													type='text'
													size={'lg'}
													value={profileBuildingDetails.caste}
													disabled
												/>
											</div>

											<div className='pb-5'>
												<h5>Sub Caste You Belong To</h5>
												<FormGroup>
													<Select
														placeholder='Choose Your Sub Caste'
														value={profileBuildingDetails.subCaste}
														size='lg'
														list={staticData.subCastes.map((e) => {
															return { value: e, text: e };
														})}
														onChange={(e) =>
															setProfileBuildingDetails({
																...profileBuildingDetails,
																subCaste: e.target.value,
															})
														}
													/>
												</FormGroup>
											</div>
										</div>

										<div className='md:grid grid-cols-3 gap-5 md:mx-5'>
											<div className='pb-5'>
												<h5>Select Your Gotra</h5>
												<Select
													placeholder='Choose Your Gotra'
													value={profileBuildingDetails.gotra}
													size='lg'
													list={staticData.gotras.map((e) => {
														return { value: e, text: e };
													})}
													onChange={(e) =>
														setProfileBuildingDetails({
															...profileBuildingDetails,
															gotra: e.target.value,
														})
													}
												/>
											</div>

											<div className='pb-5'>
												<h5>Slect Your Nakshatra</h5>
												<FormGroup>
													<Select
														placeholder='Choose Your Star'
														value={profileBuildingDetails.star}
														size='lg'
														list={staticData.nakshatras.map((e) => {
															return { value: e, text: e };
														})}
														onChange={(e) =>
															setProfileBuildingDetails({
																...profileBuildingDetails,
																star: e.target.value,
															})
														}
													/>
												</FormGroup>
											</div>

											<div className='pb-5'>
												<h5>Do You Have Kuja Dosh</h5>
												<Select
													placeholder='Choose Your Response'
													value={profileBuildingDetails.kujaDosh}
													size='lg'
													list={[
														{ value: true, text: 'Yes I Do' },
														{ value: false, text: "No I Don't" },
													]}
													onChange={(e) =>
														setProfileBuildingDetails({
															...profileBuildingDetails,
															kujaDosh: e.target.value,
														})
													}
												/>
											</div>
										</div>
									</CardBody>
								</Card>
							</WizardItem>
							<WizardItem title='Family Details'>
								<Alert color='light'>
									<div className='flex gap-3 items-center ml-3'>
										<Icon icon='LiveHelp' size={'3x'} color='warning' />
										<h5>Bonds of Togetherness: Discovering Your Family.</h5>
									</div>
								</Alert>
								<Card>
									<CardBody>
										<div className='md:grid grid-cols-6 gap-5 md:mx-5'>
											<div className='pb-5'>
												<h5>Salutation</h5>
												<FormGroup>
													<div className='w-[80%]'>
														<Select
															placeholder='Mr'
															value={
																profileBuildingDetails.fatherDetails
																	?.isAlive || true
															}
															size='lg'
															list={[
																{ value: true, text: 'Mr' },
																{ value: false, text: 'Late' },
															]}
															onChange={(e) => {
																{
																	setProfileBuildingDetails({
																		...profileBuildingDetails,
																		fatherDetails: {
																			...profileBuildingDetails.fatherDetails,
																			isAlive: e.target.value,
																			age:
																				e.target.value ==
																				'false'
																					? -1
																					: '',
																		},
																	});
																	console.log(
																		profileBuildingDetails.fatherDetails,
																	);
																}
															}}
														/>
													</div>
												</FormGroup>
											</div>
											<div className='pb-5 col-span-2'>
												<h5>Your Dad's Name</h5>
												<FormGroup>
													<Input
														size={'lg'}
														type='text'
														value={
															profileBuildingDetails.fatherDetails
																? profileBuildingDetails
																		.fatherDetails.name
																: ''
														}
														placeholder='Enter Your Father Name'
														onChange={(e) =>
															setProfileBuildingDetails({
																...profileBuildingDetails,
																fatherDetails: {
																	...profileBuildingDetails.fatherDetails,
																	name: e.target.value,
																},
															})
														}
													/>
												</FormGroup>
											</div>

											<div className='pb-5'>
												<h5>Dad's Age</h5>
												<FormGroup>
													<Input
														size={'lg'}
														type='number'
														disabled={
															profileBuildingDetails.fatherDetails
																? profileBuildingDetails
																		.fatherDetails.isAlive ==
																  'false'
																	? true
																	: false
																: false
														}
														value={
															profileBuildingDetails.fatherDetails
																? profileBuildingDetails
																		.fatherDetails.age == -1
																	? '-'
																	: profileBuildingDetails
																			.fatherDetails.age
																: ''
														}
														placeholder='Age'
														onChange={(e) =>
															setProfileBuildingDetails({
																...profileBuildingDetails,
																fatherDetails: {
																	...profileBuildingDetails.fatherDetails,
																	age: e.target.value,
																},
															})
														}
													/>
												</FormGroup>
											</div>

											<div className='pb-5 col-span-2'>
												<h5>Dad's Current Occupation</h5>
												<FormGroup>
													<Input
														size={'lg'}
														type='text'
														value={
															profileBuildingDetails.fatherDetails
																? profileBuildingDetails
																		.fatherDetails.occupation
																: ''
														}
														placeholder='Enter Your Father Occupation'
														onChange={(e) => {
															setProfileBuildingDetails({
																...profileBuildingDetails,
																fatherDetails: {
																	...profileBuildingDetails.fatherDetails,
																	occupation: e.target.value,
																},
															});
														}}
													/>
												</FormGroup>
											</div>
										</div>

										<div className='md:grid grid-cols-6 gap-5 md:mx-5'>
											<div className='pb-5'>
												<h5>Salutation</h5>
												<FormGroup>
													<div className='w-[80%]'>
														<Select
															value={
																profileBuildingDetails.motherDetails
																	?.isAlive || true
															}
															size='lg'
															list={[
																{ value: true, text: 'Mrs' },
																{ value: false, text: 'Late' },
															]}
															onChange={(e) =>
																setProfileBuildingDetails({
																	...profileBuildingDetails,
																	motherDetails: {
																		...profileBuildingDetails.motherDetails,
																		isAlive: e.target.value,
																		age:
																			e.target.value ==
																			'false'
																				? -1
																				: '',
																	},
																})
															}
														/>
													</div>
												</FormGroup>
											</div>
											<div className='pb-5 col-span-2'>
												<h5>Your Mom's Name</h5>
												<FormGroup>
													<Input
														size={'lg'}
														type='text'
														value={
															profileBuildingDetails.motherDetails
																? profileBuildingDetails
																		.motherDetails.name
																: ''
														}
														placeholder='Enter Your Mother Name'
														onChange={(e) =>
															setProfileBuildingDetails({
																...profileBuildingDetails,
																motherDetails: {
																	...profileBuildingDetails.motherDetails,
																	name: e.target.value,
																},
															})
														}
													/>
												</FormGroup>
											</div>

											<div className='pb-5'>
												<h5>Mom's Age</h5>
												<FormGroup>
													<Input
														size={'lg'}
														type='number'
														disabled={
															profileBuildingDetails.motherDetails
																? profileBuildingDetails
																		.motherDetails.isAlive ==
																  'false'
																	? true
																	: false
																: false
														}
														value={
															profileBuildingDetails.motherDetails
																? profileBuildingDetails
																		.motherDetails.age == -1
																	? '-'
																	: profileBuildingDetails
																			.motherDetails.age
																: ''
														}
														placeholder='Age'
														onChange={(e) =>
															setProfileBuildingDetails({
																...profileBuildingDetails,
																motherDetails: {
																	...profileBuildingDetails.motherDetails,
																	age: e.target.value,
																},
															})
														}
													/>
												</FormGroup>
											</div>

											<div className='pb-5 col-span-2'>
												<h5>Mom's Current Occupation</h5>
												<FormGroup>
													<Input
														size={'lg'}
														type='text'
														value={
															profileBuildingDetails.motherDetails
																? profileBuildingDetails
																		.motherDetails.occupation
																: ''
														}
														placeholder='Enter Your Mother Occupation'
														onChange={(e) =>
															setProfileBuildingDetails({
																...profileBuildingDetails,
																motherDetails: {
																	...profileBuildingDetails.motherDetails,
																	occupation: e.target.value,
																},
															})
														}
													/>
												</FormGroup>
											</div>
										</div>

										{profileBuildingDetails.siblingDetails.map(
											(data, mainIndex) => {
												return (
													<>
														<div
															className='md:grid grid-cols-6 gap-5 md:mx-5'
															key={mainIndex}>
															<div className='pb-5'>
																<h5>Salutation</h5>
																<FormGroup>
																	<div className='w-[80%]'>
																		<Select
																			value={
																				profileBuildingDetails
																					.siblingDetails[
																					mainIndex
																				].isAlive
																			}
																			size='lg'
																			list={[
																				{
																					value: 'MR',
																					text: 'Mr',
																				},
																				{
																					value: 'MS',
																					text: 'Ms',
																				},
																				{
																					value: 'MRS',
																					text: 'Mrs',
																				},
																				{
																					value: 'Late',
																					text: 'Late',
																				},
																			]}
																			onChange={(e) => {
																				const oldArray =
																					profileBuildingDetails.siblingDetails;
																				const newArray =
																					oldArray.map(
																						(
																							data,
																							nestedIndex,
																						) => {
																							if (
																								nestedIndex ===
																								mainIndex
																							) {
																								return {
																									...data,
																									isAlive:
																										e
																											.target
																											.value,
																									age:
																										e
																											.target
																											.value ==
																										'Late'
																											? -1
																											: '',
																								};
																							} else {
																								return data;
																							}
																						},
																					);

																				setProfileBuildingDetails(
																					{
																						...profileBuildingDetails,
																						siblingDetails:
																							newArray,
																					},
																				);
																			}}
																		/>
																	</div>
																</FormGroup>
															</div>
															<div className='pb-5 col-span-2'>
																<h5>Your Sibling's Name</h5>
																<FormGroup>
																	<Input
																		size={'lg'}
																		type='text'
																		value={
																			profileBuildingDetails
																				.siblingDetails[
																				mainIndex
																			].name
																		}
																		placeholder='Enter Your Sibling Name'
																		onChange={(e) => {
																			const oldArray =
																				profileBuildingDetails.siblingDetails;
																			const newArray =
																				oldArray.map(
																					(
																						data,
																						nestedIndex,
																					) => {
																						if (
																							nestedIndex ===
																							mainIndex
																						) {
																							return {
																								...data,
																								name: e
																									.target
																									.value,
																							};
																						} else {
																							return data;
																						}
																					},
																				);

																			setProfileBuildingDetails(
																				{
																					...profileBuildingDetails,
																					siblingDetails:
																						newArray,
																				},
																			);
																		}}
																	/>
																</FormGroup>
															</div>

															<div className='pb-5'>
																<h5>Sibling's Age</h5>
																<FormGroup>
																	<Input
																		size={'lg'}
																		disabled={
																			profileBuildingDetails
																				.siblingDetails[
																				mainIndex
																			]
																				? profileBuildingDetails
																						.siblingDetails[
																						mainIndex
																				  ].isAlive ==
																				  'Late'
																					? true
																					: false
																				: false
																		}
																		value={
																			profileBuildingDetails
																				.siblingDetails[
																				mainIndex
																			]
																				? profileBuildingDetails
																						.siblingDetails[
																						mainIndex
																				  ].age == -1
																					? '-'
																					: profileBuildingDetails
																							.siblingDetails[
																							mainIndex
																					  ].age
																				: '-'
																		}
																		type='number'
																		placeholder='Age'
																		onChange={(e) => {
																			const oldArray =
																				profileBuildingDetails.siblingDetails;
																			const newArray =
																				oldArray.map(
																					(
																						data,
																						nestedIndex,
																					) => {
																						if (
																							nestedIndex ===
																							mainIndex
																						) {
																							return {
																								...data,
																								age: e
																									.target
																									.value,
																							};
																						} else {
																							return data;
																						}
																					},
																				);

																			setProfileBuildingDetails(
																				{
																					...profileBuildingDetails,
																					siblingDetails:
																						newArray,
																				},
																			);
																		}}
																	/>
																</FormGroup>
															</div>

															<div className='pb-5 col-span-2'>
																<h5>Sibling's Current Ocupation</h5>
																<FormGroup>
																	<Input
																		size={'lg'}
																		type='text'
																		value={
																			profileBuildingDetails
																				.siblingDetails[
																				mainIndex
																			].occupation
																		}
																		placeholder='Enter Your Sibling Name'
																		onChange={(e) => {
																			const oldArray =
																				profileBuildingDetails.siblingDetails;
																			const newArray =
																				oldArray.map(
																					(
																						data,
																						nestedIndex,
																					) => {
																						if (
																							nestedIndex ===
																							mainIndex
																						) {
																							return {
																								...data,
																								occupation:
																									e
																										.target
																										.value,
																							};
																						} else {
																							return data;
																						}
																					},
																				);

																			setProfileBuildingDetails(
																				{
																					...profileBuildingDetails,
																					siblingDetails:
																						newArray,
																				},
																			);
																		}}
																	/>
																</FormGroup>
															</div>
														</div>

														<div className='md:grid grid-cols-3 gap-5 md:mx-5'>
															<div className='pb-5'>
																<h5>Sibling's Gender Identity</h5>
																<FormGroup>
																	<Select
																		placeholder='Choose Your Answer'
																		value={
																			profileBuildingDetails
																				.siblingDetails[
																				mainIndex
																			].gender
																		}
																		size='lg'
																		list={[
																			{
																				value: 'MALE',
																				text: 'Male',
																			},
																			{
																				value: 'FEMALE',
																				text: 'Female',
																			},
																		]}
																		onChange={(e) => {
																			const oldArray =
																				profileBuildingDetails.siblingDetails;
																			const newArray =
																				oldArray.map(
																					(
																						data,
																						nestedIndex,
																					) => {
																						if (
																							nestedIndex ===
																							mainIndex
																						) {
																							return {
																								...data,
																								gender: e
																									.target
																									.value,
																							};
																						} else {
																							return data;
																						}
																					},
																				);

																			setProfileBuildingDetails(
																				{
																					...profileBuildingDetails,
																					siblingDetails:
																						newArray,
																				},
																			);
																		}}
																	/>
																</FormGroup>
															</div>

															<div className='pb-5'>
																<h5>
																	Sibling's Current Marital Status
																</h5>
																<FormGroup>
																	<Select
																		placeholder='Choose Your Answer'
																		value={
																			profileBuildingDetails
																				.siblingDetails[
																				mainIndex
																			].isMarried
																		}
																		size='lg'
																		list={[
																			{
																				value: true,
																				text: 'MARRIED',
																			},
																			{
																				value: false,
																				text: 'NOT MARRIED',
																			},
																		]}
																		onChange={(e) => {
																			const oldArray =
																				profileBuildingDetails.siblingDetails;
																			const newArray =
																				oldArray.map(
																					(
																						data,
																						nestedIndex,
																					) => {
																						if (
																							nestedIndex ===
																							mainIndex
																						) {
																							return {
																								...data,
																								isMarried:
																									e
																										.target
																										.value,
																							};
																						} else {
																							return data;
																						}
																					},
																				);

																			setProfileBuildingDetails(
																				{
																					...profileBuildingDetails,
																					siblingDetails:
																						newArray,
																				},
																			);
																		}}
																	/>
																</FormGroup>
															</div>

															<div
																className={
																	mainIndex >= 0
																		? 'flex justify-center items-center'
																		: 'hidden'
																}>
																<p
																	className='text-end font-bold text-blue-500 cursor-pointer'
																	onClick={(e) => {
																		const oldArray =
																			profileBuildingDetails.siblingDetails;

																		oldArray.pop();
																		setProfileBuildingDetails({
																			...profileBuildingDetails,
																			siblingDetails:
																				oldArray,
																		});
																	}}>
																	<Icon
																		icon={'Delete'}
																		color={'danger'}
																		size={'2x'}
																	/>
																</p>
															</div>
														</div>
													</>
												);
											},
										)}
										<div>
											<p
												className='text-end font-bold text-yellow-400'
												style={{ cursor: 'pointer' }}
												onClick={(e) => {
													const oldArray =
														profileBuildingDetails.siblingDetails;
													const newArray = [
														...oldArray,
														{
															name: '',
															occupation: '',
															age: '',
															gender: '',
															isAlive: '',
															isMarried: '',
														},
													];
													setProfileBuildingDetails({
														...profileBuildingDetails,
														siblingDetails: newArray,
													});
												}}>
												+ Add New
											</p>
										</div>
									</CardBody>
								</Card>
							</WizardItem>
						</Wizard>
					</div>
				</div>
			</div>
			// </div>
			//  </div>
		);
	} else if (isProfileCompleted == '') {
		return (
			<div className='w-[100%]'>
				{/* <AsideBar /> */}
				{/* <div className={asideStatus ? `md:ml-[15rem] ` : `md:ml-[5rem] mt-[5rem] md:mt-0`}> */}
				{/* <DashboardHeader />  */}
				<div className='flex justify-center items-center min-h-[80vh]'>
					<div className='w-[75%]'>
						<div>
							<ShimmerContentBlock
								title
								text
								cta
								thumbnailWidth={370}
								thumbnailHeight={370}
							/>
							<ShimmerContentBlock
								title
								text
								cta
								thumbnailWidth={370}
								thumbnailHeight={370}
							/>
							<ShimmerContentBlock
								title
								text
								cta
								thumbnailWidth={370}
								thumbnailHeight={370}
							/>
							<ShimmerContentBlock
								title
								text
								cta
								thumbnailWidth={370}
								thumbnailHeight={370}
							/>
						</div>
					</div>
				</div>
			</div>
			// </div>
		);
	}
};

export default Dashboard;
