import React, { useState } from 'react';

// import FormGroup from '../../components/bootstrap/forms/FormGroup';
// import Input from '../../components/bootstrap/forms/Input';
// import { Link } from 'react-router-dom';
// import InputGroup from '../../components/bootstrap/forms/InputGroup';
// import Button from '../../components/bootstrap/Button';
// import Dropdown, {
// 	DropdownItem,
// 	DropdownMenu,
// 	DropdownToggle,
// } from '../../components/bootstrap/Dropdown';
// import Select from '../../components/bootstrap/forms/Select';
// import { Label } from '../../components/icon/material-icons';
import Login from '../presentation/auth/Login';

const LoginPage = () => {
	// const [isUsedByDropDownOpen, setIsUsedByDropDownOpen] = useState(false);

	// const [signUpFields, setSignUpFields] = useState({
	// 	email: '',
	// 	password: '',
	// 	phone: '',
	// 	countryCode: '+91',
	// 	isVerified: 'false',
	// 	usedBy: '',
	// });

	return <Login />;
};

export default LoginPage;
