import React, { useEffect, useState } from 'react';
import PaginationButtons, { dataPagination } from '../../components/PaginationButtons';
import Card, { CardBody } from '../../components/bootstrap/Card';
import useSelectTable from '../../hooks/useSelectTable';
import useSortableData from '../../hooks/useSortableData';
import Icon from '../../components/icon/Icon';
import Checks from '../../components/bootstrap/forms/Checks';
import { Table } from 'react-bootstrap';

import axios from 'axios';

const PastTransactions = () => {
	let baseUrl = '';
	switch (process.env.REACT_APP_STATUS) {
		case 'DEV':
			baseUrl = process.env.REACT_APP_DEV_URL;
			break;
		case 'STAGE':
			baseUrl = process.env.REACT_APP_STAGE_URL;
			break;
		case 'PROD':
			baseUrl = process.env.REACT_APP_PROD_URL;
			break;
	}

	const [data, setData] = useState([]);

	useEffect(() => {
		async function getData() {
			try {
				const headers = {
					authorization: localStorage.getItem('token'),
				};
				const result = await axios.get(`${baseUrl}/api/v1/payment/getTransactionDetails`, {
					headers,
				});
				console.log(result);
				setData(result.data);
			} catch (err) {
				console.log(err);
			}
		}
		getData();
	}, []);

	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(10);

	const { items, requestSort, getClassNamesFor } = useSortableData(data);

	const onCurrentPageData = dataPagination(items, currentPage, perPage);

	const humanReadAbleDate = (str) => {
		return new Date(str);
	};

	return data.length > 0 ? (
		<>
			<div className='flex flex-col'>
				<div>
					<h1 className='ml-[2rem]'>Past Transactions</h1>
				</div>
				<div className='py-5 ml-[2rem]'>
					<div className='w-[80vw]'>
						<Card stretch={true}>
							<CardBody>
								<Table className='w-100'>
									<thead>
										<tr>
											<th onClick={() => requestSort('firstName')}>
												Si No
												<Icon
													size='lg'
													className={getClassNamesFor('firstName')}
													icon='FilterList'
												/>
											</th>
											<th onClick={() => requestSort('lastName')}>
												Subscription Type
												<Icon
													size='lg'
													className={getClassNamesFor('lastName')}
													icon='FilterList'
												/>
											</th>
											<th onClick={() => requestSort('lastName')}>
												Ammount
												<Icon
													size='lg'
													className={getClassNamesFor('lastName')}
													icon='FilterList'
												/>
											</th>
											<th onClick={() => requestSort('lastName')}>
												Transaction Id
												<Icon
													size='lg'
													className={getClassNamesFor('lastName')}
													icon='FilterList'
												/>
											</th>
											<th onClick={() => requestSort('lastName')}>
												Date & Time
												<Icon
													size='lg'
													className={getClassNamesFor('lastName')}
													icon='FilterList'
												/>
											</th>
										</tr>
									</thead>
									<tbody>
										{onCurrentPageData.map((item, index) => (
											<tr key={item._id.toString()}>
												<td>{index + 1}</td>
												<td>
													{item.subscripitonId
														? item.subscripitonId.name
														: 'Booster Pack'}
												</td>
												<td>{item.amount}</td>
												<td>
													{item.orderTransactionId
														? item.orderTransactionId
														: 'N/A'}
												</td>
												<td>{new Date(item.timestamp).toLocaleString()}</td>
											</tr>
										))}
									</tbody>
								</Table>
							</CardBody>
							<PaginationButtons
								data={items}
								label='items'
								setCurrentPage={setCurrentPage}
								currentPage={currentPage}
								perPage={perPage}
								setPerPage={setPerPage}
							/>
						</Card>
					</div>
				</div>
			</div>
		</>
	) : (
		<div className='ml-[2rem]' style={{ width: '100%' }}>
			<h1>Past Transactions</h1>
			<h5 style={{ textAlign: 'center' }}>No Transactions Found !!</h5>
		</div>
	);
};

export default PastTransactions;
