import React, { useContext } from 'react';
import Brand from '../../layout/Brand/Brand';
import Navigation, { NavigationLine } from '../../layout/Navigation/Navigation';
import ThemeContext from '../../contexts/themeContext';
import Aside, { AsideBody, AsideHead } from '../../layout/Aside/Aside';

export const dashboardPagesMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/',
		icon: 'Dashboard',
		subMenu: null,
	},
};

export const demoPagesMenu = {
	searchProfiles: {
		id: 'searchProfiles',
		text: 'Search Profiles',
		path: '/searchProfiles',
		icon: 'PersonSearch',
	},
	myMatches: {
		id: 'myMatches',
		text: 'My Matches',
		path: '/myMatches',
		icon: 'HowToReg',
	},
	myRequests: {
		id: 'myRequests',
		text: 'My Requests',
		path: '/myRequests',
		icon: 'Group Add',
	},
	subscriptions: {
		id: 'subscriptions',
		text: 'Subscriptions',
		path: '/subscriptions',
		icon: 'Redeem',
	},
	wishlist: {
		id: 'wishlist',
		text: 'Wishlist',
		path: '/wishlist',
		icon: 'Contacts',
	},
	myProfile: {
		id: 'myProfile',
		text: 'My Profile',
		path: '/myProfile',
		icon: 'Account Box',
	},
	logout: {
		id: 'logout',
		text: 'Log Out',
		path: '/logout',
		icon: 'Logout',
	},
};

const AsideBar = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);

	return (
		<div className='bg-[#700000]'>
		<Aside className="bg-success">
			<AsideHead className="bg-success">
				<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
			</AsideHead>
			<AsideBody className="bg-success">
				<Navigation menu={dashboardPagesMenu} id='aside-dashboard' />
				<NavigationLine />
				<>
					<Navigation menu={demoPagesMenu} id='aside-demo-pages' />
					<NavigationLine />
				</>
			</AsideBody>
		</Aside>
		</div>
	);
};

export default AsideBar;
