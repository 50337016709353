import React, { useEffect, useContext, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AsideBar from '../customLayout/AsideBar';
import CustomHeader from '../customLayout/Header';
import ThemeContext from '../../contexts/themeContext';
import DashboardHeader from '../_layout/_headers/DashboardHeader';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../components/bootstrap/Card';
import Button from '../../components/bootstrap/Button';
import { Favorite, FavoriteBorder } from '../../components/icon/material-icons';
import Icon from '../../components/icon/Icon';
import { Link } from 'react-router-dom';
import Badge from '../../components/bootstrap/Badge';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import { request } from 'http';
import { ShimmerContentBlock } from "react-shimmer-effects"

const staticData = [
	{
		id: '#001',
		name: 'Mithun S',
		age: 21,
		profession: 'Software Developer',
		salary: '10L - 20L',
		gothra: 'Bhargava Gotram',
		star: 'Revati nakshatra',
		caste: 'Bhramin',
		subCaste: 'Madhavas',
	},
	{
		id: '#001',
		name: 'Mithun S',
		age: 21,
		profession: 'Software Developer',
		salary: '10L - 20L',
		gothra: 'Bhargava Gotram',
		star: 'Revati nakshatra',
		caste: 'Bhramin',
		subCaste: 'Madhavas',
	},
];

const MyRequests = () => {
	const navigate = useNavigate();
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);

	let baseUrl = '';
	switch (process.env.REACT_APP_STATUS) {
		case 'DEV':
			baseUrl = process.env.REACT_APP_DEV_URL;
			break;
		case 'STAGE':
			baseUrl = process.env.REACT_APP_STAGE_URL;
			break;
		case 'PROD':
			baseUrl = process.env.REACT_APP_PROD_URL;
			break;
	}
	// const [showFallback,setShowFallback]= useState(null)

	//reload state variable

	const [reload,setReload]= useState(false)

    //requests received 
	 const [requestsReceivedIds, setRequestsReceivedIds]=useState([])
	 const [accept, setAccept]= useState([])

   useEffect(()=>{
		const requestsReceived = async()=>{
			try{
				const headers ={
					authorization: localStorage.getItem("token")
				}
         const requestsReceivedResponse= await axios.get(`${baseUrl}/api/v1/matchRequest/getMatchRequestRecievedDetails`,{
           headers,
				 })

				 console.log(requestsReceivedResponse)
				 if(requestsReceivedResponse.data.length>0){
					//  requestsReceivedIds=requestsReceivedResponse.data.map((each)=>each._id)
					const updatedArray = []
					const requestsIds=[]
					for(let i = 0 ; i <requestsReceivedResponse.data.length; i++){
						const userId = requestsReceivedResponse.data[i].requestRaisedBy
						
						const userData= await axios.post(`${baseUrl}/api/v1/profile/getProfileListingDetailsById`,{
							userId,
						},{
							headers,
						});
						updatedArray.push(userData.data.result[0]);
						
						requestsIds.push(requestsReceivedResponse.data[i]._id)
					}
					setRequestsReceivedIds(updatedArray);
					setAccept(requestsIds)
					
				 }
				 else{
					setRequestsReceivedIds([]);
					setAccept([])
					

				 }

			}
			catch(e){
          console.log(e)
			}
		}
		requestsReceived()
	 },[reload])

	  //requests sent
		const [requestsSent, setRequestsSent]=useState([])
		const [revoke, setRevoke]= useState([])

		useEffect(()=>{
		 const requestsSent = async()=>{
			 try{
				 const headers ={
					 authorization: localStorage.getItem("token")
				 }
					const requestsSentResponse= await axios.get(`${baseUrl}/api/v1/matchRequest/getMatchRequestSentDetails`,{
						headers,
					});
					
					if(requestsSentResponse.data.length>0){
					 //  requestsReceivedIds=requestsReceivedResponse.data.map((each)=>each._id)
					 const updatedArray = []
					 const revokeArray=[]
					 for(let i = 0 ; i <requestsSentResponse.data.length; i++){
						 const userId = requestsSentResponse.data[i].requestedTo
						 const userData = await axios.post(`${baseUrl}/api/v1/profile/getProfileListingDetailsById`,{
							 userId,
						 },{
							 headers,
						 });
                         revokeArray.push(requestsSentResponse.data[i]._id)
						 updatedArray.push(userData.data.result[0])
					 }
					 setRequestsSent(updatedArray)
					 setRevoke(revokeArray)
					}
					else{
						setRequestsSent([])
						setRevoke([])
					}
			 }
			 catch(e){
					 console.log(e)
			 }
		 }
		 requestsSent();
		},[reload])

		


	useEffect(() => {
		const getTokenDataAndNavigate = async () => {
			try {
				const tokenFromLocalStorage = localStorage.getItem('token');
				const result = await axios.post(`${baseUrl}/api/v1/user/verifyToken`, {
					token: tokenFromLocalStorage,
				});
				if (result.data === 'invalid') {
					navigate('/login');
				}
			} catch {
				navigate('/login');
			}
		};
		// getTokenDataAndNavigate();
	}, []);

	const [isProfileCompleted, setIsProfileCompleted] = useState("");

	useEffect(()=>{
		const getAllProfiles = async ()=>{
			try{
				const headers = {
					authorization: localStorage.getItem('token'),
				};
				const responseFromApi = await axios.get(`${baseUrl}/api/v1/profile/getProfileDetails`,{
					headers,
				});
				const {parents} = responseFromApi.data.result;
				if(parents!=undefined){
					setIsProfileCompleted("true")
				}
				else{
					setIsProfileCompleted("false")
				}
			}
			catch(e){
				console.log(e)
			}	
		}
		getAllProfiles()
	},[reload])

	 const acceptR= async (id)=>{
      const requestId = accept[id]
		try{
			const headers={
				authorization:localStorage.getItem('token')
			}
       const response = await axios.post(`${baseUrl}/api/v1/matchRequest/approveMatchRequest`,{
				requestId,
			 },{
				headers,
			 })
	
			 if(response){
				// alert("HEY match request accepted")
				toast.success('MATCH REQUEST ACCEPTED', {
					position: "top-right",
					autoClose: 2000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "dark",
					});
				setReload(prevReload=>!prevReload);

				// window.location.reload()
			 }	
		}
		catch(e){
			console.log(e)
		}
	 }

	const rejectR= async (id)=>{
		const requestId = accept[id]
	try{
		const headers={
			authorization:localStorage.getItem('token')
		}
		 const response = await axios.post(`${baseUrl}/api/v1/matchRequest/rejectMatchRequest`,{
			requestId,
		 },{
			headers,
		 })

		 if(response){
			// alert("HEY match request rejected")
			// window.location.reload()
			toast.success('MATCH REQUEST REJECTED', {
				position: "top-right",
				autoClose: 2000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "dark",
				});
			setReload(prevReload=>!prevReload);
		 }	
	}
	catch(e){
		console.log(e)
	}
 }
//  console.log(requestsSent)

const revokeR= async (id)=>{
	const requestId = revoke[id]
	console.log(requestId);
 try{
	const headers={
		authorization:localStorage.getItem('token')
	}
	console.log(localStorage.getItem("token"))

	 const response = await axios.delete(`${baseUrl}/api/v1/matchRequest/revokeMatchRequest`,{
		headers,
		data:{
			requestId,
		}
	 });

	 console.log(response)

	 if(response){
		setRevoke((prevItems) => prevItems.filter((item) => item !== requestId))
		// alert("HEY sent request revoked")
		toast.success('HEY SENT REQUEST REVOKED', {
			position: "top-right",
			autoClose: 2000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "dark",
			});
		setReload(prevReload=>!prevReload);
		// window.location.reload()
	 }	
}
catch(e){
	console.log(e)
}
}
if(isProfileCompleted=="true"){
	
	return (
		<div className='w-[100%]' key={reload}>
			 {/* <AsideBar /> */}

			{/* <div className={asideStatus ? `md:ml-[15rem] ` : `md:ml-[5rem] mt-[5rem] md:mt-0`}> */}
				{/* <DashboardHeader />  */}
				<div className={isProfileCompleted ? 'mx-3 md:mx-0 md:ml-[2rem] md:mt-[2rem]' : 'hidden'} >

					{ requestsReceivedIds.length > 0 &&
					<div className='mx-3 md:mx-0 md:ml-[2rem]'>
						<h1 className='ml-5'>Requests Received.</h1>
						<div className='flex flex-col items-center'>
							{requestsReceivedIds.length > 0 && (requestsReceivedIds.map((data,index) => {
								  const acceptRequest = ()=>{
										acceptR(index)
									
									}
									const rejectRequest = ()=>{
										rejectR(index)
									}
									
								return(	
								<Card key={data.id} className='mt-10 md:w-[90%]'>
									<div className='flex flex-col md:flex-row my-3 mx-5 gap-5 items-center'>
										<img
											src={data.images[0]!="" ? data.images[0]:'https://upload.wikimedia.org/wikipedia/commons/d/d1/Image_not_available.png?20210219185637'}
											alt='Tony Stark'
											className='w-full md:w-40 md:h-40 rounded'
										/>
										<div className='w-[100%]'>
											<h1>{data.name}</h1>
											<div className='md:flex gap-10 w-[100%]'>
												<div className='md:w-[50%]'>
													<h5>
													{Math.floor((new Date() - new Date(data.dateOfBirth)) / (365.25 * 24 * 60 * 60 * 1000))}
														, {data.experience.workTitle}
													</h5>
													<p>
														{data.gotra}, {data.star}
													</p>
												</div>
												<div>
													<h5>
														Salary:{' '}
														<span className='font-normal'>
															{data.incomeRange}
														</span>
													</h5>
													<p>
														{data.caste}, {data.subCaste}
													</p>
												</div>
											</div>
											<div className='grid grid-cols-1  md:grid-cols-2 md:gap-3 '>
											<div className='m-2'>
													<Button
													  style={{backgroundColor:"green" , color:"#ffffff"}}
														isOutline={false}
														className='w-full' onClick = {acceptRequest}>	
														Accept
													</Button>
												</div>

												<div className='m-2'>
													<Button
														color='danger'
														isOutline={true}
														className='w-full' onClick = {rejectRequest} >
														Reject
													</Button>
												</div>
											</div>
										</div>
									</div>
								</Card>)}
							))}
						</div>
					</div>
                     }
                   {requestsSent.length > 0 &&
					<div className='mx-3 md:mx-0 md:ml-[2rem]'>
						<h1 className='ml-5'>Requests Sent.</h1>
						<div className='flex flex-col items-center'>
							{requestsSent.length > 0 && (requestsSent.map((data,index) => {
							 const revokeRequest = ()=>{
								 revokeR(index)
							 }
							return (
								<Card key={data.id} className='mt-10 md:w-[90%]'>
									<div className='flex flex-col md:flex-row my-3 mx-2 gap-5 items-center'>
									<img
											src={data.images[0]? data.images[0]: 'https://upload.wikimedia.org/wikipedia/commons/d/d1/Image_not_available.png?20210219185637' }
											alt='Tony Stark'
											className='w-full md:w-40 md:h-40 rounded'
										/>
										<div className='w-[100%]'>
											
											<div className='flex items-center gap-3'>
												<h1>{data.name}</h1>
												<div >
												<Badge
													color='warning'
													className='p-2 px-4 text-black'>
													Match Request Sent
												</Badge>
												</div>
											</div>
											<div className='md:flex gap-10 w-[100%]'>
												<div className='md:w-[50%]'>
													<h5>
													{Math.floor((new Date() - new Date(data.dateOfBirth)) / (365.25 * 24 * 60 * 60 * 1000))}
														, {data.experience.workTitle}
													</h5>
													<p>
														{data.gotra}, {data.star}
													</p>
												</div>
												<div>
													<h5>
														Salary:{' '}
														<span className='font-normal'>
															{data.incomeRange}
														</span>
													</h5>
													<p>
														{data.caste}, {data.subCaste}
													</p>
												</div>
											</div>
											<div className='grid grid-cols-1  md:grid-cols-2 md:gap-3 '>
											<div className='m-2'>
												
												<Link to={`/viewProfile?id=${data.authId}`} className='md:w-[45%]'>
													<Button
														style={{backgroundColor:"#ffae00b5" , color:"black"}}
														isOutline={false}
														className='w-full' >
														View Profile
													</Button>
												</Link>
												</div>
												
												
												<div className='m-2'>
													<Button
														color='danger'
														isOutline={true}
														className='w-full' onClick={revokeRequest}>
														Revoke Request
													</Button>
											  </div>
											</div>
										</div>
									</div>
								</Card>)}))
							}
						</div>
				   
					</div>
                     } 
					 {
					requestsSent.length == 0 && requestsReceivedIds.length==0 && (
						<div className='flex flex-col items-center'>	
					<div className='w-[75%]'>
					<Card className='mt-3'>
						<CardBody className='mt-5'>
							<div className='flex flex-col items-center justify-center gap-3'>
								<div style={{display:"flex",justifyContent:"center",alignItems:"center",width:"100%"}}>
									<img
										src={
											'https://img.freepik.com/premium-vector/delete-mail-message-email-vector-icon_116137-3012.jpg?size=626&ext=jpg&ga=GA1.2.753895854.1691726990&semt=ais'
										}
										alt='USer Image'
										className='h-52  rounded-full bg-purple-100'
									/>
								</div>
								<div className='flex flex-col items-center gap-3 '>
									<div className='flex items-center gap-3 '>
										<h3 className='text-center'>
										      Requests Awaited
										</h3>
										{/* <span className='-mt-2'>
											<Icon color='warning' icon='People' size={'3x'} />
										</span> */}
									</div>
									<div>
										<p className='text-center'>
										Try sending new connection requests to initiate matches. 
										Your proactive approach can spark meaningful connections.
										</p>
									</div>
									<div className='w-[100%] flex justify-center'>
										<Button className='w-[75%]' color='warning' 
										onClick={()=>{
											navigate("/searchProfiles")
										}} >
											Send Requests
										</Button>
									</div>
								</div>
							</div>
						</CardBody>
					</Card>
				         </div>
						 </div>

						)
				}	                 
				</div>
			</div>
		//</div>
	);

}
else if( isProfileCompleted == 'false'){
	return(
		 <div className='w-[100%]'>
		{/* <AsideBar /> */}
		{/* <div className={asideStatus ? `md:ml-[15rem] ` : `md:ml-[5rem] mt-[5rem] md:mt-0`}> */}
			{/* <DashboardHeader /> */}
		<div className="flex justify-center items-center min-h-[80vh]">
			
			<div className='w-[75%]'>
				<Card>
					<CardBody>
						<div className='flex flex-col items-center justify-center gap-3'>
							<div>
								<img
									src={
										'https://facit-modern.omtanke.studio/static/media/wanna7.c92d88f942e3d84190de.webp'
									}
									alt='USer Image'
									className='w-36 h-36 border-black border-1 rounded-full bg-purple-100'
								/>
							</div>
							<div className='flex flex-col items-center gap-3 '>
								<div className='flex items-center gap-3 '>
									<h3 className='text-center'>
										Find Your Perfect Match: Complete Your Profile Now!{' '}
									</h3>
									{/* <span className='-mt-2'>
										<Icon color='warning' icon='AccountBox' size={'3x'} />
									</span> */}
								</div>
								<div>
									<p className='text-center'>
										Please complete your profile to continue. By
										providing us with comprehensive details about
										yourself, your interests, and your preferences, you
										enhance your chances of discovering the perfect
										match tailored to your unique personality and
										aspirations.
									</p>
								</div>
								<div className='w-[100%] flex justify-center'>
									<Button className='w-[75%]' color='warning' 
									onClick={()=>{
										navigate("/")
									}} >
										Complete Your Profile Now
									</Button>
								</div>
							</div>
						</div>
					</CardBody>
				</Card>
			</div>
		</div>
			
		</div>
	// </div>
	
	)
  }
  else if(isProfileCompleted==""){
	return(
		<div className='w-[100%]'>
		 {/* <AsideBar />  */}
		{/* <div className={asideStatus ? `md:ml-[15rem] ` : `md:ml-[5rem] mt-[5rem] md:mt-0`}> */}
			{/* <DashboardHeader />  */}
		<div className="flex justify-center items-center min-h-[80vh]">
			
			<div className='w-[75%]'>
			<div>
		 <ShimmerContentBlock
		title
		text
		cta
		thumbnailWidth={370}
		thumbnailHeight={370}
	  />
	   <ShimmerContentBlock
		title
		text
		cta
		thumbnailWidth={370}
		thumbnailHeight={370}
	  />
	   <ShimmerContentBlock
		title
		text
		cta
		thumbnailWidth={370}
		thumbnailHeight={370}
	  />
	   <ShimmerContentBlock
		title
		text
		cta
		thumbnailWidth={370}
		thumbnailHeight={370}
	  />

	  </div>
				
			</div>
		</div>
			
		</div>
	// </div>
		
	);
  }

	
};

export default MyRequests;
