//default and named exports
import React, { useEffect, useContext, useState } from 'react';
import { useSearchContext } from '../../contexts/searchContext';
import { useNavigate } from 'react-router-dom';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../components/bootstrap/Card';
import { Favorite, FavoriteBorder } from '../../components/icon/material-icons';
import { Link } from 'react-router-dom';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../components/bootstrap/Dropdown';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../components/bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ShimmerContentBlock } from 'react-shimmer-effects';

// default exports
import axios from 'axios';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Button from '../../components/bootstrap/Button';
import districts from '../../data/states-and-districts.json';
import DashboardHeader from '../_layout/_headers/DashboardHeader';
import states from '../../data/states.json';
import AsideBar from '../customLayout/AsideBar';
import CustomHeader from '../customLayout/Header';
import ThemeContext from '../../contexts/themeContext';
import Icon from '../../components/icon/Icon';
import Select from '../../components/bootstrap/forms/Select';
import Input from '../../components/bootstrap/forms/Input';
import './searchProfile.css';

const SearchProfiles = () => {
	const navigate = useNavigate();

	//contexts
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);
	const { name, setName } = useSearchContext();

	const [filterModalOpen, setFilterModalOpen] = useState(false);
	const incomeRanges = ['0 - 1L', '1L - 3L', '3L - 6L', '6L - 10L', '10L - 20L', '20L+'];
	const relationShipStatus = ['SINGLE', 'DIVORCED', 'WIDOW/WIDOWER'];

	let baseUrl = '';
	switch (process.env.REACT_APP_STATUS) {
		case 'DEV':
			baseUrl = process.env.REACT_APP_DEV_URL;
			break;
		case 'STAGE':
			baseUrl = process.env.REACT_APP_STAGE_URL;
			break;
		case 'PROD':
			baseUrl = process.env.REACT_APP_PROD_URL;
			break;
	}
	const senderId = '';

	// this is blocked data from db
	const [blockedUserDataRT, setBlockedUserDataRT] = useState([]);
	const [blockedUserDataRRB, setBlockedUserDataRRB] = useState([]);

	useEffect(() => {
		async function getBlockedDataFromDB() {
			try {
				const headers = {
					authorization: localStorage.getItem('token'),
				};
				const responseFromApi = await axios.get(
					`${baseUrl}/api/v1/matchRequest/getBlockedRequests`,
					{
						headers,
					},
				);
				const temp1 = [];
				const temp2 = [];
				for (let i = 0; i < responseFromApi.data.length; i++) {
					temp1.push(responseFromApi.data[i].requestedTo);
					temp1.push(responseFromApi.data[i].requestRaisedBy);
				}
				setBlockedUserDataRT(temp1);
				setBlockedUserDataRRB(temp2);
			} catch (err) {
				console.log(err);
			}
		}
		getBlockedDataFromDB();
	}, []);

	let currentUserAuth = '';

	// this is matchrequests data from db
	const [matchIds, setMatchIds] = useState([]);
	const [matchAds, setMatchAds] = useState([]);
	useEffect(() => {
		const myMatches = async () => {
			try {
				const headers = {
					authorization: localStorage.getItem('token'),
				};
				const responseFromApi = await axios.get(
					`${baseUrl}/api/v1/matchRequest/getAcceptedRequest`,
					{
						headers,
					},
				);
				const currentUserDetails = await axios.get(
					`${baseUrl}/api/v1/profile/getProfileDetails`,
					{
						headers,
					},
				);
				currentUserAuth = currentUserDetails.data.result.authId;
				const tempArray = [];
				const tempArray1 = [];

				if (responseFromApi.data.length > 0) {
					for (let i = 0; i < responseFromApi.data.length; i++) {
						tempArray.push(responseFromApi.data[i].requestedTo);
						tempArray1.push(responseFromApi.data[i].requestRaisedBy);
					}
				}
				setMatchIds(tempArray);
				setMatchAds(tempArray1);
			} catch (e) {
				console.log(e);
			}
		};
		myMatches();
	}, []);

	// this is requestsent data from db
	const [requestSentData, setRequestSentData] = useState([]);
	const [authIds, setAuthIds] = useState([]);
	const [reload, setReload] = useState(false);
	useEffect(() => {
		const requestsSent = async () => {
			try {
				const headers = {
					authorization: localStorage.getItem('token'),
				};
				const requestsSentResponse = await axios.get(
					`${baseUrl}/api/v1/matchRequest/getMatchRequestSentDetails`,
					{
						headers,
					},
				);

				if (requestsSentResponse.data.length > 0) {
					const updatedArray = [];
					const tempAuth = [];

					for (let i = 0; i < requestsSentResponse.data.length; i++) {
						tempAuth.push(requestsSentResponse.data[i].requestedTo);

						if (requestsSentResponse.data[i].requestStatus === 'PENDING') {
							const userId = requestsSentResponse.data[i].requestedTo;
							const userData = await axios.post(
								`${baseUrl}/api/v1/profile/getProfileListingDetailsById`,
								{
									userId,
								},
								{
									headers,
								},
							);

							updatedArray.push(userData.data.result[0]);
						}
						setAuthIds(tempAuth);
					}
					setRequestSentData(updatedArray);
				}
			} catch (e) {
				console.log(e);
			}
		};
		requestsSent();
	}, [reload]);

	// this is verifying user and navigating
	useEffect(() => {
		const getTokenDataAndNavigate = async () => {
			try {
				const tokenFromLocalStorage = localStorage.getItem('token');
				const result = await axios.post(`${baseUrl}/api/v1/user/verifyToken`, {
					token: tokenFromLocalStorage,
				});
				senderId = result.data;
				if (result.data === 'invalid') {
					navigate('/login');
				}
			} catch {
				navigate('/login');
			}
		};
		// getTokenDataAndNavigate();
	}, []);

	const [isProfileCompleted, setIsProfileCompleted] = useState('');
	useEffect(() => {
		const getAllProfiles = async () => {
			try {
				const headers = {
					authorization: localStorage.getItem('token'),
				};
				const responseFromApi = await axios.get(
					`${baseUrl}/api/v1/profile/getProfileDetails`,
					{
						headers,
					},
				);
				const { parents } = responseFromApi.data.result;
				if (parents != undefined) {
					setIsProfileCompleted('true');
				} else {
					setIsProfileCompleted('false');
				}
			} catch (e) {
				console.log(e);
			}
		};
		getAllProfiles();
	}, []);

	// this is getting allprofiles data  by gender from db
	const [allProfilesData, setAllProfilesData] = useState([]);
	useEffect(() => {
		const getAllProfilesByGender = async () => {
			try {
				const headers = {
					authorization: localStorage.getItem('token'),
				};
				const responseFromApi = await axios.get(
					`${baseUrl}/api/v1/profile/getAllProfilesByGender`,
					{
						headers,
					},
				);
				setAllProfilesData(responseFromApi.data.result);
			} catch (e) {
				console.log(e);
			}
		};
		getAllProfilesByGender();
	}, []);

	// this is currentuser details from db
	const getLoggedUserId = async () => {
		let senderId = '';
		try {
			const verifyTokenResponse = await axios.post(`${baseUrl}/api/v1/user/verifyToken`, {
				token: localStorage.getItem('token'),
			});
			if (verifyTokenResponse.data === 'invalid') {
				alert('please login to send request');
			} else {
				senderId = verifyTokenResponse.data;
			}
		} catch (e) {
			console.log(e);
		}
		return senderId;
	};

	// this is sending request to profiles functionality
	const sendRequestApi = async (id) => {
		const receiverId = id;
		const userSenderId = await getLoggedUserId();
		const headers = {
			authorization: localStorage.getItem('token'),
		};

		try {
			const matchRequestApiResponse = await axios.post(
				`${baseUrl}/api/v1/matchRequest/sendMatchRequest`,
				{
					recieverId: receiverId,
					senderId: userSenderId.data,
				},
				{
					headers,
				},
			);
			if (
				matchRequestApiResponse.statusText == 'Accepted' &&
				matchRequestApiResponse.status == 202
			) {
				toast.success('Request Sent Succesfully', {
					position: 'top-right',
					autoClose: 2000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'colored',
				});
				// alert('Request sent successfully');
				//  window.location.reload();
				setReload((prevReload) => !prevReload);
			}
		} catch (e) {
			toast.warn('REQUEST ALREADY SENT!', {
				position: 'top-right',
				autoClose: 2000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'colored',
			});
			// alert('request already send');
		}
	};

	//
	const deductCredits = async (id) => {
		try {
			const headers = {
				authorization: localStorage.getItem('token'),
			};
			const responseFromApi = await axios.post(
				`${baseUrl}/api/v1/userSubscription/deductUserCredits`,
				null,
				{
					headers,
				},
			);

			if (responseFromApi.data.msg === 'NO ACTIVE SUBSCRIPTION FOUND') {
				// alert("PLEASE BUY MAIN SUBSCRIPTION TO SEND REQUEST")
				toast.warn('PLEASE BUY SUBSCRIPTION TO SEND REQUEST!', {
					position: 'top-right',
					autoClose: 2000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'colored',
				});
			} else if (responseFromApi.data.msg === 'NO ACTIVE BOOSTER PACK FOUND') {
				// alert("PLEASE BUY BOOSTER SUBSCRIPTION TO SEND REQUEST")
				toast.warn('PLEASE BUY BOOSTER SUBSCRIPTION TO SEND REQUEST!', {
					position: 'top-right',
					autoClose: 2000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'colored',
				});
			} else {
				// alert("credit succesfully deducted")
				sendRequestApi(id);
			}
		} catch (e) {
			console.log(e);
		}
	};

	const [allWishListProfiles, setAllWishListProfiles] = useState([]); //3parameters
	const [wishListProfileDetails, setWishListProfileDetails] = useState([]); //profiledetails of wishlisted people
	const [wishIds, setWishIds] = useState([]);

	useEffect(() => {
		async function getWishListDataFromDB() {
			try {
				const headers = {
					authorization: localStorage.getItem('token'),
				};
				const responseFromApi = await axios.get(
					`${baseUrl}/api/v1/wishList/getWishListedProfiles`,
					{
						headers,
					},
				);
				const allProfileDetails = [];
				const allWishProfiles = [];
				const temp = [];
				for (let i = 0; i < responseFromApi.data.length; i++) {
					allWishProfiles.push(responseFromApi.data[i]);
					temp.push(responseFromApi.data[i].profileId);
					const profileDetails = await axios.post(
						`${baseUrl}/api/v1/profile/getProfileListingDetailsById`,
						{
							userId: responseFromApi.data[i].profileId,
						},
						{
							headers,
						},
					);
					allProfileDetails.push(profileDetails.data.result[0]);
				}
				setAllWishListProfiles(allWishProfiles);
				setWishListProfileDetails(allProfileDetails);
				setWishIds(temp);
			} catch (err) {
				console.log(err);
			}
		}
		getWishListDataFromDB();
	}, [reload]);

	async function removeFromWishList(id) {
		try {
			const headers = {
				authorization: localStorage.getItem('token'),
			};
			const responseFromApi = await axios.delete(
				`${baseUrl}/api/v1/wishList/removeFromWishList`,
				{
					headers,
					data: { wishListId: id },
				},
			);
			// alert('Removed From WishList');
			toast.success('Removed From Wishlist Successfully!', {
				position: 'top-right',
				autoClose: 2000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'dark',
			});
			setReload((prevReload) => !prevReload);
			// window.location.reload();
		} catch (err) {
			console.log(err);
		}
	}

	const addToWishList = async (sendigToId, data) => {
		const userSenderId = await getLoggedUserId();
		// alert(`Add To WishList: ${sendigToId} by ${userSenderId.data}`);
		try {
			const headers = {
				authorization: localStorage.getItem('token'),
			};
			const result = await axios.post(
				`${baseUrl}/api/v1/wishList/addToWishList`,
				{
					recieverId: sendigToId,
					senderId: userSenderId.data, //authid of user
				},
				{
					headers,
				},
			);
			if (result) {
				// alert('Added To WishList!!');
				toast.success('Added To WishList!', {
					position: 'top-right',
					autoClose: 2000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'colored',
				});
				setReload((prev) => !prev);
			}
		} catch (err) {
			const filterId = allWishListProfiles.filter((each) => each.profileId === sendigToId);
			await removeFromWishList(filterId[0]._id);
		}
	};

	//this is getting districts and state lists for applying filters
	const stateList = states
		.filter((e) => {
			return e.country_name == 'India';
		})
		.map((e) => e.name);

	const getDistrictsList = () => {
		try {
			if (filter.state) {
				return districts.states.filter((e) => {
					return e.state == filter.state;
				})[0].districts;
			} else {
				return [];
			}
		} catch {
			return [];
		}
	};

	//this is gettingstatic data from db
	const [staticData1, setStaticData1] = useState({
		education: [],
		occupation: [],
		subCastes: [],
		gotras: [],
		nakshatras: [],
		zodaicSigns: [],
		languages: [],
	});
	useEffect(() => {
		const getStaticDetails = async () => {
			try {
				const result = await axios.get(`${baseUrl}/api/v1/static/getStaticData`);

				if (result) {
					setStaticData1({
						...staticData1,
						education: result.data.education,
						occupation: result.data.occupation,
						subCastes: result.data.subCastes,
						gotras: result.data.gotras,
						nakshatras: result.data.nakshatras,
						zodaicSigns: result.data.zodaicSigns,
						languages: result.data.languages,
					});
				}
			} catch (e) {
				console.log(e);
			}
		};
		getStaticDetails();
	}, []);

	// this is filtering profiles based on user criteria
	const [filter, setFilter] = useState({
		subCastes: '',
		gotra: '',
		motherTongue: '',
		district: '',
		state: '',
		relationshipStatus: '',
		minAge: 0,
		maxAge: 100,
		minHeight: 0,
		maxHeight: 9,
		incomeRange: '',
		kujaDosh: false,
	});
	const [filteredProfiles, setFilteredProfiles] = useState(allProfilesData);
	useEffect(() => {
		setFilteredProfiles(allProfilesData);
	}, [allProfilesData]);
	const filterProfiles = async () => {
		try {
			console.log('------------');
			console.log(filter);
			const filteredResponse = await axios.post(
				`${baseUrl}/api/v1/profile/filteredProfiles`,
				filter,
				{
					headers: {
						authorization: localStorage.getItem('token'),
					},
				},
			);
			setFilteredProfiles(filteredResponse.data.result);
		} catch (e) {
			console.log(e);
		}
	};

	//this is search bar functionality
	const [searchedProfiles, setSearchedProfiles] = useState([]);
	useEffect(() => {
		if (name.length > 0) {
			const filterProfilesByName = async () => {
				const searchText = {
					name: name.length > 0 ? name : '',
				};

				try {
					const filteredResponse = await axios.post(
						`${baseUrl}/api/v1/profile/filteredProfilesByName`,
						searchText,
						{
							headers: {
								authorization: localStorage.getItem('token'),
							},
						},
					);
					setFilteredProfiles(filteredResponse.data.result);
					setName('');
				} catch (e) {
					console.log(e);
				}
			};
			filterProfilesByName();
		}
	}, [name]);

	const profileCard = (data) => {
		return (
			<Card key={data._id}>
				<div className='flex flex-col text-xs md:flex-row my-3 mx-5 gap-5 items-center'>
					<img
						src={
							data.images[0]
								? data.images[0]
								: 'https://upload.wikimedia.org/wikipedia/commons/d/d1/Image_not_available.png?20210219185637'
						}
						alt='Tony Stark'
						className='w-full md:w-40 md:h-40 rounded'
					/>
					<div className='w-[100%]'>
						<div
							className='inline absolute right-10 cursor-pointer'
							onClick={() => addToWishList(data.authId, data)}>
							{/* <Icon
							icon='Favorite'
							 color= "danger"
							size={'2x'}></Icon> */}
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='16'
								height='16'
								fill={wishIds.includes(data.authId) ? 'red' : '#CCCCCC'}
								class='bi bi-heart-fill'
								viewBox='0 0 16 16'>
								<path
									fill-rule='evenodd'
									d='M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z'
								/>
							</svg>
							{/* <i className="bi bi-heart" ></i> */}
						</div>
						<h1>{data.name}</h1>
						<div className='md:flex gap-10 w-[100%]'>
							<div className='md:w-[50%]'>
								<h5>
									{Math.floor(
										(new Date() - new Date(data.dateOfBirth)) /
											(365.25 * 24 * 60 * 60 * 1000),
									)}
									, {data.experience ? data.experience.workTitle : 'N/A'}
								</h5>
								<p>
									{data.gotra}, {data.star}
								</p>
							</div>
							<div>
								<h5>
									Salary: <span className='font-normal'>{data.incomeRange}</span>
								</h5>
								<p>
									{data.caste}, {data.subCaste}
								</p>
							</div>
						</div>
						<div className=' grid grid-cols-1  md:grid-cols-2 md:gap-3'>
							<div className='m-2'>
								<Link to={`/viewProfile?id=${data.authId}`} className='md:w-[45%]'>
									<Button
										style={{ backgroundColor: '#ffae00b5', color: 'black' }}
										isOutline={true}
										className='w-full'>
										View Profile
									</Button>
								</Link>
							</div>
							<div className='m-2'>
								<Button
									// style={{backgroundColor: "#700000", color:"#ffffff"}}
									disabled={authIds.includes(data.authId) ? true : false}
									type='button'
									color='danger'
									isOutline={true}
									className='w-full'
									onClick={() => {
										deductCredits(data.authId);
									}}>
									{authIds.includes(data.authId)
										? 'Request Sent'
										: 'Send Request'}
								</Button>
							</div>
						</div>
					</div>
				</div>
			</Card>
		);
	};
	const [kujaDosh, setKujaDosh] = useState('');

	if (isProfileCompleted == 'true') {
		return (
			<>
				<div className='w-[100%]'>
					{/* <AsideBar /> */}
					{/* <div className={asideStatus ? `md:ml-[15rem] ` : `md:ml-[5rem] mt-[5rem] md:mt-0`}> */}
					{/* <DashboardHeader /> */}

					{allProfilesData ? (
						<div
							className='mx-3 md:mx-0 md:ml-[2rem] md:mt-[2rem]'
							style={{ height: '100vh' }}>
							<div className='flex items-center gap-3 justify-between px-2'>
								<h1 className='md:ml-5 w-[70%] md:[w-100%] md:text-2xl '>
									Find Your Perfect Life Partner.
								</h1>

								<div className='flex justify-center md:hidden w-[28%]'>
									<Button
										onClick={() => setFilterModalOpen(!filterModalOpen)}
										color='warning'
										className='fs-6 md:hidden'
										size={'sm'}>
										Filters
									</Button>
								</div>
							</div>
							<div className='flex justify-between' style={{ height: '100vh' }}>
								<div
									className='md:w-[72%] mt-10'
									style={{
										height: '80vh',
										overflow: 'scroll',
										WebkitScrollbar: { display: 'none' },
									}}>
									{/* // searched profiles through name */}
									{searchedProfiles.length > 0 &&
										searchedProfiles.map((data) => {
											if (
												!matchIds.includes(data.authId) &&
												!matchAds.includes(data.authId) &&
												!blockedUserDataRT.includes(data.authId) &&
												!blockedUserDataRRB.includes(data.authId)
											) {
												return (
													<>
														{/* <h5> THIS IS SEARCHED PROFILES WITH NAME {name}</h5> */}
														{profileCard(data)}
													</>
												);
											}
										})}

									{/* filtered profiles */}
									{filteredProfiles &&
									filteredProfiles &&
									filteredProfiles.length > 0 &&
									searchedProfiles.length === 0 ? (
										filteredProfiles.map((data) => {
											if (
												!matchIds.includes(data.authId) &&
												!matchAds.includes(data.authId) &&
												!blockedUserDataRT.includes(data.authId) &&
												!blockedUserDataRRB.includes(data.authId)
											) {
												return (
													<>
														{/* <h5>THIS IS FILTERED PROFILES WITH GIVEN CRITERIA</h5> */}
														{profileCard(data)}
													</>
												);
											}
										})
									) : (
										<h5 className='ml-5'>No Profiles Found!!!</h5>
									)}
								</div>
								{/* this is apply filter div  */}
								<div
									style={{
										height: '80vh',
										overflow: 'scroll',
										borderRadius: '30px',
									}}
									className='hidden md:block md:w-[22%]  shadow-lg'>
									<Card>
										<div className='min-h-[80vh] rounded-3xl text-gray-900 mb-5'>
											<div>
												<h3 className='text-center py-3'>Apply Filters</h3>
											</div>

											<div className='px-3'>
												<p>Age Range</p>
												<div className='flex justify-around'>
													<input
														type='number'
														value={filter.minAge}
														min={0}
														onFocus={(e) =>
															e.target.addEventListener(
																'wheel',
																function (e) {
																	e.preventDefault();
																},
																{ passive: false },
															)
														}
														placeholder='Min'
														className='w-[44%] h-8 px-3 rounded border-1 border-gray-900'
														onChange={(e) => {
															const values = e.target.value;
															console.log(values);
															if (values >= 0 && values != '-') {
																setFilter({
																	...filter,
																	minAge: values,
																});
															}
														}}
													/>
													<input
														type='number'
														min={0}
														value={filter.maxAge}
														onFocus={(e) =>
															e.target.addEventListener(
																'wheel',
																function (e) {
																	e.preventDefault();
																},
																{ passive: false },
															)
														}
														placeholder='Max'
														className='w-[44%] h-8 px-3 rounded border-1 border-gray-900'
														onChange={(e) => {
															const value = e.target.value;
															if (
																e.target.value >= 0 &&
																e.target.value != '-'
															) {
																setFilter({
																	...filter,
																	maxAge: e.target.value,
																});
															}
														}}
														// onChange={(e)=>{
														// 	setFilter({...filter,maxAge:parseInt(e.target.value)})
														// }}
													/>
												</div>
											</div>

											<div className='px-3 pt-3'>
												<p>Height Range [ In ft ]</p>
												<div className='flex justify-around'>
													<input
														type='number'
														min='0'
														value={filter.minHeight}
														onFocus={(e) =>
															e.target.addEventListener(
																'wheel',
																function (e) {
																	e.preventDefault();
																},
																{ passive: false },
															)
														}
														placeholder='Min'
														className='w-[44%] h-8 px-3 rounded border-1 border-gray-900'
														// onChange={(e)=>{
														// 	setFilter({...filter,minHeight:parseInt(e.target.value)})
														// }}
														onChange={(e) => {
															const value = parseInt(e.target.value);
															setFilter({
																...filter,
																minHeight: value,
															});
														}}
													/>
													<input
														type='number'
														placeholder='Max'
														value={filter.maxHeight}
														onFocus={(e) =>
															e.target.addEventListener(
																'wheel',
																function (e) {
																	e.preventDefault();
																},
																{ passive: false },
															)
														}
														min='0'
														// onChange={(e)=>{
														// 	setFilter({parseInt(e.target.value) == null ? {{...filter,maxHeight:Number.MAX_SAFE_INTEGER}}: {{...filter,maxHeight:parseInt(e.target.value)}})
														// }}
														onChange={(e) => {
															const value = parseInt(e.target.value);
															setFilter({
																...filter,
																maxHeight: value,
															});
														}}
														className='w-[44%] h-8 px-3 rounded border-1 border-gray-900'
													/>
												</div>
											</div>

											<div className='px-3 pt-3'>
												<p>Sub Caste</p>
												<FormGroup>
													<Select
														placeholder='select subcaste'
														size='sm'
														id='gender'
														value={filter.subCastes}
														// list={[{ value: 'INDIA', text: 'INDIA' }]}
														list={staticData1.subCastes.map((e) => {
															return { value: e, text: e };
														})}
														onChange={(e) =>
															setFilter({
																...filter,
																subCastes: e.target.value,
															})
														}
													/>
												</FormGroup>
											</div>

											<div className='px-3 pt-3'>
												<p>Gotra</p>
												<FormGroup>
													<Select
														placeholder='select Gotra'
														size='sm'
														id='gender'
														value={filter.gotra}
														// list={[{ value: 'INDIA', text: 'INDIA' }]}
														list={staticData1.gotras.map((e) => {
															return { value: e, text: e };
														})}
														onChange={(e) =>
															setFilter({
																...filter,
																gotra: e.target.value,
															})
														}
													/>
												</FormGroup>
											</div>

											<div className='px-3 pt-3'>
												<p>Kuja Dosh</p>
												<div className='flex justify-around'>
													<Button
														className='w-[40%]'
														size={'sm'}
														// style={{backgroundColor:"#ffae00b5", color:"black"}}
														style={{
															backgroundColor:
																filter.kujaDosh === true
																	? '#ffae00b5'
																	: 'transparent',
															color:
																filter.kujaDosh === true
																	? 'black'
																	: 'inherit',
															border: 'none',
														}}
														onClick={(e) => {
															setFilter({
																...filter,
																kujaDosh: true,
															});
															setKujaDosh(true);
														}}>
														Yes
													</Button>
													<Button
														className='w-[40%]'
														size={'sm'}
														// color= {kujaDosh === false?'danger':'dark'}
														isOutline={false}
														style={{
															backgroundColor:
																filter.kujaDosh === false
																	? '#ffae00b5'
																	: 'transparent',
															color:
																filter.kujaDosh === false
																	? 'black'
																	: 'inherit',
															border: 'none',
														}}
														onClick={(e) => {
															setFilter({
																...filter,
																kujaDosh: false,
															});
															setKujaDosh(false);
														}}>
														No
													</Button>
												</div>
											</div>

											<div className='px-3 pt-3'>
												<p>Mother Tongue</p>
												<FormGroup>
													<Select
														placeholder='select mother tongue'
														size='sm'
														id='gender'
														value={filter.motherTongue}
														// list={[{ value: 'INDIA', text: 'INDIA' }]}
														list={staticData1.languages.map((e) => {
															return { value: e, text: e };
														})}
														onChange={(e) =>
															setFilter({
																...filter,
																motherTongue: e.target.value,
															})
														}
													/>
												</FormGroup>
											</div>

											<div className='px-3 pt-3'>
												<p>Income Range in Lakhs</p>
												<div className='flex justify-around'>
													<input
														type='range'
														name='range'
														id='range'
														min='0'
														step='1'
														max={incomeRanges.length - 1}
														placeholder='Enter Income Range'
														value={incomeRanges[filter.incomeRange]}
														color={'warning'}
														className='w-[98%] h-9 px-3 rounded border-1 range-field'
														onChange={(e) => {
															// setFilter({...filter,incomeRange:e.target.value})
															const selectedRange =
																incomeRanges[e.target.value];
															setFilter({
																...filter,
																incomeRange: selectedRange,
															});
														}}
													/>
												</div>
												<p>
													selected income Range :{' '}
													<strong style={{ color: '#700000' }}>
														{filter.incomeRange}
													</strong>
												</p>
											</div>

											<div className='px-3 pt-3'>
												<p>State</p>
												<FormGroup>
													<Select
														placeholder='select state'
														size='sm'
														id='gender'
														value={filter.state}
														// list={[{ value: 'INDIA', text: 'INDIA' }]}
														list={stateList.map((e) => {
															return {
																value: e,
																text: e,
															};
														})}
														onChange={(e) =>
															setFilter({
																...filter,
																state: e.target.value,
															})
														}
													/>
												</FormGroup>
											</div>

											<div className='px-3 pt-3'>
												<p>District</p>
												<FormGroup>
													<Select
														placeholder='select District'
														size='sm'
														id='gender'
														value={filter.district}
														// list={[{ value: 'INDIA', text: 'INDIA' }]}
														list={getDistrictsList()?.map((e) => {
															return {
																value: e,
																text: e,
															};
														})}
														onChange={(e) =>
															setFilter({
																...filter,
																district: e.target.value,
															})
														}
													/>
												</FormGroup>
											</div>

											<div className='px-3 pt-3'>
												<p>Relationship Status</p>

												<FormGroup>
													<Select
														placeholder='select relationship status'
														size='sm'
														id='gender'
														value={filter.relationshipStatus}
														// list={[{ value: 'INDIA', text: 'INDIA' }]}
														list={relationShipStatus.map((e) => {
															return {
																value: e,
																text: e,
															};
														})}
														onChange={(e) =>
															setFilter({
																...filter,
																relationshipStatus: e.target.value,
															})
														}
													/>
												</FormGroup>
											</div>

											<div className='p-3 py-3 mt-3'>
												<Button
													className='w-full'
													style={{
														backgroundColor: '#ffae00b5',
														color: 'black',
													}}
													onClick={() => {
														setSearchedProfiles([]);
														filterProfiles();
													}}>
													Filter
												</Button>
											</div>
											<div className='p-3 py-2'>
												<Button
													className='w-full'
													color='danger'
													isOutline={true}
													onClick={() => {
														setFilteredProfiles(allProfilesData);
														setSearchedProfiles([]);
														setFilter({
															subCastes: '',
															gotra: '',
															motherTongue: '',
															district: '',
															state: '',
															relationshipStatus: '',
															minAge: 0,
															maxAge: 100,
															minHeight: 0,
															maxHeight: 9,
															incomeRange: '',
															kujaDosh: false,
														});
													}}>
													Clear Filters
												</Button>
											</div>
										</div>
									</Card>
								</div>
							</div>
						</div>
					) : (
						<>
							<ShimmerContentBlock
								title
								text
								cta
								thumbnailWidth={370}
								thumbnailHeight={370}
							/>
						</>
					)}
					<Modal isOpen={filterModalOpen} setIsOpen={setFilterModalOpen}>
						<ModalHeader>
							<ModalTitle className='w-100 pt-2 d-sm-none d-md-block '>
								<div className='flex justify-between items-center w-[100%]'>
									<h3>Apply Filters</h3>
									<div onClick={() => setFilterModalOpen(!filterModalOpen)}>
										<Icon icon='close' color='dark' size={'2x'}></Icon>
									</div>
								</div>
							</ModalTitle>
						</ModalHeader>
						<ModalBody>
							<div className='w-100 min-h-[80vh] rounded-3xl text-gray-900 mb-5'>
								<div className='px-3'>
									<p>Age Range</p>
									<div className='flex justify-around'>
										<input
											type='number'
											value={filter.minAge}
											min={0}
											onFocus={(e) =>
												e.target.addEventListener(
													'wheel',
													function (e) {
														e.preventDefault();
													},
													{ passive: false },
												)
											}
											placeholder='Min'
											className='w-[44%] h-8 px-3 rounded border-1 border-gray-900'
											// onChange={(e)=>{
											// 	setFilter({...filter,minAge:parseInt(e.target.value)})
											// }}
											onChange={(e) => {
												const value = e.target.value;
												if (value >= 0 && value != '-') {
													setFilter({
														...filter,
														minAge: isNaN(value) ? 0 : value,
													});
												}
											}}
										/>
										<input
											type='number'
											min={0}
											onFocus={(e) =>
												e.target.addEventListener(
													'wheel',
													function (e) {
														e.preventDefault();
													},
													{ passive: false },
												)
											}
											value={filter.maxAge}
											placeholder='Max'
											className='w-[44%] h-8 px-3 rounded border-1 border-gray-900'
											onChange={(e) => {
												const value = e.target.value;
												if (e.target.value >= 0 && e.target.value != '-') {
													setFilter({
														...filter,
														maxAge: e.target.value,
													});
												}
											}}
											// onChange={(e)=>{
											// 	setFilter({...filter,maxAge:parseInt(e.target.value)})
											// }}
										/>
									</div>
								</div>

								<div className='px-3 pt-3'>
									<p>Height Range [ In ft ]</p>
									<div className='flex justify-around'>
										<input
											type='number'
											min='0'
											onFocus={(e) =>
												e.target.addEventListener(
													'wheel',
													function (e) {
														e.preventDefault();
													},
													{ passive: false },
												)
											}
											value={filter.minHeight}
											placeholder='Min'
											className='w-[44%] h-8 px-3 rounded border-1 border-gray-900'
											// onChange={(e)=>{
											// 	setFilter({...filter,minHeight:parseInt(e.target.value)})
											// }}
											onChange={(e) => {
												const value = parseInt(e.target.value);
												setFilter({
													...filter,
													minHeight: value,
												});
											}}
										/>
										<input
											type='number'
											placeholder='Max'
											value={filter.maxHeight}
											min='0'
											onFocus={(e) =>
												e.target.addEventListener(
													'wheel',
													function (e) {
														e.preventDefault();
													},
													{ passive: false },
												)
											}
											// onChange={(e)=>{
											// 	setFilter({parseInt(e.target.value) == null ? {{...filter,maxHeight:Number.MAX_SAFE_INTEGER}}: {{...filter,maxHeight:parseInt(e.target.value)}})
											// }}
											onChange={(e) => {
												const value = parseInt(e.target.value);
												setFilter({
													...filter,
													maxHeight: value,
												});
											}}
											className='w-[44%] h-8 px-3 rounded border-1 border-gray-900'
										/>
									</div>
								</div>

								<div className='px-3 pt-3'>
									<p>Sub Caste</p>
									<FormGroup>
										<Select
											placeholder='select subcaste'
											size='sm'
											id='gender'
											value={filter.subCastes}
											// list={[{ value: 'INDIA', text: 'INDIA' }]}
											list={staticData1.subCastes.map((e) => {
												return { value: e, text: e };
											})}
											onChange={(e) =>
												setFilter({
													...filter,
													subCastes: e.target.value,
												})
											}
										/>
									</FormGroup>
								</div>

								<div className='px-3 pt-3'>
									<p>Gotra</p>
									<FormGroup>
										<Select
											placeholder='select Gotra'
											size='sm'
											id='gender'
											value={filter.gotra}
											// list={[{ value: 'INDIA', text: 'INDIA' }]}
											list={staticData1.gotras.map((e) => {
												return { value: e, text: e };
											})}
											onChange={(e) =>
												setFilter({
													...filter,
													gotra: e.target.value,
												})
											}
										/>
									</FormGroup>
								</div>

								<div className='px-3 pt-3'>
									<p>Kuja Dosh</p>
									<div className='flex justify-around'>
										<Button
											className='w-[40%]'
											size={'sm'}
											// style={{backgroundColor:"#ffae00b5", color:"black"}}
											style={{
												backgroundColor:
													filter.kujaDosh === true
														? '#ffae00b5'
														: 'transparent',
												color:
													filter.kujaDosh === true ? 'black' : 'inherit',
												border: 'none',
											}}
											onClick={(e) => {
												setFilter({ ...filter, kujaDosh: true });
												setKujaDosh(true);
											}}>
											Yes
										</Button>
										<Button
											className='w-[40%]'
											size={'sm'}
											// color= {kujaDosh === false?'danger':'dark'}
											isOutline={false}
											style={{
												backgroundColor:
													filter.kujaDosh === false
														? 'transparent'
														: '#ffae00b5',
												color:
													filter.kujaDosh === false ? 'black' : 'inherit',
												border: 'none',
											}}
											onClick={(e) => {
												setFilter({ ...filter, kujaDosh: false });
												setKujaDosh(false);
											}}>
											No
										</Button>
									</div>
								</div>

								<div className='px-3 pt-3'>
									<p>Mother Tongue</p>
									<FormGroup>
										<Select
											placeholder='select mother tongue'
											size='sm'
											id='gender'
											value={filter.motherTongue}
											// list={[{ value: 'INDIA', text: 'INDIA' }]}
											list={staticData1.languages.map((e) => {
												return { value: e, text: e };
											})}
											onChange={(e) =>
												setFilter({
													...filter,
													motherTongue: e.target.value,
												})
											}
										/>
									</FormGroup>
								</div>

								<div className='px-3 pt-3'>
									<p>Income Range in Lakhs</p>
									<div className='flex justify-around'>
										<input
											type='range'
											min='0'
											step='1'
											max={incomeRanges.length - 1}
											placeholder='Enter Income Range'
											value={incomeRanges[filter.incomeRange]}
											color={'warning'}
											className='w-[98%] h-9 px-3 rounded border-1 range-field'
											// onInput={(event) => {
											// 	event.target.style.background = `#700000`
											//   event.target.style.webkitAppearance = 'none';
											//   event.target.style.webkitSliderThumb = 'width: 20px;  webkitAppearance:none; height: 20px; background: #700000; boxShadow:-410px 0 0 400px #700000; bordeRadius: 50%;';

											// }}
											onChange={(e) => {
												// setFilter({...filter,incomeRange:e.target.value})
												const selectedRange = incomeRanges[e.target.value];

												setFilter({
													...filter,
													incomeRange: selectedRange,
												});
											}}
										/>
									</div>
									<p>
										selected income Range :{' '}
										<strong style={{ color: '#700000' }}>
											{filter.incomeRange}
										</strong>
									</p>
								</div>

								<div className='px-3 pt-3'>
									<p>State</p>
									<FormGroup>
										<Select
											placeholder='select state'
											size='sm'
											id='gender'
											value={filter.state}
											// list={[{ value: 'INDIA', text: 'INDIA' }]}
											list={stateList.map((e) => {
												return { value: e, text: e };
											})}
											onChange={(e) =>
												setFilter({
													...filter,
													state: e.target.value,
												})
											}
										/>
									</FormGroup>
								</div>

								<div className='px-3 pt-3'>
									<p>District</p>
									<FormGroup>
										<Select
											placeholder='select District'
											size='sm'
											id='gender'
											value={filter.district}
											// list={[{ value: 'INDIA', text: 'INDIA' }]}
											list={getDistrictsList()?.map((e) => {
												return {
													value: e,
													text: e,
												};
											})}
											onChange={(e) =>
												setFilter({
													...filter,
													district: e.target.value,
												})
											}
										/>
									</FormGroup>
								</div>

								<div className='px-3 pt-3'>
									<p>Relationship Status</p>

									<FormGroup>
										<Select
											placeholder='select relationship status'
											size='sm'
											id='gender'
											value={filter.relationshipStatus}
											// list={[{ value: 'INDIA', text: 'INDIA' }]}
											list={relationShipStatus.map((e) => {
												return { value: e, text: e };
											})}
											onChange={(e) =>
												setFilter({
													...filter,
													relationshipStatus: e.target.value,
												})
											}
										/>
									</FormGroup>
								</div>

								<div className='p-3 py-3 mt-3'>
									<Button
										className='w-full'
										style={{ backgroundColor: '#ffae00b5', color: 'black' }}
										onClick={() => {
											setSearchedProfiles([]);
											filterProfiles();
										}}>
										Filter
									</Button>
								</div>
								<div className='p-3 py-2'>
									<Button
										className='w-full'
										color='danger'
										isOutline={true}
										onClick={() => {
											setFilteredProfiles([]);
											setSearchedProfiles([]);
											setFilter({
												subCastes: '',
												gotra: '',
												motherTongue: '',
												district: '',
												state: '',
												relationshipStatus: '',
												minAge: 0,
												maxAge: 100,
												minHeight: 0,
												maxHeight: 9,
												incomeRange: '',
												kujaDosh: '',
											});
										}}>
										Clear Filters
									</Button>
								</div>

								{/* <div className='px-3'>
											<p>Age Range</p>
											<div className='flex justify-around'>
												<input
													type='number'
													value={filter.minAge}
													min="0"
													placeholder='Min'
													className='w-[44%] h-9 px-3 rounded border-1 border-gray-900'
													onChange={(e)=>{
														setFilter({...filter,minAge:parseInt(e.target.value)})
													}}
												/>
												<input
													type='number'
													min="0"
													value={filter.maxAge}
													placeholder='Max'
													className='w-[44%] h-9 px-3 rounded border-1 border-gray-900'
													onChange={(e)=>{
														setFilter({...filter,maxAge:parseInt(e.target.value)})
													}}
												/>
											</div>
										</div>

										<div className='px-3 pt-3'>
											<p>Height Range</p>
											<div className='flex justify-around'>
												<input
													type='number'
													min="0"
													value={filter.minHeight}
													placeholder='Min'
													className='w-[44%] h-9 px-3 rounded border-1 border-gray-900'
													onChange={(e)=>{
														setFilter({...filter,minHeight:parseInt(e.target.value)})
													}}
												/>
												<input
													type='number'
													placeholder='Max'
													value={filter.maxHeight}
													min="0"
													onChange={(e)=>{
														setFilter({...filter,maxHeight:parseInt(e.target.value)})
													}}
													className='w-[44%] h-9 px-3 rounded border-1 border-gray-900'
												/>
											</div>
										</div>

										<div className='px-3 pt-3'>
											<p>Sub Caste</p>
											<FormGroup>
													<Select
														placeholder='select subcaste'
														size='sm'
														id='gender'
														value={filter.subCastes}
														// list={[{ value: 'INDIA', text: 'INDIA' }]}
														list={staticData1.subCastes.map((e) => {
															return { value: e, text: e };
														})}
														onChange={(e) =>
															setFilter({
																...filter,
																subCastes: e.target.value,
															})
														}
													/>
												</FormGroup>
											</div>

											<div className='px-3 pt-3'>
												<p>Gotra</p>
												<FormGroup>
													<Select
														placeholder='select Gotra'
														size='sm'
														id='gender'
														value={filter.gotra}
														// list={[{ value: 'INDIA', text: 'INDIA' }]}
														list={staticData1.gotras.map((e) => {
															return { value: e, text: e };
														})}
														onChange={(e) =>
															setFilter({
																...filter,
																gotra: e.target.value,
															})
														}
													/>
												</FormGroup>
											</div>

											<div className='px-3 pt-3'>
												<p>Gotra</p>
												<FormGroup>
													<Select
														placeholder='select Gotra'
														size='sm'
														id='gender'
														value={filter.gotra}
														// list={[{ value: 'INDIA', text: 'INDIA' }]}
														list={staticData1.gotras.map((e) => {
															return { value: e, text: e };
														})}
														onChange={(e) =>
															setFilter({
																...filter,
																gotra: e.target.value,
															})
														}
													/>
												</FormGroup>
											</div>

											<div className='px-3 pt-3'>
												<p>Kuja Dosh</p>
												<div className='flex justify-around'>
													<Button
														className='w-[40%]'
														size={'sm'}
														// style={{backgroundColor:"#ffae00b5", color:"black"}}
														style={{
															backgroundColor:
																kujaDosh === true
																	? '#ffae00b5'
																	: 'transparent',
															color:
																kujaDosh === true
																	? 'black'
																	: 'inherit',
															border: 'none',
														}}
														onClick={(e) => {
															setFilter({
																...filter,
																kujaDosh: true,
															});
															setKujaDosh(true);
														}}>
														Yes
													</Button>
													<Button
														className='w-[40%]'
														size={'sm'}
														// color= {kujaDosh === false?'danger':'dark'}
														isOutline={false}
														style={{
															backgroundColor:
																kujaDosh === false
																	? 'transparent'
																	: '#ffae00b5',
															color:
																kujaDosh === false
																	? 'black'
																	: 'inherit',
															border: 'none',
														}}
														onClick={(e) => {
															setFilter({
																...filter,
																kujaDosh: false,
															});
															setKujaDosh(false);
														}}>
														No
													</Button>
												</div>
											</div>

											<div className='px-3 pt-3'>
												<p>Mother Toungue</p>
												<FormGroup>
													<Select
														placeholder='select mother toungue'
														size='sm'
														id='gender'
														value={filter.motherTongue}
														// list={[{ value: 'INDIA', text: 'INDIA' }]}
														list={staticData1.languages.map((e) => {
															return { value: e, text: e };
														})}
														onChange={(e) =>
															setFilter({
																...filter,
																motherTongue: e.target.value,
															})
														}
													/>
												</FormGroup>
											</div>

											<div className='px-3 pt-3'>
												<p>Income Range in Lakhs</p>
												<div className='flex justify-around'>
													<input
														type='range'
														min='0'
														step='1'
														max={incomeRanges.length - 1}
														placeholder='Enter Income Range'
														value={incomeRanges[filter.incomeRange]}
														color={'warning'}
														className='w-[98%] h-9 px-3 rounded border-1 range-field'
														// onInput={(event) => {
														// 	event.target.style.background = `#700000`
														//   event.target.style.webkitAppearance = 'none';
														//   event.target.style.webkitSliderThumb = 'width: 20px;  webkitAppearance:none; height: 20px; background: #700000; boxShadow:-410px 0 0 400px #700000; bordeRadius: 50%;';

														// }}
														onChange={(e) => {
															// setFilter({...filter,incomeRange:e.target.value})
															const selectedRange =
																incomeRanges[e.target.value];
															console.log(e.target.value);
															setFilter({
																...filter,
																incomeRange: selectedRange,
															});
														}}
													/>
												</div>
												<p>
													selected income Range :{' '}
													<strong style={{ color: '#700000' }}>
														{filter.incomeRange}
													</strong>
												</p>
											</div>

											<div className='px-3 pt-3'>
												<p>City</p>
												<div className='flex justify-around'>
													<input
														type='text'
														placeholder='Enter City Name'
														className='w-[98%] h-8 px-3 rounded border-1 border-gray-900'
													/>
												</div>
											</div>

											<div className='px-3 pt-3'>
												<p>State</p>
												<FormGroup>
													<Select
														placeholder='select state'
														size='sm'
														id='gender'
														value={filter.state}
														// list={[{ value: 'INDIA', text: 'INDIA' }]}
														list={stateList.map((e) => {
															return { value: e, text: e };
														})}
														onChange={(e) =>
															setFilter({
																...filter,
																state: e.target.value,
															})
														}
													/>
												</FormGroup>
											</div>

											<div className='px-3 pt-3'>
												<p>District</p>
												<FormGroup>
													<Select
														placeholder='select District'
														size='sm'
														id='gender'
														value={filter.district}
														// list={[{ value: 'INDIA', text: 'INDIA' }]}
														list={getDistrictsList()?.map((e) => {
															return {
																value: e,
																text: e,
															};
														})}
														onChange={(e) =>
															setFilter({
																...filter,
																district: e.target.value,
															})
														}
													/>
												</FormGroup>
											</div>

											<div className='px-3 pt-3'>
												<p>Relationship Status</p>

												<FormGroup>
													<Select
														placeholder='select relationship status'
														size='sm'
														id='gender'
														value={filter.relationshipStatus}
														// list={[{ value: 'INDIA', text: 'INDIA' }]}
														list={relationShipStatus.map((e) => {
															return { value: e, text: e };
														})}
														onChange={(e) =>
															setFilter({
																...filter,
																relationshipStatus: e.target.value,
															})
														}
													/>
												</FormGroup>
											</div>

											<div className='p-3 py-3 mt-3'>
												<Button
													className='w-full'
													style={{
														backgroundColor: '#ffae00b5',
														color: 'black',
													}}
													onClick={() => {
														console.log(filter);
														console.log('this is filter data');
														setSearchedProfiles([]);
														filterProfiles();
													}}>
													Yes
												</Button>
												<Button
													className='w-[40%]'
													size={'sm'}
													// color= {kujaDosh === false?'danger':'dark'}
													isOutline={false}
													style={{
														backgroundColor:
															kujaDosh === false
																? '#ffae00b5'
																: 'transparent',
														color:
															kujaDosh === false
																? 'black'
																: 'inherit',
														border: 'none',
													}}
													onClick={(e) => {
														setFilter({ ...filter, kujaDosh: false });
														setKujaDosh(false);
													}}>
													No
												</Button>
											</div>
										</div>

										<div className='px-3 pt-3'>
											<p>Mother Toungue</p>
											<FormGroup>
												<Select
													placeholder='select mother toungue'
													size='sm'
													id='gender'
													value={filter.motherTongue}
													// list={[{ value: 'INDIA', text: 'INDIA' }]}
													list={staticData1.languages.map((e) => {
														return { value: e, text: e };
													})}
													onChange={(e) =>
														setFilter({
															...filter,
															motherTongue: e.target.value,
														})
													}
												/>
											</FormGroup>
										</div>

										<div className='px-3 pt-3'>
											<p>Income Range in Lakhs</p>
											<div className='flex justify-around'>
												<input
													type='range'
													min='0'
													step='1'
													max={incomeRanges.length - 1}
													placeholder='Enter Income Range'
													value={incomeRanges[filter.incomeRange]}
													color={'warning'}
													className='w-[98%] h-9 px-3 rounded border-1 rangeField'
													// onInput={(event) => {
													// 	event.target.style.background = `#700000`
													//   event.target.style.webkitAppearance = 'none';
													//   event.target.style.webkitSliderThumb = 'width: 20px;  webkitAppearance:none; height: 20px; background: #700000; boxShadow:-410px 0 0 400px #700000; bordeRadius: 50%;';

													// }}
													onChange={(e) => {
														// setFilter({...filter,incomeRange:e.target.value})
														const selectedRange =
															incomeRanges[e.target.value];
														setFilter({
															...filter,
															incomeRange: selectedRange,
														});
													}}
												/>
											</div>
											<p>
												selected income Range :{' '}
												<strong style={{ color: '#700000' }}>
													{filter.incomeRange}
												</strong>
											</p>
										</div>

										<div className='px-3 pt-3'>
											<p>State</p>
											<FormGroup>
												<Select
													placeholder='select state'
													size='sm'
													id='gender'
													value={filter.state}
													// list={[{ value: 'INDIA', text: 'INDIA' }]}
													list={stateList.map((e) => {
														return { value: e, text: e };
													})}
													onChange={(e) =>
														setFilter({
															...filter,
															state: e.target.value,
														})
													}
												/>
											</FormGroup>
										</div>

										<div className='px-3 pt-3'>
											<p>District</p>
											<FormGroup>
												<Select
													placeholder='select District'
													size='sm'
													id='gender'
													value={filter.district}
													// list={[{ value: 'INDIA', text: 'INDIA' }]}
													list={getDistrictsList()?.map((e) => {
														return {
															value: e,
															text: e,
														};
													})}
													onChange={(e) =>
														setFilter({
															...filter,
															district: e.target.value,
														})
													}
												/>
											</FormGroup>
										</div>

										<div className='px-3 pt-3'>
											<p>Relationship Status</p>

											<FormGroup>
												<Select
													placeholder='select relationship status'
													size='sm'
													id='gender'
													value={filter.relationshipStatus}
													// list={[{ value: 'INDIA', text: 'INDIA' }]}
													list={relationShipStatus.map((e) => {
														return { value: e, text: e };
													})}
													onChange={(e) =>
														setFilter({
															...filter,
															relationshipStatus: e.target.value,
														})
													}
												/>
											</FormGroup>
										</div>

										<div className='p-3 py-3 mt-3'>
											<Button
												className='w-full'
												style={{
													backgroundColor: '#ffae00b5',
													color: 'black',
												}}
												onClick={() => {
													setSearchedProfiles([]);
													filterProfiles();
												}}>
												Filter
											</Button>
										</div>
										<div className='p-3 py-2'>
											<Button
												className='w-full'
												color='danger'
												isOutline={true}
												onClick={() => {
													setFilteredProfiles(allProfilesData);
													setSearchedProfiles([]);
													setFilter({
														subCastes: '',
														gotra: '',
														motherTongue: '',
														district: '',
														state: '',
														relationshipStatus: '',
														minAge: 0,
														maxAge: 100,
														minHeight: 0,
														maxHeight: 0,
														incomeRange: '',
														kujaDosh: '',
													});
												}}>
												Clear Filters
											</Button>
										</div> */}
							</div>
						</ModalBody>
					</Modal>
				</div>
				{/* </div>  */}
			</>
		);
	} else if (isProfileCompleted == 'false') {
		return (
			<div className='w-[100%]'>
				{/* <AsideBar /> */}
				{/* <div className={asideStatus ? `md:ml-[15rem] ` : `md:ml-[5rem] mt-[5rem] md:mt-0`}> */}
				{/* <DashboardHeader />  */}
				<div className='flex justify-center items-center min-h-[80vh]'>
					<div className='w-[75%]'>
						<Card>
							<CardBody>
								<div className='flex flex-col items-center justify-center gap-3'>
									<div>
										<img
											src={
												'https://facit-modern.omtanke.studio/static/media/wanna7.c92d88f942e3d84190de.webp'
											}
											alt='USer Image'
											className='w-36 h-36 border-black border-1 rounded-full bg-purple-100'
										/>
									</div>
									<div className='flex flex-col items-center gap-3 '>
										<div className='flex items-center gap-3 '>
											<h3 className='text-center'>
												Find Your Perfect Match: Complete Your Profile Now!{' '}
											</h3>
											{/* <span className='-mt-2'>
										<Icon color='warning' icon='AccountBox' size={'3x'} />
									</span> */}
										</div>
										<div>
											<p className='text-center'>
												Please complete your profile to continue. By
												providing us with comprehensive details about
												yourself, your interests, and your preferences, you
												enhance your chances of discovering the perfect
												match tailored to your unique personality and
												aspirations.
											</p>
										</div>
										<div className='w-[100%] flex justify-center'>
											<Button
												className='w-[75%]'
												color='warning'
												onClick={() => {
													navigate('/');
												}}>
												Complete Your Profile Now
											</Button>
										</div>
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
				</div>
			</div>
			//  </div>
		);
	} else if (isProfileCompleted == '') {
		return (
			<div className='w-[100%]'>
				{/* <AsideBar /> */}
				{/* <div className={asideStatus ? `md:ml-[15rem] ` : `md:ml-[5rem] mt-[5rem] md:mt-0`}> */}
				{/* <DashboardHeader />  */}
				<div className='flex justify-center items-center min-h-[80vh]'>
					<div className='w-[75%]'>
						<div>
							<ShimmerContentBlock
								title
								text
								cta
								thumbnailWidth={370}
								thumbnailHeight={370}
							/>
							<ShimmerContentBlock
								title
								text
								cta
								thumbnailWidth={370}
								thumbnailHeight={370}
							/>
							<ShimmerContentBlock
								title
								text
								cta
								thumbnailWidth={370}
								thumbnailHeight={370}
							/>
							<ShimmerContentBlock
								title
								text
								cta
								thumbnailWidth={370}
								thumbnailHeight={370}
							/>
						</div>
					</div>
				</div>
			</div>
			//  </div>
		);
	}
};

export default SearchProfiles;
