import React, { useEffect, useContext, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AsideBar from '../customLayout/AsideBar';
import CustomHeader from '../customLayout/Header';
import ThemeContext from '../../contexts/themeContext';
import DashboardHeader from '../_layout/_headers/DashboardHeader';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../components/bootstrap/Card';
import Button from '../../components/bootstrap/Button';
import { Favorite, FavoriteBorder } from '../../components/icon/material-icons';
import Icon from '../../components/icon/Icon';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ShimmerContentBlock } from 'react-shimmer-effects';

const Wishlist = () => {
	const navigate = useNavigate();
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);

	let baseUrl = '';
	switch (process.env.REACT_APP_STATUS) {
		case 'DEV':
			baseUrl = process.env.REACT_APP_DEV_URL;
			break;
		case 'STAGE':
			baseUrl = process.env.REACT_APP_STAGE_URL;
			break;
		case 'PROD':
			baseUrl = process.env.REACT_APP_PROD_URL;
			break;
	}
	const [reload, setReload] = useState(false);

	useEffect(() => {
		const getTokenDataAndNavigate = async () => {
			try {
				const tokenFromLocalStorage = localStorage.getItem('token');
				const result = await axios.post(`${baseUrl}/api/v1/user/verifyToken`, {
					token: tokenFromLocalStorage,
				});
				if (result.data === 'invalid') {
					navigate('/login');
				}
			} catch {
				navigate('/login');
			}
		};
		// getTokenDataAndNavigate();
	}, []);

	const [isProfileCompleted, setIsProfileCompleted] = useState('');

	useEffect(() => {
		const getAllProfiles = async () => {
			try {
				const headers = {
					authorization: localStorage.getItem('token'),
				};
				const responseFromApi = await axios.get(
					`${baseUrl}/api/v1/profile/getProfileDetails`,
					{
						headers,
					},
				);
				const { parents } = responseFromApi.data.result;
				if (parents != undefined) {
					setIsProfileCompleted('true');
				} else {
					setIsProfileCompleted('false');
				}
			} catch (e) {
				console.log(e);
			}
		};
		getAllProfiles();
	}, []);

	const [allWishListProfiles, setAllWishListProfiles] = useState([]);
	const [wishListProfileDetails, setWishListProfileDetails] = useState([]);
	const [showFallback, setShowFallback] = useState(null);

	useEffect(() => {
		async function getWishListDataFromDB() {
			try {
				const headers = {
					authorization: localStorage.getItem('token'),
				};
				const responseFromApi = await axios.get(
					`${baseUrl}/api/v1/wishList/getWishListedProfiles`,
					{
						headers,
					},
				);
				console.log(responseFromApi);
				const allProfileDetails = [];
				const allWishProfiles = [];
				for (let i = 0; i < responseFromApi.data.length; i++) {
					allWishProfiles.push(responseFromApi.data[i]);
					const profileDetails = await axios.post(
						`${baseUrl}/api/v1/profile/getProfileListingDetailsById`,
						{
							userId: responseFromApi.data[i].profileId,
						},
						{
							headers,
						},
					);
					allProfileDetails.push(profileDetails.data.result[0]);
				}
				setAllWishListProfiles(allWishProfiles);
				setWishListProfileDetails(allProfileDetails);
				console.log(allWishListProfiles);
				console.log(wishListProfileDetails);
				// setShowFallback(false)
				if (responseFromApi.data.length > 0) {
					setShowFallback(false);
				} else {
					setShowFallback(true);
				}
			} catch (err) {
				console.log(err);
				setShowFallback(true);
			}
		}
		getWishListDataFromDB();
	}, [reload]);

	const getLoggedUserId = async () => {
		let senderId = '';
		try {
			const verifyTokenResponse = await axios.post(`${baseUrl}/api/v1/user/verifyToken`, {
				token: localStorage.getItem('token'),
			});
			if (verifyTokenResponse.data === 'invalid') {
				alert('please login to send request');
			} else {
				senderId = verifyTokenResponse.data;
			}
		} catch (e) {
			console.log(e);
		}
		return senderId;
	};

	async function removeFromWishList(index) {
		try {
			const headers = {
				authorization: localStorage.getItem('token'),
			};
			const responseFromApi = await axios.delete(
				`${baseUrl}/api/v1/wishList/removeFromWishList`,
				{
					headers,
					data: { wishListId: allWishListProfiles[index]._id },
				},
			);
			// alert('Removed From WishList');
			toast.success('Removed From Wishlist Successfully!', {
				position: 'top-right',
				autoClose: 2000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'dark',
			});
			setReload((prevReload) => !prevReload);
			// window.location.reload();
		} catch (err) {
			console.log(err);
		}
	}

	async function sendMatchRequest(index) {
		const userId = await getLoggedUserId();
		const sendingTo = wishListProfileDetails[index].authId;
		try {
			const headers = {
				authorization: localStorage.getItem('token'),
			};
			const bodyParams = {
				senderId: userId.data,
				recieverId: sendingTo,
			};
			const profileDetails = await axios.post(
				`${baseUrl}/api/v1/matchRequest/sendMatchRequest`,
				bodyParams,
				{
					headers,
				},
			);
			removeFromWishList(index);
			// alert('Request Sent Succesfully !!');
			toast.success('Request Sent Successfully!', {
				position: 'top-right',
				autoClose: 2000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'dark',
			});
			setReload((prevReload) => !prevReload);
			// window.location.reload();
		} catch (err) {
			toast.warn(err.response.data.msg, {
				position: 'top-right',
				autoClose: 2000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'dark',
			});
			// alert(err.response.data.msg);
			console.log(err);
		}
	}
	const [requestsSent, setRequestsSent] = useState([]);

	useEffect(() => {
		const requestSent = async () => {
			try {
				const headers = {
					authorization: localStorage.getItem('token'),
				};
				const requestsSentResponse = await axios.get(
					`${baseUrl}/api/v1/matchRequest/getMatchRequestSentDetails`,
					{
						headers,
					},
				);
				const requestsRecievedResponse = await axios.get(
					`${baseUrl}/api/v1/matchRequest/getMatchRequestRecievedDetails`,
					{
						headers,
					},
				);
				const getAccepted = await axios.get(
					`${baseUrl}/api/v1/matchRequest/getAcceptedRequest`,
					{
						headers,
					},
				);
				const currentUserDetails = await axios.get(
					`${baseUrl}/api/v1/profile/getProfileDetails`,
					{
						headers,
					},
				);

				if (getAccepted.data.length > 0) {
					const updatedArray = [];
					for (let i = 0; i < getAccepted.data.length; i++) {
						const userId =
							currentUserDetails.data.result.authId ==
							getAccepted.data[i].requestRaisedBy
								? getAccepted.data[i].requestedTo
								: getAccepted.data[i].requestRaisedBy;

						updatedArray.push(userId);
					}

					setRequestsSent([...requestsSent, ...updatedArray]);
				}
				if (requestsRecievedResponse.data.length > 0) {
					const updatedArray = [];
					for (let i = 0; i < requestsRecievedResponse.data.length; i++) {
						const userId = requestsRecievedResponse.data[i].requestRaisedBy;
						updatedArray.push(userId);
					}
					setRequestsSent([...requestsSent, ...updatedArray]);
				}

				if (requestsSentResponse.data.length > 0) {
					//  requestsReceivedIds=requestsReceivedResponse.data.map((each)=>each._id)
					const updatedArray = [];

					for (let i = 0; i < requestsSentResponse.data.length; i++) {
						const userId = requestsSentResponse.data[i].requestedTo;
						updatedArray.push(userId);
					}

					setRequestsSent([...requestsSent, ...updatedArray]);
				}
				console.log('---------------------');
				console.log(requestsSent);
			} catch (e) {
				console.log(e);
			}
		};
		requestSent();
	}, [reload]);

	const deductCredits = async (index) => {
		try {
			const headers = {
				authorization: localStorage.getItem('token'),
			};
			const responseFromApi = await axios.post(
				`${baseUrl}/api/v1/userSubscription/deductUserCredits`,
				null,
				{
					headers,
				},
			);
			console.log(responseFromApi, 'this is deduct response');
			if (responseFromApi.data.msg === 'NO ACTIVE SUBSCRIPTION FOUND') {
				// alert("PLEASE BUY MAIN SUBSCRIPTION TO SEND REQUEST")
				toast.warn('PLEASE BUY MAIN SUBSCRIPTION TO SEND REQUEST', {
					position: 'top-right',
					autoClose: 2000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'dark',
				});
			} else if (responseFromApi.data.msg === 'NO ACTIVE BOOSTER PACK FOUND') {
				// alert("PLEASE BUY BOOSTER SUBSCRIPTION TO SEND REQUEST")
				toast.warn('PLEASE BUY BOOSTER SUBSCRIPTION TO SEND REQUEST', {
					position: 'top-right',
					autoClose: 2000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'dark',
				});
			} else {
				// navigate(`/viewProfile?id=${id}`)
				sendMatchRequest(index);
			}
		} catch (e) {
			console.log(e);
		}
	};
	console.log(wishListProfileDetails);
	if (isProfileCompleted == 'true' && wishListProfileDetails.length > 0) {
		return (
			<div className='w-[100%]'>
				{/* <AsideBar /> */}

				{/* <div className={asideStatus ? `md:ml-[15rem] ` : `md:ml-[5rem] mt-[5rem] md:mt-0`}> */}
				{/* <DashboardHeader /> */}
				<div className="mx-3 md:mx-0 md:ml-[2rem] md:mt-4'">
					<h1 className='ml-5'>Your WishList.</h1>
					<div className='flex flex-col items-center'>
						{wishListProfileDetails.map((data, index) => (
							<Card key={data.id} className='mt-10 md:w-[90%]'>
								<div className='flex flex-col md:flex-row my-3 mx-5 gap-5 items-center'>
									<img
										src={
											data.images
												? data.images[0]
												: 'https://wipy.tv/wp-content/uploads/2020/05/alcoholismo-de-tony-stark-en-el-mcu-1200x900.jpg'
										}
										alt='Tony Stark'
										className='w-full md:w-40 md:h-40 rounded'
									/>
									<div className='w-[100%]'>
										<div
											className='inline absolute  right-10'
											style={{ cursor: 'pointer' }}
											onClick={() => removeFromWishList(index)}>
											{/* <Icon
													icon='Favorite'
													color='secondary'
													size={'2x'}></Icon> */}
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='16'
												height='16'
												fill='red'
												class='bi bi-heart-fill'
												viewBox='0 0 16 16'>
												<path
													fill-rule='evenodd'
													d='M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z'
												/>
											</svg>
										</div>
										<h1>{data.name}</h1>
										<div className='md:flex gap-10 w-[100%]'>
											<div className='md:w-[50%]'>
												<h5>
													{Math.floor(
														(new Date() - new Date(data.dateOfBirth)) /
															(365.25 * 24 * 60 * 60 * 1000),
													)}
													, {data.experience.workTitle}
												</h5>
												<p>
													{data.gotra}, {data.star}
												</p>
											</div>
											<div>
												<h5>
													Salary:{' '}
													<span className='font-normal'>
														{data.incomeRange}
													</span>
												</h5>
												<p>
													{data.caste}, {data.subCaste}
												</p>
											</div>
										</div>
										<div className='grid grid-cols-1  md:grid-cols-2 md:gap-3 '>
											<div className='m-2'>
												<Link
													to={`/viewProfile?id=${data.authId}`}
													className='md:w-[45%]'>
													<Button
														style={{
															backgroundColor: '#ffae00b5',
															color: 'black',
															border: 'none',
														}}
														color='info'
														isOutline={true}
														className='w-full'>
														View Profile
													</Button>
												</Link>
											</div>

											<div className='m-2'>
												<Button
													//   style={{backgroundColor: "#700000", color:"#ffffff"}}
													className='w-full'
													color='danger'
													isOutline={true}
													disabled={
														requestsSent.includes(data.authId)
															? true
															: false
													}
													onClick={() => deductCredits(index)}>
													{requestsSent.includes(data.authId)
														? 'Request Sent'
														: 'Send Request'}
												</Button>
											</div>
										</div>
									</div>
								</div>
							</Card>
						))}
					</div>
				</div>
			</div>
			// </div>
		);
	} else if (showFallback && isProfileCompleted == 'true') {
		return (
			<div className='w-[100%]'>
				{/* <AsideBar /> */}

				{/* <div className={asideStatus ? `md:ml-[15rem] ` : `md:ml-[5rem] mt-[5rem] md:mt-0`}> */}
				{/* <DashboardHeader />  */}
				<div
					className={
						isProfileCompleted === 'true'
							? 'mx-3 md:mx-0 md:ml-[2rem] md:mt-4'
							: 'hidden'
					}>
					<h1 className='ml-5'>Your WishList.</h1>
					<div className='flex flex-col items-center'>
						<div className='w-[75%]'>
							<Card className='mt-3'>
								<CardBody className='mt-5'>
									<div className='flex flex-col items-center justify-center gap-3'>
										<div
											style={{
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
												width: '100%',
											}}>
											<img
												src={
													'https://img.freepik.com/premium-vector/wishlist-icon-comic-style-like-document-cartoon-vector-illustration-white-isolated-background-favorite-list-splash-effect-business-concept_157943-11428.jpg?w=740'
												}
												alt='USer Image'
												className='h-52  rounded-full bg-purple-100'
											/>
										</div>
										<div className='flex flex-col items-center gap-3 '>
											<div className='flex items-center gap-3 '>
												<h3 className='text-center'>
													Your Wishlist is Waiting
												</h3>
												{/* <span className='-mt-2'>
											<Icon style={{color:"red"}} icon='Favorite' size={'3x'} />
										</span> */}
											</div>
											<div>
												<p className='text-center'>
													It looks like your wishlist is empty right now.
													Explore profiles and discover individuals who
													align with your preferences and aspirations.
													Adding profiles to your wishlist is a great way
													to express interest and keep track of potential
													connections.
												</p>
											</div>
											<div className='w-[100%] flex justify-center'>
												<Button
													className='w-[75%]'
													color='warning'
													onClick={() => {
														navigate('/searchProfiles');
													}}>
													Start Exploring
												</Button>
											</div>
										</div>
									</div>
								</CardBody>
							</Card>
						</div>
					</div>
				</div>
			</div>
			// </div>
		);
	} else if (isProfileCompleted == 'false') {
		return (
			<div className='w-[100%]'>
				{/* <AsideBar /> */}
				{/* <div className={asideStatus ? `md:ml-[15rem] ` : `md:ml-[5rem] mt-[5rem] md:mt-0`}> */}
				{/* <DashboardHeader />  */}
				<div className='flex justify-center items-center min-h-[80vh]'>
					<div className='w-[75%]'>
						<Card>
							<CardBody>
								<div className='flex flex-col items-center justify-center gap-3'>
									<div>
										<img
											src={
												'https://facit-modern.omtanke.studio/static/media/wanna7.c92d88f942e3d84190de.webp'
											}
											alt='USer Image'
											className='w-36 h-36 border-black border-1 rounded-full bg-purple-100'
										/>
									</div>
									<div className='flex flex-col items-center gap-3 '>
										<div className='flex items-center gap-3 '>
											<h3 className='text-center'>
												Find Your Perfect Match: Complete Your Profile Now!{' '}
											</h3>
											{/* <span className='-mt-2'>
											<Icon color='warning' icon='AccountBox' size={'3x'} />
										</span> */}
										</div>
										<div>
											<p className='text-center'>
												Please complete your profile to continue. By
												providing us with comprehensive details about
												yourself, your interests, and your preferences, you
												enhance your chances of discovering the perfect
												match tailored to your unique personality and
												aspirations.
											</p>
										</div>
										<div className='w-[100%] flex justify-center'>
											<Button
												className='w-[75%]'
												color='warning'
												onClick={() => {
													navigate('/');
												}}>
												Complete Your Profile Now
											</Button>
										</div>
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
				</div>
			</div>
			// </div>
		);
	} else if (isProfileCompleted == '') {
		return (
			<div className='w-[100%]'>
				{/* <AsideBar /> */}
				{/* <div className={asideStatus ? `md:ml-[15rem] ` : `md:ml-[5rem] mt-[5rem] md:mt-0`}> */}
				{/* <DashboardHeader />  */}
				<div className='flex justify-center items-center min-h-[80vh]'>
					<div className='w-[75%]'>
						<div>
							<ShimmerContentBlock
								title
								text
								cta
								thumbnailWidth={370}
								thumbnailHeight={370}
							/>
							<ShimmerContentBlock
								title
								text
								cta
								thumbnailWidth={370}
								thumbnailHeight={370}
							/>
							<ShimmerContentBlock
								title
								text
								cta
								thumbnailWidth={370}
								thumbnailHeight={370}
							/>
							<ShimmerContentBlock
								title
								text
								cta
								thumbnailWidth={370}
								thumbnailHeight={370}
							/>
						</div>
					</div>
				</div>
			</div>
			//  </div>
		);
	}
};

export default Wishlist;
