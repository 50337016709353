import React, { createContext, FC, useContext, useState } from 'react';

interface ISearchContextProps {
  name: string;
  setName: (name: string) => void;
  userName: string;
  userPlan: string;
  userImage: string;
  setUserDetails: (name: string, plan: string, image: string) => void;
}

const SearchContext = createContext<ISearchContextProps>({
  name: '',
  setName: () => {},
  userName: '',
  userPlan: '',
  userImage: '',
  setUserDetails: () => {},
});

export const useSearchContext = () => useContext(SearchContext);

interface ISearchContextProviderProps {
  children: React.ReactNode;
}

export const SearchContextProvider: FC<ISearchContextProviderProps> = ({
  children,
}) => {
  const [name, setName] = useState<string>('');
  const [userName, setUserName] = useState<string>('');
  const [userPlan, setUserPlan] = useState<string>('');
  const [userImage, setUserImage] = useState<string>('');
  const setUserDetails = (name: string, plan: string, image: string) => {
    setUserName(name);
    setUserPlan(plan);
    setUserImage(image);
  };

  return (
    <SearchContext.Provider value={{ name, setName, userName,
      userPlan,
      userImage,
      setUserDetails, }}>
      {children}
    </SearchContext.Provider>
  );
};
