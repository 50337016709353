import React, { useEffect, useContext, useState } from 'react';
import axios from 'axios';
// import { useState, CSSProperties } from "react";
import BeatLoader from 'react-spinners/ClipLoader';

import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import AsideBar from '../customLayout/AsideBar';
import CustomHeader from '../customLayout/Header';
import ThemeContext from '../../contexts/themeContext';
import DashboardHeader from '../_layout/_headers/DashboardHeader';
import Card, { CardBody } from '../../components/bootstrap/Card';
import Button from '../../components/bootstrap/Button';
import Icon from '../../components/icon/Icon';
import { ShimmerContentBlock } from 'react-shimmer-effects';
import PastTransactions from './pastTransactions';
import { Link } from 'react-router-dom';

const Subscription = () => {
	const navigate = useNavigate();
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);
	const [reload, setReload] = useState(false);

	let baseUrl = '';
	switch (process.env.REACT_APP_STATUS) {
		case 'DEV':
			baseUrl = process.env.REACT_APP_DEV_URL;
			break;
		case 'STAGE':
			baseUrl = process.env.REACT_APP_STAGE_URL;
			break;
		case 'PROD':
			baseUrl = process.env.REACT_APP_PROD_URL;
			break;
	}

	useEffect(() => {
		const getTokenDataAndNavigate = async () => {
			try {
				const tokenFromLocalStorage = localStorage.getItem('token');
				const result = await axios.post(`${baseUrl}/api/v1/user/verifyToken`, {
					token: tokenFromLocalStorage,
				});
				if (result.data === 'invalid') {
					navigate('/login');
				}
			} catch {
				navigate('/login');
			}
		};
		// getTokenDataAndNavigate();
	}, []);

	const [isProfileCompleted, setIsProfileCompleted] = useState('');

	useEffect(() => {
		const getAllProfiles = async () => {
			try {
				const headers = {
					authorization: localStorage.getItem('token'),
				};
				const responseFromApi = await axios.get(
					`${baseUrl}/api/v1/profile/getProfileDetails`,
					{
						headers,
					},
				);
				const { parents } = responseFromApi.data.result;
				if (parents != undefined) {
					setIsProfileCompleted('true');
				} else {
					setIsProfileCompleted('false');
				}
			} catch (e) {
				console.log(e);
			}
		};
		getAllProfiles();
	}, []);

	const [packages, setPackages] = useState([]);
	const [boosterPacks, setBoosterPacks] = useState([]);

	useEffect(() => {
		const getPackages = async () => {
			try {
				const result = await axios.get(`${baseUrl}/api/v1/packages/getAllPackages`);
				setPackages(result.data);
			} catch (error) {
				console.log(error);
			}
		};
		const getBoosterPacks = async () => {
			try {
				const result = await axios.get(`${baseUrl}/api/v1/packages/getAllBoosterPacks`);

				setBoosterPacks(result.data);
			} catch (error) {
				console.log(error);
			}
		};
		getPackages();
		getBoosterPacks();
	}, []);

	const [userSubDetails, setUserSubDetails] = useState([]);
	const [activePackData, setActivePackData] = useState({});

	useEffect(() => {
		const getUserSubscriptionDetails = async () => {
			try {
				const headers = {
					authorization: localStorage.getItem('token'),
				};
				const resultApi = await axios.get(
					`${baseUrl}/api/v1/userSubscription/getSubscriptionDetails`,
					{ headers },
				);

				for (let i = 0; i < resultApi.data.result.length; i++) {
					if (resultApi.data.result[i]._id === resultApi.data.ActiveSubscriptionPack) {
						setActivePackData(resultApi.data.result[i]);
					}
				}
				setUserSubDetails(resultApi.data);
			} catch (e) {
				console.log(e);
			}
		};
		getUserSubscriptionDetails();
	}, [reload]);

	const handleBuyPack = async (packId, type) => {
		try {
			const headers = {
				authorization: localStorage.getItem('token'),
			};
			const bodyParams = {
				instrumentId: packId,
				instrumentType: type,
				selectedPG: 'INSTAMOJO',
			};
			const result = await axios.post(
				`${baseUrl}/api/v1/payment/createPaymentLink`,
				bodyParams,
				{
					headers,
				},
			);
			window.location.href = result.data.payment_url;
			//    alert("hey pack purchased")
			//    toast.success('Subscription Purchased Successfully!', {
			// 	position: "top-right",
			// 	autoClose: 2000,
			// 	hideProgressBar: false,
			// 	closeOnClick: true,
			// 	pauseOnHover: true,
			// 	draggable: true,
			// 	progress: undefined,
			// 	theme: "dark",
			// 	});
			// 	setReload(prevReload=>!prevReload)
			//    window.location.reload();
		} catch (e) {
			console.log(e);
		}
	};

	const handleBoosterPack = async (boosterId) => {
		if (userSubDetails.ActiveSubscriptionPack === 'NO ACTIVE SUBSCRIPTIONS') {
			alert('please purchase the main subscrption');
		} else {
			try {
				const headers = {
					authorization: localStorage.getItem('token'),
				};
				const bodyParams = {
					boosterId,
					paymentId: '64c76339421a8dd7084c4c91',
				};
				const result = await axios.post(
					`${baseUrl}/api/v1/userSubscription/createBoosterPack`,
					bodyParams,
					{
						headers,
					},
				);
				// alert("hey booster pack purchased")
				toast.success('Booster Pack Purchased Successfully!', {
					position: 'top-right',
					autoClose: 2000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'dark',
				});
				setReload((prevReload) => !prevReload);
				// window.location.reload();
			} catch (e) {
				console.log(e);
			}
		}
	};
	const scrollToTarget = (targetId) => {
		const targetElement = document.getElementById(targetId);

		if (targetElement) {
			targetElement.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			});
		}
	};

	let differenceDays = 0;

	if (userSubDetails.startDate != '') {
		const endDate = userSubDetails.endDate;
		const oneDay = 24 * 60 * 60 * 1000;
		differenceDays = Math.round((new Date(endDate) - new Date()) / oneDay);
	}

	const weddingLinks = [
		'https://i.pinimg.com/originals/2b/f0/fd/2bf0fd272eb74bbeba011775c43b9853.jpg',
		'https://img.freepik.com/premium-vector/indian-wedding-couple-together-standing-white-background-vector-design-wedding-invitation_717949-96.jpg',
		'https://media.istockphoto.com/id/1322436248/vector/beautiful-indian-couple-bride-and-groom-in-traditional-wedding-sari-dress-eps10-vec.jpg?s=612x612&w=0&k=20&c=qRQcWMU_UQQc_ynX1lpBlJDDCEkWqawMPK7nDvZewNw=',
		'https://media.istockphoto.com/id/1272596515/vector/faceless-indian-wedding-couple-together-standing-on-white-background.jpg?s=612x612&w=0&k=20&c=17puEKpubvE0Zzm9Rzj6OEElVlhqU3RKrehTAB7iYe0=',
	];
	const boosterLinks = [
		'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQpxc4uPr4iqtA0XJsXeNdeXVGspQnf5_5vqg&usqp=CAU',
		'https://media.istockphoto.com/id/1285822013/vector/hindu-wedding-invitation-card.jpg?s=612x612&w=0&k=20&c=arGRsUT0WfiZ7i3Q5SGC6BIDRCtEUp_0MYB687XpZi8=',
		'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLx9KoJnvaSfX5eqtNr0KMu08X1xrpk_rdvQ&usqp=CAU',
		'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQRCWoFA62deLaQ81wRIJQbhNMgVCJkL7ynQ&usqp=CAU',
	];

	if (isProfileCompleted == 'true') {
		return (
			<div className='w-[100%]'>
				{/* <AsideBar /> */}

				{/* <div className={asideStatus ? `md:ml-[15rem] ` : `md:ml-[5rem] mt-[5rem] md:mt-0`}> */}
				{/* <DashboardHeader /> */}
				<div
					className={isProfileCompleted ? 'mx-2 md:mx-0 md:ml-[2rem] md:mt-5' : 'hidden'}>
					<div>
						<h1 className='ml-7'>Subscriptions</h1>
						<div className='md:my-5 flex justify-center'>
							<div className='w-full md:w-[70%]'>
								<Card>
									<div className='flex flex-col md:flex-row gap-4'>
										<div
											className='w-full h-[200px] md:h-[400px] md:w-[45%] rounded'
											style={{
												display: 'flex',
												flexDirection: 'column',
												justifyContent: 'center',
												alignItems: 'center',
												textAlign: 'center',
												padding: '1rem',
												backgroundColor: '#ffae00b5',
											}}>
											<Icon
												icon='Subscriptions'
												size={'10x'}
												style={{ color: '#700000' }}
											/>
											<h4
												style={{
													color: 'black',
													fontSize: 'bold',
													marginTop: '2rem',
													fontWeight: '800',
												}}>
												{userSubDetails.packageName}
											</h4>
										</div>
										<div className='flex flex-col gap-4 justify-center p-5'>
											<h2>
												Name:{' '}
												<span className='font-normal text-3xl'>
													{userSubDetails.name
														? userSubDetails.name
														: 'USER'}
												</span>
											</h2>
											<h2>
												Credits Left:{' '}
												<span className='font-normal text-3xl'>
													{activePackData.creditsLeft > 0
														? activePackData.creditsLeft
														: '0'}
												</span>
											</h2>
											<h2>
												Booster Credits Left:{' '}
												<span className='font-normal text-3xl'>
													{userSubDetails.TotalActiveBoosterPacksCredits >
													0
														? userSubDetails.TotalActiveBoosterPacksCredits
														: '0'}
												</span>
											</h2>
											<h2>
												Validity:{' '}
												<span className='font-normal text-xl'>
													{userSubDetails.startDate == ''
														? 'NO ACTIVE PACKS'
														: `${differenceDays} Days Left`}
												</span>
											</h2>
											<Button
												color='warning'
												onClick={() => scrollToTarget('targetSection')}>
												Buy Now
											</Button>

											<p>
												To view past transactions{' '}
												<Link to={'/pastTransactions'}>Click Here</Link>
											</p>
										</div>
									</div>
								</Card>
							</div>
						</div>
					</div>
					<h1 className='ml-[2rem]' id='targetSection'>
						Our Subscriptions
					</h1>
					<div className='mx-[2rem] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 py-5 px-3'>
						{packages.map((data, index) => (
							<div>
								<Card shadow={'3d'} borderSize={3} borderColor={'danger'}>
									<CardBody>
										<div className='py-4 justify-center mx-2 items-center gap-3'>
											{/* <Icon icon='home' size={'5x'} /> */}
											<div
												style={{
													width: '100%',
													height: '35vh',
													padding: '1rem',
													display: 'flex',
													justifyContent: 'center',
												}}>
												<img
													src={weddingLinks[index]}
													alt='marriage'
													style={{
														height: '90%',
														padding: '0.5rem',
														width: '80%',
													}}
												/>
											</div>
											<h2>{data.name}</h2>
										</div>
										<div>
											<h1 className='text-6xl font-bold mx-2'>
												₹ {data.amount}
											</h1>
											<p className='py-2'>{data.description}</p>
										</div>
										<div>
											<h3 className='font-bold text-center'>
												{data.credits} Credits For {data.validity} Days
											</h3>
										</div>
										<div className='py-3'>
											{data.benefits.map((items) => (
												<div className='flex gap-3 items-start'>
													<Icon
														icon='check'
														size={'2x'}
														color='success'
													/>
													<p className='flex gap-3 items-center font-semibold'>
														{items}
													</p>
												</div>
											))}
										</div>
										<div className='w-[100%]'>
											<Button
												className='w-100'
												size={'lg'}
												disabled={
													data.name === userSubDetails.packageName
														? true
														: false
												}
												style={{
													backgroundColor: '#ffae00b5',
													color: 'black',
													display: 'flex',
													justifyContent: 'center',
												}}
												onClick={() => {
													handleBuyPack(data._id, 'PACKAGE');
												}}>
												{/* Buy Now */}
												{data.name === userSubDetails.packageName
													? 'Subscription is Active'
													: 'Buy Now'}
											</Button>
										</div>
									</CardBody>
								</Card>
							</div>
						))}
					</div>

					<h1 className='ml-[2rem]'>Our Booster Packs</h1>
					<div className='mx-[2rem] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 py-5'>
						{boosterPacks.map((data, index) => {
							return (
								<div>
									<Card shadow={'3d'} borderSize={3} borderColor={'danger'}>
										<CardBody>
											<div className='py-4  justify-center mx-2 items-center gap-3'>
												{/* <Icon icon='home' size={'5x'} /> */}
												<div
													style={{
														width: '100%',
														height: '25vh',
														padding: '1rem',
														display: 'flex',
														justifyContent: 'center',
													}}>
													<img
														src={boosterLinks[index]}
														alt='marriage'
														style={{
															height: '90%',
															width: '80%',
															padding: '0.5rem',
														}}
													/>
												</div>

												<h3>{data.name}</h3>
											</div>
											<div>
												<h2 className='text-6xl font-bold mx-2'>
													₹ {data.amount}
												</h2>
												<p className='py-2'>{data.description}</p>
											</div>
											<div className='py-2'>
												<h3 className='font-bold text-center'>
													{data.credits} Credits
												</h3>
											</div>
											<div className='w-[100%]'>
												<Button
													className='w-100'
													size={'lg'}
													style={{
														backgroundColor: '#ffae00b5',
														color: 'black',
													}}
													onClick={() => {
														handleBuyPack(data._id, 'BOOSTERPACK');
													}}>
													Buy Now
												</Button>
											</div>
										</CardBody>
									</Card>
								</div>
							);
						})}
					</div>
				</div>
			</div>
			//  </div>
		);
	} else if (isProfileCompleted == 'false') {
		return (
			<div className='w-[100%]'>
				{/* <AsideBar /> */}
				{/* <div className={asideStatus ? `md:ml-[15rem] ` : `md:ml-[5rem] mt-[5rem] md:mt-0`}> */}
				{/* <DashboardHeader /> */}
				<div className='flex justify-center items-center min-h-[80vh]'>
					<div className='w-[75%]'>
						<Card>
							<CardBody>
								<div className='flex flex-col items-center justify-center gap-3'>
									<div>
										<img
											src={
												'https://facit-modern.omtanke.studio/static/media/wanna7.c92d88f942e3d84190de.webp'
											}
											alt='USer Image'
											className='w-36 h-36 border-black border-1 rounded-full bg-purple-100'
										/>
									</div>
									<div className='flex flex-col items-center gap-3 '>
										<div className='flex items-center gap-3 '>
											<h3 className='text-center'>
												Find Your Perfect Match: Complete Your Profile Now!{' '}
											</h3>
											<span className='-mt-2'>
												<Icon
													color='warning'
													icon='AccountBox'
													size={'3x'}
												/>
											</span>
										</div>
										<div>
											<p className='text-center'>
												Please complete your profile to continue. By
												providing us with comprehensive details about
												yourself, your interests, and your preferences, you
												enhance your chances of discovering the perfect
												match tailored to your unique personality and
												aspirations.
											</p>
										</div>
										<div className='w-[100%] flex justify-center'>
											<Button
												className='w-[75%]'
												color='warning'
												onClick={() => {
													navigate('/');
												}}>
												Complete Your Profile Now
											</Button>
										</div>
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
				</div>
			</div>
			//  </div>
		);
	} else if (isProfileCompleted == '') {
		return (
			<div className='w-[100%]'>
				{/* <AsideBar /> */}
				{/* <div className={asideStatus ? `md:ml-[15rem] ` : `md:ml-[5rem] mt-[5rem] md:mt-0`}> */}
				{/* <DashboardHeader /> */}
				<div className='flex justify-center items-center min-h-[80vh]'>
					<div className='w-[75%]'>
						<div>
							<ShimmerContentBlock
								title
								text
								cta
								thumbnailWidth={370}
								thumbnailHeight={370}
							/>
							<ShimmerContentBlock
								title
								text
								cta
								thumbnailWidth={370}
								thumbnailHeight={370}
							/>
							<ShimmerContentBlock
								title
								text
								cta
								thumbnailWidth={370}
								thumbnailHeight={370}
							/>
							<ShimmerContentBlock
								title
								text
								cta
								thumbnailWidth={370}
								thumbnailHeight={370}
							/>
						</div>
					</div>
				</div>
			</div>
			//  </div>
		);
	}
};

export default Subscription;
