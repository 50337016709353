import React, {useState, useEffect } from 'react';
import axios from 'axios';


import Card, { CardBody } from '../../components/bootstrap/Card';
import Button from '../../components/bootstrap/Button';
import Icon from '../../components/icon/Icon';
import DashboardHeader from '../_layout/_headers/DashboardHeader';
import { useNavigate } from 'react-router-dom';
import BeatLoader from "react-spinners/ClipLoader";
import './paymentStyles.css';

const PaymentConfirmation = ()=>{
    // const history = useHistory();
    const navigate = useNavigate();
    const [timer, setTimer] = useState(null);
  
    useEffect(() => {
      const delay = 5000;
      const timerId = setTimeout(() => {
        navigate("/")
      }, delay);
      setTimer(timerId);
      
      
      return () => {
        clearTimeout(timer);
        setTimer(null);
      };
    }, []);
  
    // const cancelTimerAndRedirect = () => {
    //   if (timer!== null) {
    //     console.log("alert entering the click")
    //     clearTimeout(timer); 
    //   }
    //   navigate('/'); 
    // };
  

 let baseUrl = '';
 switch (process.env.REACT_APP_STATUS) {
     case 'DEV':
         baseUrl = process.env.REACT_APP_DEV_URL;
         break;
     case 'STAGE':
         baseUrl = process.env.REACT_APP_STAGE_URL;
         break;
     case 'PROD':
         baseUrl = process.env.REACT_APP_PROD_URL;
         break;
 }

const urlString = window.location.href
const urlSearchParams = new URLSearchParams(urlString);

const paymentRequestId = urlSearchParams.get('payment_request_id');


	return(
        <div className="col-6 main-div"> 
      <div className="subscription-card">
        <div className="card-body">
        <div className='image-container'>
          <img src="https://cdn-icons-png.flaticon.com/512/148/148767.png" alt="success" className='image-styling' />
          </div>
          <h5 className="card-title">Stay Connected!</h5>
          {/* <h5>PAYMENT ID : {paymentId}</h5> */}
          <h5>REFERENCE ID :<span style={{color:"#700000",fontWeight:"bold"}}>  {paymentRequestId} </span></h5>
          <p className="card-text">
            We'll keep you informed about your subscription details through email.
          </p>
          {/* <button type='button' onClick={()=>{cancelTimerAndRedirect}} className='main-button'>Go To Home Page</button> */}

        </div>
      </div>
    </div>

	)
}

export default PaymentConfirmation