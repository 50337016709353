import React, { FC, useCallback, useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormik } from 'formik';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import AuthContext from '../../../contexts/authContext';
import USERS, { getUserDataWithUsername } from '../../../common/data/userDummyData';
import Spinner from '../../../components/bootstrap/Spinner';
import Alert from '../../../components/bootstrap/Alert';
import signUp from '../../../assets/custom/images/signup.jpeg';
import signIn from '../../../assets/custom/images/signin.jpeg';
import Select from '../../../components/bootstrap/forms/Select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useLocation } from 'react-router-dom';

import axios from 'axios';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../components/bootstrap/Modal';

let baseUrl = '';
switch (process.env.REACT_APP_STATUS) {
	case 'DEV':
		baseUrl = process.env.REACT_APP_DEV_URL;
		break;
	case 'STAGE':
		baseUrl = process.env.REACT_APP_STAGE_URL;
		break;
	case 'PROD':
		baseUrl = process.env.REACT_APP_PROD_URL;
		break;
}

const LoginHeader = ({ isNewUser }) => {
	if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>Create an Account,</div>
				<div className='text-center h4 text-muted mb-5'>Sign up to get started!</div>
			</>
		);
	}
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>Welcome Back,</div>
			<div className='text-center h4 text-muted mb-5'>Sign in to continue!</div>
		</>
	);
};
LoginHeader.defaultProps = {
	isNewUser: false,
};

const Login = ({ isSignUp }) => {
	const regex = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}');

	const { setUser } = useContext(AuthContext);

	const { darkModeStatus } = useDarkMode();

	const [openForgotPasswordModal, setOpenForgotPasswordModal] = useState(false);
	const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');

	const [signInPassword, setSignInPassword] = useState(false);
	const [singUpStatus, setSingUpStatus] = useState(!isSignUp);

	const loginButtonStyle = { backgroundColor: darkModeStatus ? '#ff9900' : '#700000' };
	const signupButtonStyle = { backgroundColor: darkModeStatus ? '#00ff99' : '#700000' };

	const navigate = useNavigate();
	const handleOnClick = useCallback(() => navigate('/'), [navigate]);

	const usernameCheck = (username) => {
		return !!getUserDataWithUsername(username);
	};

	const passwordCheck = (username, password) => {
		return getUserDataWithUsername(username).password === password;
	};

	const [signUpValues, setSignUpValues] = useState({
		email: '',
		phone: '',
		password: '',
		usedBy: 'SELF',
		countryCode: '+91',
		isVerified: true,
	});

	const location = useLocation();

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);

		const profile = queryParams.get('profile');
		const name = queryParams.get('name');
		const email = queryParams.get('email');

		if (profile && name && email) {
			setSignUpValues({ ...signUpValues, email: email, usedBy: profile });
		}
	}, []);

	const [signInValues, setSignInValues] = useState({
		email: '',
		password: '',
	});

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			loginUsername: '',
			loginPassword: '',
		},
		validate: (values) => {
			const errors = {};

			if (!values.loginUsername) {
				errors.loginUsername = 'Required';
			}

			if (!values.loginPassword) {
				errors.loginPassword = 'Required';
			}

			return errors;
		},
		validateOnChange: false,
		onSubmit: (values) => {
			if (usernameCheck(values.loginUsername)) {
				if (passwordCheck(values.loginUsername, values.loginPassword)) {
					if (setUser) {
						setUser(values.loginUsername);
					}

					handleOnClick();
				} else {
					formik.setFieldError('loginPassword', 'Username and password do not match.');
				}
			}
		},
	});

	const [isLoading, setIsLoading] = useState(false);
	const handleContinue = () => {
		setIsLoading(true);
		setTimeout(() => {
			if (
				!Object.keys(USERS).find(
					(f) => USERS[f].username.toString() === formik.values.loginUsername,
				)
			) {
				formik.setFieldError('loginUsername', 'No such user found in the system.');
			} else {
				setSignInPassword(true);
			}
			setIsLoading(false);
		}, 1000);
	};

	async function handleSignUp(e) {
		try {
			e.preventDefault();
			const result = await axios.post(`${baseUrl}/api/v1/user/userSignUp`, signUpValues);
			console.log(result.data.token);
			localStorage.setItem('token', result.data.token);
			navigate('/');
		} catch (error) {
			console.log(error.response.data.message);
			toast.warn(error.response.data.message, {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'colored',
			});
			// alert(error.response.data.message);
		}
	}

	async function handleLogin(e) {
		try {
			e.preventDefault();
			const result = await axios.post(`${baseUrl}/api/v1/user/userLogIn`, signInValues);
			console.log(result);
			localStorage.setItem('token', result.data.token);
			navigate('/');
		} catch (error) {
			console.log(error.response);
			// alert(error.response.data.msg);
			toast.warn(error.response.data.msg, {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'colored',
			});
		}
	}
	const [loginClicked, setLoginClicked] = useState(false);
	const [signupClicked, setSignupClicked] = useState(true);

	async function sendForgotPasswordEmail() {
		try {
			const result = await axios.post(`${baseUrl}/api/v1/user/forgotPassword`, {
				email: forgotPasswordEmail,
			});
			console.log(result);
			toast.warn('Reset Email Sent Successfully !!', {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'colored',
			});
			setOpenForgotPasswordModal(false);
		} catch (error) {
			console.log(error);
			if (error.response.data == "User with given email does't exists") {
				toast.warn(error.response.data, {
					position: 'top-right',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'colored',
				});
			} else {
				toast.warn(error.response.statusText, {
					position: 'top-right',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'colored',
				});
			}
			setOpenForgotPasswordModal(false);
		}
	}

	return (
		<PageWrapper isProtected={false}>
			<Page className='p-0 w-lg-75 shadow-md'>
				<div className='row h-100 align-items-center justify-content-center mt-4'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container w-100'>
						<Card className='shadow-3d-dark w-100' data-tour='login-page'>
							<div className='d-flex gap-0'>
								<div className='w-50 d-none m-5 d-lg-flex justify-content-center align-items-center '>
									<img
										src={singUpStatus ? signUp : signIn}
										className='w-100 rounded shadow-lg'
										alt='Side Image'
										style={{
											maxWidth: '78%', // Set the maximum width of the image
											height: 'auto', // Maintain aspect ratio
										}}
									/>
								</div>
								<CardBody className='w-50 p-5 d-flex flex-column justify-content-center'>
									<div
										className={classNames('rounded-3', {
											'bg-l10-dark': !darkModeStatus,
											'bg-dark': darkModeStatus,
										})}>
										<div className='row row-cols-2 g-3 pb-3 px-3 m-0'>
											<div className='col'>
												<Button
													// isActive={signupClicked}
													// color={signupClicked ? 'warning' : null}
													style={
														signupClicked
															? {
																	backgroundColor: '#700000',
																	color: 'white',
															  }
															: { backgroundColor: 'transparent' }
													}
													isLight={!singUpStatus}
													className='rounded-1 w-100'
													size='lg'
													onClick={() => {
														setSignInPassword(false);
														setSingUpStatus(!singUpStatus);
														setLoginClicked(false);
														setSignupClicked(true);
													}}>
													Sign Up
												</Button>
											</div>
											<div className='col'>
												<Button
													// style={singUpStatus ? signupButtonStyle : loginButtonStyle}

													isLight={singUpStatus}
													className='rounded-1 w-100'
													size='lg'
													// isActive={loginClicked}

													color={loginClicked ? 'warning' : null}
													onClick={() => {
														setSignInPassword(false);
														setSingUpStatus(!singUpStatus);
														setLoginClicked(true);
														setSignupClicked(false);
													}}>
													Login
												</Button>
											</div>
										</div>
									</div>

									<LoginHeader isNewUser={singUpStatus} />

									<form className='row g-4'>
										{singUpStatus ? (
											<>
												<div className='col-12'>
													<FormGroup
														id='signup-email'
														isFloating
														label='Email'
														required>
														<Input
															value={signUpValues.email}
															onChange={(e) => {
																const newValue =
																	e.target.value.replace(
																		/\s/g,
																		'',
																	);
																setSignUpValues({
																	...signUpValues,
																	email: newValue,
																});
															}}
															type='email'
															autoComplete='email'
															required={true}
														/>
													</FormGroup>
												</div>
												<div className='col-12'>
													<FormGroup
														id='signup-password'
														isFloating
														label='Password'>
														<Input
															type='password'
															value={signUpValues.password}
															onChange={(e) => {
																const newValue =
																	e.target.value.replace(
																		/\s/g,
																		'',
																	);
																setSignUpValues({
																	...signUpValues,
																	password: newValue,
																});
															}}
															autoComplete='given-name'
														/>
													</FormGroup>
												</div>

												<div className='col-12'>
													<FormGroup
														id='signup-password'
														// isFloating
														label='Matrimony Profile For'>
														<Select
															defaultValue={[
																{ value: 'SELF', text: 'Self' },
															]}
															value={signUpValues.usedBy}
															size='lg'
															onChange={(e) =>
																setSignUpValues({
																	...signUpValues,
																	usedBy: e.target.value,
																})
															}
															list={[
																{ value: 'SELF', text: 'Self' },
																{
																	value: 'PARENT_SON',
																	text: 'Son',
																},
																{
																	value: 'PARENT_DAUGHTER',
																	text: 'Daughter',
																},
																{
																	value: 'SIBLING',
																	text: 'Sibling',
																},
																{ value: 'FRIEND', text: 'Friend' },
																{
																	value: 'RELATIVE',
																	text: 'Relative',
																},
															]}
														/>
													</FormGroup>
												</div>
												<div className='col-12'>
													<Button
														//color='dark'
														style={{
															backgroundColor: '#700000',
															color: 'white',
															border: 'none',
														}}
														type='submit'
														className='w-100 py-3'
														onClick={handleSignUp}
														isDisable={
															signUpValues.email === '' ||
															signUpValues.password === '' ||
															signUpValues.usedBy === '' ||
															!regex.test(signUpValues.email)
														}>
														Sign Up
													</Button>
												</div>
											</>
										) : (
											<>
												<div className='col-12'>
													<div className='col-12'>
														<FormGroup
															id='signup-email'
															isFloating
															label='Email'
															required>
															<Input
																value={signInValues.email}
																onChange={(e) => {
																	const newValue =
																		e.target.value.replace(
																			/\s/g,
																			'',
																		);
																	setSignInValues({
																		...signInValues,
																		email: newValue,
																	});
																}}
																type='email'
																autoComplete='email'
																required={true}
															/>
														</FormGroup>
													</div>

													<div className='col-12 mt-4 mb-2'>
														<FormGroup
															id='signup-password'
															isFloating
															label='Password'>
															<Input
																type='password'
																value={signInValues.password}
																onChange={(e) => {
																	const newValue =
																		e.target.value.replace(
																			/\s/g,
																			'',
																		);
																	setSignInValues({
																		...signInValues,
																		password: newValue,
																	});
																}}
																autoComplete='given-name'
															/>
														</FormGroup>
													</div>
												</div>
												<p
													className='text-dark fw-bold cursor-pointer'
													onClick={() =>
														setOpenForgotPasswordModal(true)
													}>
													Forgot Your Password?
												</p>
												<div className='col-12'>
													<Button
														//color='dark'
														style={{
															backgroundColor: '#ffae00b5',
															color: '#000000',
															border: 'none',
														}}
														className='w-100 py-3'
														onClick={handleLogin}
														isDisable={
															signInValues.email === '' ||
															signInValues.password === ''
														}>
														Login
													</Button>
												</div>
											</>
										)}
									</form>
								</CardBody>
							</div>
						</Card>
					</div>
				</div>
			</Page>

			<Modal
				isOpen={openForgotPasswordModal}
				setIsOpen={setOpenForgotPasswordModal}
				isAnimation={true}
				size={'lg'}>
				<ModalHeader setIsOpen={setOpenForgotPasswordModal} className='p-3'>
					<ModalTitle>
						<h3>Forgot Password ? </h3>
					</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<div className='col-12'>
						<FormGroup
							id='signup-email'
							isFloating
							label='Enter Your Registered Email'
							required>
							<Input
								value={forgotPasswordEmail}
								onChange={(e) => {
									const newValue = e.target.value.replace(/\s/g, '');
									setForgotPasswordEmail(newValue);
								}}
								type='email'
								autoComplete='email'
								required={true}
							/>
						</FormGroup>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button
						// color={darkModeStatus ? 'light' : 'dark'}
						style={{ backgroundColor: '#700000', color: 'white' }}
						className='rounded-1 w-100'
						isDisable={!forgotPasswordEmail}
						onClick={sendForgotPasswordEmail}
						size='lg'>
						Send Reset Email
					</Button>
				</ModalFooter>
			</Modal>
		</PageWrapper>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
