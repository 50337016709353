import React, { lazy, useState, useEffect } from 'react';
import { dashboardPagesMenu, demoPagesMenu, pageLayoutTypesPagesMenu } from '../menu';
import Login from '../pages/presentation/auth/Login';
import LoginPage from '../pages/authentication/LoginPage';
import Dashboard from '../pages/dashboard/Dashboard';
import SearchProfiles from '../pages/searchProfiles/SearchProfiles';
import ViewProfiles from '../pages/viewProfile/viewProfiles';
import MyMatches from '../pages/myMatches/myMatches';
import MyRequests from '../pages/myRequests/myRequests';
import Subscription from '../pages/subscriptions/subscriptions';
import Wishlist from '../pages/wishlist/wishlist';
import MyProfile from '../pages/myProfile/myProfile';
import CreateProfile from '../pages/createProfile/createProfile';
import PaymentConfirmation from "../pages/payment-confirmation/payment-confirmation";
import Modal, { ModalBody, ModalHeader, ModalTitle, ModalFooter } from '../components/bootstrap/Modal';
import Button from '../components/bootstrap/Button';
import PastTransactions from '../pages/subscriptions/pastTransactions';

// import { Modal, Button } from 'react-bootstrap';

const Logout = () => {
	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		setShowModal(true); // Show the modal automatically when the component mounts
	}, []);

	const handleCloseModal = () => {
		setShowModal(false);
		window.location.href = "/";
	};

	const out = () => {
		localStorage.removeItem("token");
		window.location.href = "/login";
	};

	return (
		<Modal isOpen={showModal} setIsOpen={handleCloseModal} isCentered={true}>
			<ModalHeader>
				<ModalTitle id='modalTitle'>Confirmation</ModalTitle>
			</ModalHeader>
			<ModalBody>Are you sure you want to logout?</ModalBody>
			<ModalFooter>
				<Button color="secondary" onClick={handleCloseModal} style={{ backgroundColor: "#ffae00b5", color: "black", border: "none" }}>
					Cancel
				</Button>
				<Button color="primary" onClick={out} style={{ backgroundColor: "#700000", color: "white", border: "none" }} >
					Logout
				</Button>
			</ModalFooter>
		</Modal>
	);
};

{/* <Modal isOpen={filterModalOpen} setIsOpen={setFilterModalOpen}>
<ModalHeader> backdrop="static"
	<ModalTitle className='w-100 pt-2 d-sm-none d-md-block '>
		<div className='flex justify-between items-center w-[100%]'>
			<h3>Apply Filters</h3>
			<div onClick={() => setFilterModalOpen(!filterModalOpen)}>
				<Icon icon='close' color='dark' size={'2x'}></Icon>
			</div>
		</div>
	</ModalTitle>
</ModalHeader>

</Modal> */}
// const LANDING = {
// 	DASHBOARD: lazy(() => import('../pages/presentation/auth/Login')),
// };
// const AUTH = {
// 	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
// };
// const PAGE_LAYOUTS = {
// 	HEADER_SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/HeaderAndSubheader')),
// 	HEADER: lazy(() => import('../pages/presentation/page-layouts/OnlyHeader')),
// 	SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/OnlySubheader')),
// 	CONTENT: lazy(() => import('../pages/presentation/page-layouts/OnlyContent')),
// 	BLANK: lazy(() => import('../pages/presentation/page-layouts/Blank')),
// 	ASIDE: lazy(() => import('../pages/presentation/aside-types/DefaultAsidePage')),
// 	MINIMIZE_ASIDE: lazy(() => import('../pages/presentation/aside-types/MinimizeAsidePage')),
// };

const presentation = [
	/**
	 * Landing
	 */
	// {
	// 	path: dashboardPagesMenu.dashboard.path,
	// 	element: <LANDING.DASHBOARD />,
	// },
	// {
	// 	path: demoPagesMenu.page404.path,
	// 	element: <AUTH.PAGE_404 />,
	// },
	// {
	// 	path: demoPagesMenu.login.path,
	// 	element: <Login />,
	// },
	// {
	// 	path: demoPagesMenu.signUp.path,
	// 	element: <Login isSignUp />,
	// },

	/** ************************************************** */

	/**
	 * Page Layout Types
	 */
	// {
	// 	path: pageLayoutTypesPagesMenu.blank.path,
	// 	element: <PAGE_LAYOUTS.BLANK />,
	// },
	// {
	// 	path: pageLayoutTypesPagesMenu.pageLayout.subMenu.headerAndSubheader.path,
	// 	element: <PAGE_LAYOUTS.HEADER_SUBHEADER />,
	// },
	// {
	// 	path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyHeader.path,
	// 	element: <PAGE_LAYOUTS.HEADER />,
	// },
	// {
	// 	path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlySubheader.path,
	// 	element: <PAGE_LAYOUTS.SUBHEADER />,
	// },
	// {
	// 	path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyContent.path,
	// 	element: <PAGE_LAYOUTS.CONTENT />,
	// },
	// {
	// 	path: pageLayoutTypesPagesMenu.asideTypes.subMenu.defaultAside.path,
	// 	element: <PAGE_LAYOUTS.ASIDE />,
	// },
	// {
	// 	path: pageLayoutTypesPagesMenu.asideTypes.subMenu.minimizeAside.path,
	// 	element: <PAGE_LAYOUTS.MINIMIZE_ASIDE />,
	// },
	// {
	// 	path: "/",
	// 	element: <div>Hello</div>
	// },
	{
		path: "/login",
		element: <LoginPage />
	},
	{
		path: "/",
		element: <Dashboard />
	},
	{
		path: "/searchProfiles",
		element: <SearchProfiles />
	},
	{
		path: "/viewProfile",
		element: <ViewProfiles />
	},
	{
		path: "/myMatches",
		element: <MyMatches />
	},
	{
		path: "/myRequests",
		element: <MyRequests />
	},
	{
		path: "/subscriptions",
		element: <Subscription />
	},
	{
		path: "/wishlist",
		element: <Wishlist />
	},
	{
		path: "/myProfile",
		element: <MyProfile />
	},
	{
		path: "/createProfile",
		element: <CreateProfile />
	},
	{
		path: "/logout",
		element: <Logout />
	},
	{
		path: "/payment-confirmation",
		element: <PaymentConfirmation />
	},
	{
		path: "/pastTransactions",
		element: <PastTransactions />
	}
];
const contents = [...presentation];

export default contents;
