import React, { useEffect, useContext, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AsideBar from '../customLayout/AsideBar';
import CustomHeader from '../customLayout/Header';
import ThemeContext from '../../contexts/themeContext';
import DashboardHeader from '../_layout/_headers/DashboardHeader';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../components/bootstrap/Card';
import Button from '../../components/bootstrap/Button';
import { Favorite, FavoriteBorder } from '../../components/icon/material-icons';
import Icon from '../../components/icon/Icon';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ShimmerContentBlock } from 'react-shimmer-effects';

const ViewProfiles = () => {
	const navigate = useNavigate();
	const AUTHID = new URLSearchParams(window.location.search).get('id');
	console.log(AUTHID, 'THIS IS AUTHID WE ARE GETTING FROM URL');
	const [reload, setReload] = useState(false);

	const { asideStatus, setAsideStatus } = useContext(ThemeContext);
	const staticData = {
		id: '#01',
		Phone: '+91 6366256689',
		Age: 21,
		Profession: 'Software Developer',
		Education: 'B.Tech',
		Gender: 'Male',
	};

	const [basicDetails, setBasicDetails] = useState({
		// id: '1xxxxxx',
		Name: 'Nxxxxx',
		Phone: '9xxxxxx',
		Gender: 'xxxxxx',
		DOB: '01-xx-xxxx',
		Height: 'xx',
		Weight: 'xx',
		MotherToungue: 'xxxxx',
		IncomeRange: 'xxxx-xxxx',
	});

	const [showShimmer, setShowShimmer] = useState(true);
	const [personalDetails, setPersonalDetails] = useState({
		Parents: {
			Father: {
				name: 'Srinivas',
				age: 50,
				occupation: 'Teacher',
				isAlive: true,
			},
			Mother: {
				name: 'Kavitha',
				age: 45,
				occupation: 'House Wife',
				isAlive: true,
			},
		},
		Siblings: {
			Sister: {
				name: 'Kusuma',
				gender: 'Female',
				age: 24,
				occupation: 'Teacher',
				isAlive: true,
				isMarried: true,
			},
		},
		RelationShipStatus: 'Single',
		Education: 'B.Tech',
	});

	const [religionDetails, setReligionDetails] = useState({
		Religion: 'Hindu',
		Caste: 'Bhramin',
		SubCaste: 'Madhava',
		Gotra: 'Something',
		Star: 'Something',
		KujaDosh: 'No',
	});

	let baseUrl = '';
	switch (process.env.REACT_APP_STATUS) {
		case 'DEV':
			baseUrl = process.env.REACT_APP_DEV_URL;
			break;
		case 'STAGE':
			baseUrl = process.env.REACT_APP_STAGE_URL;
			break;
		case 'PROD':
			baseUrl = process.env.REACT_APP_PROD_URL;
			break;
	}

	useEffect(() => {
		const getTokenDataAndNavigate = async () => {
			try {
				const tokenFromLocalStorage = localStorage.getItem('token');
				const result = await axios.post(`${baseUrl}/api/v1/user/verifyToken`, {
					token: tokenFromLocalStorage,
				});
				if (result.data === 'invalid') {
					navigate('/login');
				}
			} catch {
				navigate('/login');
			}
		};
		getTokenDataAndNavigate();
	}, []);

	const [activeImage, setActiveImage] = useState(
		'https://image.winudf.com/v2/image1/Y29tLmF2aW5kZXIuZGV2ZW4uYmxhbmtfc2NyZWVuXzFfMTU2NzE2OTAwMl8wMjk/screen-1.webp?fakeurl=1&type=.webp',
	);
	const [userImage, setUserImage] = useState(
		'https://image.winudf.com/v2/image1/Y29tLmF2aW5kZXIuZGV2ZW4uYmxhbmtfc2NyZWVuXzFfMTU2NzE2OTAwMl8wMjk/screen-1.webp?fakeurl=1&type=.webp',
	);

	const [profileDetails, setProfileDetails] = useState({
		name: 'USER',
		email: '',
		phone: '',
		bio: '',
		images: [],
		siblings: [],
		dateOfBirth: '',
		gender: '',
		height: 0,
		incomeRange: '',
		relationshipStatus: '',
		weight: 0,
		address: '',
		city: '',
		country: '',
		district: '',
		pinCode: '',
		state: '',
		caste: '',
		education: '',
		experience: {
			workTitle: '',
			company: '',
		},
		gotra: '',
		kujaDosh: 'No',
		motherToungue: '',
		religion: '',
		star: '',
		subCaste: '',
		parents: {
			fatherDetails: {
				name: '',
				occupation: '',
				age: '',
				isAlive: '',
			},
			motherDetails: {
				name: '',
				occupation: '',
				age: '',
				isAlive: '',
			},
		},
	});
	const [isUserAPremiumUser, setIsUserAPremiumUser] = useState(false);

	useEffect(() => {
		const profileDetailsApi = async () => {
			const headers = {
				authorization: localStorage.getItem('token'),
			};
			const urlParams = new URLSearchParams(window.location.search);
			const userId = urlParams.get('id');
			// alert(userId)

			const matchedData = await axios.get(
				`${baseUrl}/api/v1/matchRequest/getAcceptedRequest`,
				{
					headers,
				},
			);
			const currentUserDetails = await axios.get(
				`${baseUrl}/api/v1/profile/getProfileDetails`,
				{
					headers,
				},
			);
			console.log(currentUserDetails, 'this is current user details in view profiles');
			let matchesIds = [];

			if (matchedData.data.length > 0) {
				for (let i = 0; i < matchedData.data.length; i++) {
					const temp =
						currentUserDetails.data.result.authId === matchedData.data[i].requestedTo
							? matchedData.data[i].requestRaisedBy
							: matchedData.data[i].requestedTo;
					matchesIds.push(temp);
				}
				// setMatchesIds(updatedArray)
			}

			if (matchesIds.length > 0 && matchesIds.includes(userId)) {
				setIsUserAPremiumUser(true);
				try {
					const bodyParams = {
						userId,
					};
					const headers = {
						authorization: localStorage.getItem('token'),
					};
					const responseFromApi = await axios.post(
						`${baseUrl}/api/v1/profile/getProfileAllDetailsById`,
						bodyParams,
						{
							headers,
						},
					);

					const mainData = responseFromApi.data.result[0];
					setProfileDetails(mainData);
					setShowShimmer(false);
					setUserImage(
						mainData.images[0]
							? mainData.images[0]
							: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZ6487aImMfhXIOLgOlZtFLwi11LKkcVrIs4EQb6glGw&',
					);
					setBasicDetails({
						// id: mainData._id,
						Name: mainData.name,
						Phone: mainData.phone,
						Gender: mainData.gender,
						DOB: mainData.dateOfBirth,
						Height: mainData.height,
						Weight: mainData.weight,
						MotherToungue: mainData.motherToungue,
						IncomeRange: mainData.incomeRange,
					});

					setPersonalDetails({
						Parents: {
							Father: {
								name: mainData.parents.fatherDetails.name,
								age: mainData.parents.fatherDetails.age,
								occupation: mainData.parents.fatherDetails.occupation,
								isAlive: mainData.parents.fatherDetails.isAlive,
							},
							Mother: {
								name: mainData.parents.motherDetails.name,
								age: mainData.parents.motherDetails.age,
								occupation: mainData.parents.motherDetails.occupation,
								isAlive: mainData.parents.fatherDetails.isAlive,
							},
						},

						RelationShipStatus: mainData.relationshipStatus,
						Education: mainData.education,
					});
					setReligionDetails({
						Religion: mainData.religion,
						Caste: mainData.caste,
						SubCaste: mainData.subCaste,
						Gotra: mainData.gotra,
						Star: mainData.star,
						KujaDosh: mainData.kujaDosh.toString(),
					});

					setActiveImage(mainData.images[0]);
				} catch (e) {
					console.log(e);
				}
			} else {
				setIsUserAPremiumUser(false);
				setShowShimmer(false);
				try {
					const bodyParams = {
						userId,
					};
					const headers = {
						authorization: localStorage.getItem('token'),
					};
					const responseFromApi = await axios.post(
						`${baseUrl}/api/v1/profile/getProfileListingDetailsById`,
						bodyParams,
						{
							headers,
						},
					);
					console.log(responseFromApi, 'this is basic details api results');
					const mainData = responseFromApi.data.result[0];
					setBasicDetails({
						// id: mainData._id,
						Name: mainData.name,
						Phone: mainData.phone,
						Gender: mainData.gender,
						DOB: mainData.dateOfBirth,
						Height: mainData.height,
						Weight: mainData.weight,
						MotherToungue: mainData.motherToungue,
						IncomeRange: mainData.incomeRange,
					});
					setUserImage(
						mainData.images[0] != ''
							? mainData.images[0]
							: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZ6487aImMfhXIOLgOlZtFLwi11LKkcVrIs4EQb6glGw&',
					);
				} catch (e) {
					console.log(e);
				}
			}
		};
		profileDetailsApi();
	}, [reload]);

	const getLoggedUserId = async () => {
		let senderId = '';
		try {
			const verifyTokenResponse = await axios.post(`${baseUrl}/api/v1/user/verifyToken`, {
				token: localStorage.getItem('token'),
			});
			if (verifyTokenResponse.data === 'invalid') {
				alert('please login to send request');
			} else {
				senderId = verifyTokenResponse.data;
			}
		} catch (e) {
			console.log(e);
		}
		return senderId;
	};

	// this is sending request to profiles functionality
	const sendRequestApi = async (id) => {
		const receiverId = id;
		const userSenderId = await getLoggedUserId();
		const headers = {
			authorization: localStorage.getItem('token'),
		};

		try {
			const matchRequestApiResponse = await axios.post(
				`${baseUrl}/api/v1/matchRequest/sendMatchRequest`,
				{
					recieverId: receiverId,
					senderId: userSenderId.data,
				},
				{
					headers,
				},
			);
			if (
				matchRequestApiResponse.statusText == 'Accepted' &&
				matchRequestApiResponse.status == 202
			) {
				// alert('Request sent successfully');
				toast.success('Request Sent Successfully', {
					position: 'top-right',
					autoClose: 2000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'colored',
				});
				// window.location.reload();
				setReload((prevReload) => !prevReload);
			}
		} catch (e) {
			// alert('request already send');
			toast.warn('Request Already Sent', {
				position: 'top-right',
				autoClose: 2000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'dark',
			});
		}
	};

	const deductCredits = async (id) => {
		try {
			const headers = {
				authorization: localStorage.getItem('token'),
			};
			const responseFromApi = await axios.post(
				`${baseUrl}/api/v1/userSubscription/deductUserCredits`,
				null,
				{
					headers,
				},
			);

			if (responseFromApi.data.msg === 'NO ACTIVE SUBSCRIPTION FOUND') {
				// alert("PLEASE BUY MAIN SUBSCRIPTION TO SEND REQUEST")
				toast.warn('PLEASE BUY MAIN SUBSCRIPTION TO SEND REQUEST', {
					position: 'top-right',
					autoClose: 2000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'dark',
				});
			} else if (responseFromApi.data.msg === 'NO ACTIVE BOOSTER PACK FOUND') {
				// alert("PLEASE BUY BOOSTER SUBSCRIPTION TO SEND REQUEST")
				toast.warn('PLEASE BUY BOOSTER SUBSCRIPTION TO SEND REQUEST', {
					position: 'top-right',
					autoClose: 2000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'dark',
				});
			} else {
				// alert("credit succesfully deducted")
				sendRequestApi(id);
			}
		} catch (e) {
			console.log(e);
		}
	};
	const [requestsSent, setRequestsSent] = useState([]);

	useEffect(() => {
		const requestsSent = async () => {
			try {
				const headers = {
					authorization: localStorage.getItem('token'),
				};
				const requestsSentResponse = await axios.get(
					`${baseUrl}/api/v1/matchRequest/getMatchRequestSentDetails`,
					{
						headers,
					},
				);

				if (requestsSentResponse.data.length > 0) {
					//  requestsReceivedIds=requestsReceivedResponse.data.map((each)=>each._id)
					const updatedArray = [];

					for (let i = 0; i < requestsSentResponse.data.length; i++) {
						const userId = requestsSentResponse.data[i].requestedTo;
						updatedArray.push(userId);
					}
					setRequestsSent(updatedArray);
				} else {
					setRequestsSent([]);
				}
			} catch (e) {
				console.log(e);
			}
		};
		requestsSent();
	}, [reload]);

	const [inWishlist, setInWishList] = useState(false);

	const addToWishList = async (sendigToId) => {
		const userSenderId = await getLoggedUserId();
		// alert(`Add To WishList: ${sendigToId} by ${userSenderId.data}`);
		try {
			const headers = {
				authorization: localStorage.getItem('token'),
			};
			const result = await axios.post(
				`${baseUrl}/api/v1/wishList/addToWishList`,
				{
					recieverId: sendigToId,
					senderId: userSenderId.data,
				},
				{
					headers,
				},
			);
			if (result) {
				toast.success('Added To WishList!!', {
					position: 'top-right',
					autoClose: 2000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'colored',
				});
				// alert('Added To WishList!!');
			}
		} catch (err) {
			toast.warn(err.response.data.msg, {
				position: 'top-right',
				autoClose: 2000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'dark',
			});
			setInWishList(true);
			// alert(err.response.data.msg);
		}
	};

	if (showShimmer) {
		return (
			<div className='w-[100%]'>
				{/* <AsideBar /> */}
				{/* <div className={asideStatus ? `md:ml-[15rem] ` : `md:ml-[5rem] mt-[5rem] md:mt-0`}> */}
				{/* <DashboardHeader /> */}
				<div className='flex justify-center items-center min-h-[80vh]'>
					<div className='w-[75%]'>
						<div>
							<ShimmerContentBlock
								title
								text
								cta
								thumbnailWidth={370}
								thumbnailHeight={370}
							/>
							<ShimmerContentBlock
								title
								text
								cta
								thumbnailWidth={370}
								thumbnailHeight={370}
							/>
							<ShimmerContentBlock
								title
								text
								cta
								thumbnailWidth={370}
								thumbnailHeight={370}
							/>
							<ShimmerContentBlock
								title
								text
								cta
								thumbnailWidth={370}
								thumbnailHeight={370}
							/>
						</div>
					</div>
				</div>
			</div>
			// </div>
		);
	} else {
		return (
			<div className='w-[100%]'>
				{/* <AsideBar /> */}

				{/* <div className={asideStatus ? `md:ml-[15rem] ` : `md:ml-[5rem] mt-[5rem] md:mt-0`}> */}
				{/* <DashboardHeader />  */}

				<div className='mx-3 md:mx-0 md:ml-[3rem]'>
					<h4 className='text-xl md:text-4xl ml-9'>
						Know Your Perfect Life Partner Better.
					</h4>
					<div className='md:flex gap-5'>
						<div className='md:w-[30%] md:ml-10 mt-4'>
							<Card>
								<div>
									<div className='flex justify-center py-3'>
										<img
											src={profileDetails.images[0] ? activeImage : userImage}
											alt='NO IMAGE'
											className='w-[70%] rounded-xl border-4 border-black'
										/>
									</div>
									{isUserAPremiumUser == true && (
										<div className='py-3 flex justify-center gap-4'>
											{profileDetails.images.map((eachImage, index) => {
												return (
													<img
														src={
															profileDetails.images[index]
																? profileDetails.images[index]
																: 'https://wipy.tv/wp-content/uploads/2020/05/alcoholismo-de-tony-stark-en-el-mcu-1200x900.jpg'
														}
														alt='NO IMAGE'
														className='h-10 w-10 rounded-full border-2 border-black'
														onClick={() => {
															setActiveImage(
																profileDetails.images[index]
																	? profileDetails.images[index]
																	: 'https://wipy.tv/wp-content/uploads/2020/05/alcoholismo-de-tony-stark-en-el-mcu-1200x900.jpg',
															);
														}}
													/>
												);
											})}
										</div>
									)}
								</div>
								{isUserAPremiumUser === false && (
									<div className='mt-2'>
										{Object.keys(basicDetails).map((item) =>
											item != '_id' ? (
												item == 'Phone' ? (
													<div
														key={item._id}
														className='flex ml-16 justify-start gap-2'>
														<h5 className='text-start'>{item}:</h5>
														<p className='text-start'>
															{isUserAPremiumUser
																? basicDetails[item]
																: 'No Access'}
														</p>
													</div>
												) : (
													<div
														key={item.id}
														className='flex ml-16 justify-start gap-2'>
														<h5 className='text-start'>{item}:</h5>
														<p className='text-start'>
															{basicDetails[item]}
														</p>
													</div>
												)
											) : (
												<> </>
											),
										)}
									</div>
								)}

								<div className='mx-3 pb-3 flex flex-col gap-3'>
									{isUserAPremiumUser === false && (
										<Button
											color='danger'
											className='w-100'
											disabled={requestsSent.includes(AUTHID) ? true : false}
											isOutline={true}
											onClick={() => {
												deductCredits(AUTHID);
											}}>
											{requestsSent.includes(AUTHID)
												? 'Request Sent'
												: 'Send Match'}
										</Button>
									)}

									<Button
										onClick={() => addToWishList(AUTHID)}
										color='warning'
										isOutline={false}
										// disabled={inWishlist?true:false}
										className='w-100 text-black'>
										Add To WishList
									</Button>
								</div>
							</Card>
						</div>

						{isUserAPremiumUser ? (
							<div className='mt-20 md:w-[60%]'>
								<h3>Bio</h3>
								<p>{profileDetails.bio}</p>
								<div className='grid md:grid-cols-2'>
									<div>
										<h3 className='mt-5'>Basic Details:</h3>
										<div className='my-4'>
											{Object.keys(basicDetails).map((item) =>
												item != 'id' ? (
													<div
														key={item.id}
														className='flex justify-start gap-2'>
														<h5 className='text-start'>
															{item.toString() == 'Height'
																? 'Height [ in ft ]'
																: item.toString() == 'Weight'
																? 'Weight [ in Kgs ]'
																: item.toString() == 'MotherToungue'
																? 'Mother Tongue'
																: item.toString() == 'IncomeRange'
																? 'Income Range'
																: item}
															:
														</h5>
														<p className='text-start'>
															{basicDetails[item]}
														</p>
													</div>
												) : (
													<> </>
												),
											)}
										</div>
									</div>

									<div>
										<div>
											<h3 className='mt-5'>Personal Details:</h3>
											<div className='my-4'>
												{Object.keys(personalDetails).map((item) => {
													switch (item) {
														case 'Parents':
															return Object.keys(
																personalDetails[item],
															).map((subItem) => {
																const string =
																	personalDetails[item][subItem]
																		.name +
																	', ' +
																	personalDetails[item][subItem]
																		.age +
																	', ' +
																	personalDetails[item][subItem]
																		.occupation;

																return (
																	<div
																		key={
																			personalDetails[item].id
																		}
																		className='flex justify-start gap-2'>
																		<h5 className='text-start'>
																			{subItem}:
																		</h5>
																		<p className='text-start'>
																			{string}
																		</p>
																	</div>
																);
															});
														case 'Siblings':
															return Object.keys(
																personalDetails[item],
															).map((subItem) => {
																const string =
																	personalDetails[item][subItem]
																		.name +
																	', ' +
																	personalDetails[item][subItem]
																		.age +
																	', ' +
																	personalDetails[item][subItem]
																		.occupation;

																return (
																	<div
																		key={
																			personalDetails[item].id
																		}
																		className='flex justify-start gap-2'>
																		<h5 className='text-start'>
																			{subItem}:
																		</h5>
																		<p className='text-start'>
																			{string}
																		</p>
																	</div>
																);
															});
														default:
															return (
																<div
																	key={item.id}
																	className='flex justify-start gap-2'>
																	<h5 className='text-start'>
																		{item.toString() ==
																		'RelationShipStatus'
																			? 'Relationship Status'
																			: item}
																		:
																	</h5>
																	<p className='text-start'>
																		{personalDetails[item]}
																	</p>
																</div>
															);
													}
												})}
											</div>
											{/* <div>
											<h6 className='mt-5'>Siblings </h6>
	
													{
													   profileDetails.siblings.length > 0 && (
														<ul className="my-4">
															{
																profileDetails.siblings.map((eachItem)=>{
																	<li>`{eachItem.name},{eachItem.age},{eachItem.gender},{eachItem.isMarried}`</li>
																})
															}
														</ul>
													   )
													}
												</div>	 */}

											<div>
												<h3 className='mt-5'>Religion Details:</h3>
												<div className='my-4'>
													{Object.keys(religionDetails).map((item) =>
														item != 'id' ? (
															<div
																key={item.id}
																className='flex justify-start gap-2'>
																<h5 className='text-start'>
																	{item}:
																</h5>
																<p className='text-start'>
																	{religionDetails[
																		item
																	].toString() === 'true'
																		? 'Yes'
																		: religionDetails[
																				item
																		  ].toString() === 'false'
																		? 'No'
																		: religionDetails[item]}
																</p>
															</div>
														) : (
															<> </>
														),
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						) : (
							<div className='w-[100%] md:w-[60%]'>
								<div className='flex flex-col items-center min-h-[75vh]'>
									<img
										src='https://img.freepik.com/premium-vector/target-advertising-flat-illustration-design_203633-1095.jpg'
										alt='image'
										className='w-[60%] h-[60%]'
									/>
									<h3 className='px-10 text-center'>
										Complete Profile Details Will Be Visible Once Your Match
										Request Gets Accepted.
									</h3>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			//  </div>
		);
	}
};

export default ViewProfiles;
