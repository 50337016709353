import React, { Children, cloneElement, FC, ReactElement, ReactNode, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardFooterLeft,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardTitle,
	ICardProps,
} from './bootstrap/Card';
import Button from './bootstrap/Button';
import Popovers from './bootstrap/Popovers';
import useDarkMode from '../hooks/useDarkMode';
import { TColor } from '../type/color-type';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axios from 'axios';

export const WizardItem = ({ id, title, children, className, ...props }) => {
	return (
		<section
			id={id}
			className={classNames('wizard-item', className)}
			role='tabpanel'
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}>
			{children}
		</section>
	);
};

WizardItem.propTypes = {
	id: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
	title: PropTypes.string,
	className: PropTypes.string,
};
WizardItem.defaultProps = {
	className: undefined,
	title: undefined,
};

const Wizard = ({ children, onSubmit, isHeader, color, stretch, onNext, userID, ...props }) => {
	const { themeStatus } = useDarkMode();
	const [activeItemIndex, setActiveItemIndex] = useState(0);
	let baseUrl = '';
	switch (process.env.REACT_APP_STATUS) {
		case 'DEV':
			baseUrl = process.env.REACT_APP_DEV_URL;
			break;
		case 'STAGE':
			baseUrl = process.env.REACT_APP_STAGE_URL;
			break;
		case 'PROD':
			baseUrl = process.env.REACT_APP_PROD_URL;
			break;
	}

	const stage1ApiCall = async (bodyParams, headers) => {
		try {
			const result = await axios.post(
				`${baseUrl}/api/v1/profile/createProfileStage01`,

				bodyParams,
				{ headers },
			);
			if (result.status === 202) {
				setActiveItemIndex(activeItemIndex + 1);
			}
		} catch {
			console.log('Error');
		}
	};

	const stage2ApiCall = async (bodyParams, headers) => {
		try {
			const result = await axios.post(
				`${baseUrl}/api/v1/profile/createProfileStage02`,

				bodyParams,
				{ headers },
			);
			if (result.status === 202) {
				setActiveItemIndex(activeItemIndex + 1);
			}
		} catch {
			console.log('Error');
		}
	};

	const stage3ApiCall = async (bodyParams, headers) => {
		try {
			const result = await axios.post(
				`${baseUrl}/api/v1/profile/createProfileStage03`,

				bodyParams,
				{ headers },
			);
			if (result.status === 202) {
				setActiveItemIndex(activeItemIndex + 1);
			}
		} catch {
			console.log('Error');
		}
	};

	const stage4ApiCall = async (bodyParams, headers) => {
		try {
			onNext.setShowLoader(true);
			// const images = await axios.get(`${baseUrl}/api/v1/profile/getProfileDetails`, {
			// 	headers,
			// });
			// const imagesFromDB = images.data.result.images;
			// const newImages = [...imagesFromDB];
			// for (let i = 0; i < 5; i++) {
			// 	if (bodyParams.images[i]) {
			// 		newImages[i] = bodyParams.images[i];
			// 	} else if (imagesFromDB[i]) {
			// 		newImages[i] = imagesFromDB[i];
			// 	} else {
			// 		newImages[i] = '';
			// 	}
			// }
			// bodyParams.images = newImages;
			// console.log(bodyParams);
			// const result = await axios.post(
			// 	`${baseUrl}/api/v1/profile/createProfileStage04`,

			// 	bodyParams,
			// 	{ headers },
			// );
			// if (result.status === 202) {
			// 	setActiveItemIndex(activeItemIndex + 1);
			// }
			setActiveItemIndex(activeItemIndex + 1);
			onNext.setShowLoader(false);
		} catch (e) {
			console.log(e);
		}
	};

	const stage5ApiCall = async (bodyParams, headers) => {
		try {
			const result = await axios.post(
				`${baseUrl}/api/v1/profile/createProfileStage05`,

				bodyParams,
				{ headers },
			);
			if (result.status === 202) {
				setActiveItemIndex(activeItemIndex + 1);
			}
		} catch {
			console.log('Error');
		}
	};

	const stage6ApiCall = async (bodyParams, headers) => {
		try {
			const result = await axios.post(
				`${baseUrl}/api/v1/profile/createProfileStage06`,

				bodyParams,
				{ headers },
			);
			if (result.status === 202) {
				console.log(result);
				setActiveItemIndex(0);
				location.reload();
			}
		} catch {
			console.log('Error');
		}
	};

	const childCount = Array.isArray(children) ? children?.length : 1;

	const getTitleName = (i) => {
		return `Step ${i + 1}`;
	};

	const prevBtn = !!activeItemIndex && (
		<Button
			color={color}
			onClick={() => {
				setActiveItemIndex(activeItemIndex - 1);
			}}>
			Previous
		</Button>
	);

	const nextBtn = (
		<>
			<Button
				className={classNames({ 'd-none': childCount === activeItemIndex + 1 }, 'me-0')}
				aria-hidden={childCount === activeItemIndex + 1}
				color={color}
				onClick={() => {
					console.log(onNext);
					switch (activeItemIndex) {
						case 0:
							if (onNext.name && onNext.phoneNumber && onNext.bio && userID) {
								const headers = {
									authorization: localStorage.getItem('token'),
								};
								const bodyData = {
									name: onNext.name,
									phone: onNext.phoneNumber,
									alternatePhone: onNext.alternatePhone,
									bio: onNext.bio,
									_id: userID.data,
								};
								stage1ApiCall(bodyData, headers);
							} else {
								// alert('Please fill All The Fields');
								toast.warn('Please fill All The Fields', {
									position: 'top-right',
									autoClose: 5000,
									hideProgressBar: false,
									closeOnClick: true,
									pauseOnHover: true,
									draggable: true,
									progress: undefined,
									theme: 'colored',
								});
							}
							break;
						case 1:
							if (
								onNext.gender &&
								onNext.dateOfBirth &&
								onNext.height &&
								onNext.weight &&
								onNext.incomeRange &&
								onNext.relationshipStatus &&
								userID
							) {
								const headers = {
									authorization: localStorage.getItem('token'),
								};
								const bodyData = {
									_id: userID.data,
									gender: onNext.gender,
									dateOfBirth: onNext.dateOfBirth,
									height: onNext.height,
									weight: onNext.weight,
									incomeRange: onNext.incomeRange,
									relationshipStatus: onNext.relationshipStatus,
								};
								stage2ApiCall(bodyData, headers);
							} else {
								// alert('Please fill All The Fields');
								toast.warn('Please fill All The Fields', {
									position: 'top-right',
									autoClose: 5000,
									hideProgressBar: false,
									closeOnClick: true,
									pauseOnHover: true,
									draggable: true,
									progress: undefined,
									theme: 'colored',
								});
							}
							break;

						case 2:
							if (
								onNext.address &&
								onNext.country &&
								onNext.state &&
								onNext.district &&
								onNext.city &&
								onNext.pincode &&
								userID
							) {
								if (onNext.pincode.length == 6) {
									const headers = {
										authorization: localStorage.getItem('token'),
									};
									const bodyData = {
										_id: userID.data,
										address: onNext.address,
										state: onNext.state,
										country: onNext.country,
										district: onNext.district,
										city: onNext.city,
										pinCode: onNext.pincode,
									};
									stage3ApiCall(bodyData, headers);
								} else {
									toast.warn('Please Enter Proper Pincode', {
										position: 'top-right',
										autoClose: 5000,
										hideProgressBar: false,
										closeOnClick: true,
										pauseOnHover: true,
										draggable: true,
										progress: undefined,
										theme: 'colored',
									});
								}
							} else {
								// alert('Please fill All The Fields');
								toast.warn('Please fill All The Fields', {
									position: 'top-right',
									autoClose: 5000,
									hideProgressBar: false,
									closeOnClick: true,
									pauseOnHover: true,
									draggable: true,
									progress: undefined,
									theme: 'colored',
								});
							}
							break;

						case 3:
							if (
								onNext.images &&
								onNext.images[0] &&
								onNext.images[1] &&
								onNext.images[2] &&
								userID
							) {
								const headers = {
									authorization: localStorage.getItem('token'),
								};
								const bodyData = {
									_id: userID.data,
									images: onNext.images,
								};
								stage4ApiCall(bodyData, headers);
							} else {
								// alert('Please fill All The Fields');
								toast.warn('Please fill All The Fields', {
									position: 'top-right',
									autoClose: 5000,
									hideProgressBar: false,
									closeOnClick: true,
									pauseOnHover: true,
									draggable: true,
									progress: undefined,
									theme: 'colored',
								});
							}
							break;

						case 4:
							if (
								onNext.education &&
								onNext.workTitle &&
								onNext.company &&
								onNext.religion &&
								onNext.motherToungue &&
								onNext.caste &&
								onNext.subCaste &&
								onNext.gotra &&
								onNext.star &&
								onNext.kujaDosh != undefined &&
								userID
							) {
								const headers = {
									authorization: localStorage.getItem('token'),
								};

								const bodyData = {
									_id: userID.data,
									education: onNext.education,
									workTitle: onNext.workTitle,
									company: onNext.company,
									religion: onNext.religion,
									motherToungue: onNext.motherToungue,
									caste: onNext.caste,
									subCaste: onNext.subCaste,
									gotra: onNext.gotra,
									star: onNext.star,
									kujaDosh: onNext.kujaDosh,
								};
								console.log(
									bodyData,
									'this is body data we are sending from frontend',
								);
								stage5ApiCall(bodyData, headers);
							} else {
								// alert('Please fill All The Fields');
								toast.warn('Please fill All The Fields', {
									position: 'top-right',
									autoClose: 5000,
									hideProgressBar: false,
									closeOnClick: true,
									pauseOnHover: true,
									draggable: true,
									progress: undefined,
									theme: 'colored',
								});
							}
							break;
					}
				}}>
				Save & Proceed
			</Button>
			<Button
				className={classNames({ 'd-none': childCount !== activeItemIndex + 1 })}
				aria-hidden={childCount !== activeItemIndex + 1}
				type='button'
				onClick={(e) => {
					// alert(onNext.fatherDetails.name);
					e.preventDefault();
					if (
						onNext.fatherDetails &&
						onNext.motherDetails &&
						onNext.fatherDetails.name &&
						onNext.fatherDetails.age &&
						onNext.fatherDetails.occupation &&
						onNext.motherDetails.name &&
						onNext.motherDetails.age &&
						onNext.motherDetails.occupation &&
						onNext.siblingDetails &&
						userID
					) {
						const headers = {
							authorization: localStorage.getItem('token'),
						};
						const bodyData = {
							_id: userID.data,
							fatherDetails: onNext.fatherDetails,
							motherDetails: onNext.motherDetails,
							siblingDetails: onNext.siblingDetails,
						};
						console.log(bodyData);

						stage6ApiCall(bodyData, headers);
					} else {
						// alert('Please fill All The Fields');
						toast.warn('Please fill All The Fields', {
							position: 'top-right',
							autoClose: 5000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
							theme: 'colored',
						});
					}
				}}
				color={color}>
				Submit
			</Button>
		</>
	);

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<Card stretch={stretch} tag='form' onSubmit={onSubmit} {...props}>
			{!!isHeader && (
				<CardHeader>
					<CardLabel icon='Assignment' iconColor={color}>
						{Children.map(children, (child, index) => (
							<CardTitle
								key={child?.props?.id}
								className={index !== activeItemIndex ? 'd-none' : undefined}>
								{child?.props.title || getTitleName(index)}
							</CardTitle>
						))}
					</CardLabel>
					{isHeader === 'withButton' && (
						<CardActions>
							{prevBtn}
							{nextBtn}
						</CardActions>
					)}
				</CardHeader>
			)}
			<CardBody isScrollable={!!stretch}>
				<div className='wizard-progress position-relative'>
					<div className='progress'>
						<div
							className={classNames('progress-bar', {
								[`bg-${color}`]: color !== 'primary',
							})}
							role='progressbar'
							style={{ width: `${(100 / (childCount - 1)) * activeItemIndex}%` }}
							aria-valuenow={(100 / (childCount - 1)) * activeItemIndex}
							aria-valuemin={0}
							aria-valuemax={100}
							aria-label='progress'
						/>
					</div>
					{Children.map(children, (child, index) => (
						<Popovers
							key={child.props.id}
							desc={child.props.title || getTitleName(index)}
							trigger='hover'>
							<button
								type='button'
								className={classNames(
									'wizard-progress-btn',
									'position-absolute p-0 top-0',
									'translate-middle',
									'btn btn-sm',
									{
										[`btn-${color}`]: activeItemIndex >= index,
										[`btn-${themeStatus}`]: activeItemIndex < index,
									},
									'rounded-pill',
								)}
								style={{
									left: `${(100 / (childCount - 1)) * index}%`,
								}}
								// onClick={() => setActiveItemIndex(index)}
							>
								{index + 1}
							</button>
						</Popovers>
					))}
				</div>

				<div className='wizard'>
					{Children.map(children, (child, index) =>
						cloneElement(child, {
							className: index !== activeItemIndex ? 'd-none' : '',
							'aria-hidden': index !== activeItemIndex,
						}),
					)}
				</div>
			</CardBody>
			<CardFooter>
				<CardFooterLeft>{prevBtn}</CardFooterLeft>
				<CardFooterRight>{nextBtn}</CardFooterRight>
			</CardFooter>
		</Card>
	);
};

Wizard.propTypes = {
	// @ts-ignore
	children: PropTypes.node.isRequired,
	color: PropTypes.oneOf([
		null,
		'primary',
		'secondary',
		'success',
		'info',
		'warning',
		'danger',
		'light',
		'dark',
	]),
	// @ts-ignore
	isHeader: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['withButton'])]),
	onSubmit: PropTypes.func.isRequired,
	// @ts-ignore
	stretch: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['full', 'semi'])]),
};
Wizard.defaultProps = {
	isHeader: false,
	color: 'primary',
	stretch: undefined,
};

export default Wizard;
