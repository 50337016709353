import React, { useEffect, useContext, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AsideBar from '../customLayout/AsideBar';
import CustomHeader from '../customLayout/Header';
import ThemeContext from '../../contexts/themeContext';
import DashboardHeader from '../_layout/_headers/DashboardHeader';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../components/bootstrap/Card';
import Button from '../../components/bootstrap/Button';
import { Favorite, FavoriteBorder } from '../../components/icon/material-icons';
import Icon from '../../components/icon/Icon';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ShimmerContentBlock } from "react-shimmer-effects"

const staticData = [
	{
		id: '#001',
		name: 'Mithun S',
		age: 21,
		profession: 'Software Developer',
		salary: '10L - 20L',
		gothra: 'Bhargava Gotram',
		star: 'Revati nakshatra',
		caste: 'Bhramin',
		subCaste: 'Madhavas',
	},
	{
		id: '#001',
		name: 'Mithun S',
		age: 21,
		profession: 'Software Developer',
		salary: '10L - 20L',
		gothra: 'Bhargava Gotram',
		star: 'Revati nakshatra',
		caste: 'Bhramin',
		subCaste: 'Madhavas',
	},
];

const MyMatches = () => {
	const navigate = useNavigate();
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);

	let baseUrl = '';
	switch (process.env.REACT_APP_STATUS) {
		case 'DEV':
			baseUrl = process.env.REACT_APP_DEV_URL;
			break;
		case 'STAGE':
			baseUrl = process.env.REACT_APP_STAGE_URL;
			break;
		case 'PROD':
			baseUrl = process.env.REACT_APP_PROD_URL;
			break;
	}

	useEffect(() => {
		const getTokenDataAndNavigate = async () => {
			try {
				const tokenFromLocalStorage = localStorage.getItem('token');
				const result = await axios.post(`${baseUrl}/api/v1/user/verifyToken`, {
					token: tokenFromLocalStorage,
				});
				if (result.data === 'invalid') {
					navigate('/login');
				}
			} catch {
				navigate('/login');
			}
		};
		// getTokenDataAndNavigate();
	}, []);

	const [isProfileCompleted, setIsProfileCompleted] = useState("");
	
	useEffect(()=>{
		const getAllProfiles = async ()=>{
			try{
				const headers = {
					authorization: localStorage.getItem('token'),
				};
				const responseFromApi = await axios.get(`${baseUrl}/api/v1/profile/getProfileDetails`,{
					headers,
				});
				const {parents} = responseFromApi.data.result;
				if(parents!=undefined){
					setIsProfileCompleted("true")
				}
				else{
					setIsProfileCompleted("false")
				}
			}
			catch(e){
				console.log(e)
			}	
		}
		getAllProfiles()
	},[])

	const [matchesData, setMatchesData]= useState([])
	const [ matchesIds,setMatchesIds]= useState([])
	const [reload,setReload]= useState(false)
	const [showFallback,setShowFallback]= useState(null)

	useEffect(()=>{
		const myMatches = async ()=>{
			try{
				const headers = {
					authorization: localStorage.getItem('token'),
				};
				const responseFromApi = await axios.get(`${baseUrl}/api/v1/matchRequest/getAcceptedRequest`,{
					headers,
				});
				console.log("this is my matches response P.N.T");
				console.log(responseFromApi);
				const currentUserDetails = await axios.get(`${baseUrl}/api/v1/profile/getProfileDetails`,{
					headers,
				})
				//console.log(currentUserDetails.data.result.authId);

				if(responseFromApi.data.length>0){
					
					const updatedArray = []
					const ids = []
					for(let i = 0 ; i <responseFromApi.data.length; i++){
					  const userId = currentUserDetails.data.result.authId === responseFromApi.data[i].requestedTo ? responseFromApi.data[i].requestRaisedBy : responseFromApi.data[i].requestedTo


						const userData = await axios.post(`${baseUrl}/api/v1/profile/getProfileListingDetailsById`,{
							userId,
						},{
							headers,
						});
						console.log(userData.data.result[0])
						updatedArray.push(userData.data.result[0])
						ids.push(responseFromApi.data[i]._id)
					}
					setMatchesData(updatedArray)
					setMatchesIds(ids)
					setShowFallback(false)
				}
				else{
					setMatchesData([])
					setMatchesIds([])
					setShowFallback(true)
				}
			}
			catch(e){
				console.log(e)
			}	
		}
		myMatches()
	},[reload])

	console.log(matchesData);
	console.log(matchesIds);
  

	const block = async (id)=>{
		const requestId = matchesIds[id]

		try{
			const headers={
				authorization: localStorage.getItem("token")
			}
			const response = await axios.post(`${baseUrl}/api/v1/matchRequest/blockUser`,{
				requestId,
			},{
				headers,
			})

			if(response){
				// alert("user blocked successfully")
				toast.success('user blocked successfully', {
					position: "top-right",
					autoClose: 2000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "dark",
					});

				setMatchesIds((prevItems) => prevItems.filter((item) => item !== requestId));
				setReload(prevReload=>!prevReload)
				// window.location.reload()
			}

		}
		catch(e){
			console.log(e)
		}
		
	}
	if(isProfileCompleted=="true" && matchesData.length>0){
			return (
				<div className='w-[100%]'>
					{/* <AsideBar /> */}
					 {/* <div className={asideStatus ? `md:ml-[15rem] ` : `md:ml-[5rem] mt-[5rem] md:mt-0`}> */}
						{/* <DashboardHeader /> */}
						<div className='mx-3 md:mx-0 md:ml-[2rem]'>
							<h1 className='ml-5'>Your Matches.</h1>
							<div className='flex flex-col items-center'>
								{matchesData.map((data,index) =>{
									const blockUser=()=>{
						                 block(index)
									} 
								
								return(
									<Card key={data.id} className='mt-10 md:w-[90%]'>
										<div className='flex flex-col md:flex-row my-3 mx-5 gap-5 items-center'>
										<img
													src={data.images[0]!="" ? data.images[0]:'https://upload.wikimedia.org/wikipedia/commons/d/d1/Image_not_available.png?20210219185637'}
													alt='Tony Stark'
													className='w-full md:w-40 md:h-40 rounded'
												/>
											<div className='w-[100%]'>
												{/* <div className='inline absolute right-10'>
												<Icon
													icon='FavoriteBorder'
													color='secondary'
													size={'2x'}></Icon>
											</div> */}
												<h1>{data.name}</h1>
												<div className='md:flex gap-10 w-[100%]'>
													<div className='md:w-[50%]'>
														<h5>
														{Math.floor((new Date() - new Date(data.dateOfBirth)) / (365.25 * 24 * 60 * 60 * 1000))}, {data.experience.workTitle}
														</h5>
														<p>
															{data.gotra}, {data.star}
														</p>
													</div>
													<div>
														<h5>
															Salary:{' '}
															<span className='font-normal'>
																{data.incomeRange}
															</span>
														</h5>
														<p>
															{data.caste}, {data.subCaste}
														</p>
													</div>
												</div>
												<div className='grid grid-cols-1  md:grid-cols-2 md:gap-3 '>
												<div className='m-2'>
													<Link to={`/viewProfile?id=${data.authId}`} className='w-[45%]'>
														<Button
															style={{backgroundColor:"#ffae00b5" , color:"black"}}
															isOutline={false}
															className='w-full' >
															View Profile
														</Button>
													</Link>
													</div>
		
													<div className='m-2'>
														<Button
															color='danger'
															isOutline={true}
															className='w-full' onClick={blockUser}>
															Block User
														</Button>
													</div>
												
												</div>
											</div>
										</div>
									</Card>
								)})}
							</div>
						</div>
						
					</div>
				//  </div>
			);

		}
	else if(showFallback&&isProfileCompleted=="true"){
		  return (
		            <div className='w-[100%]'>
						 {/* <AsideBar />  */}
						{/* <div className={asideStatus ? `md:ml-[15rem] ` : `md:ml-[5rem] mt-[5rem] md:mt-0`}> */}
							{/* <DashboardHeader />  */}
							<div className='mx-3 md:mx-0 md:ml-[2rem]'>
								<h1 className='ml-5'>Your Matches.</h1>
								<div className='flex flex-col items-center'>
								<div className='w-[75%]'>
							<Card className='mt-3'>
								<CardBody className='mt-5'>
									<div className='flex flex-col items-center justify-center gap-3'>
										<div style={{display:"flex",justifyContent:"center",alignItems:"center",width:"100%"}}>
											<img
												src={
													'https://img.freepik.com/free-vector/young-couple-using-tablet_603843-987.jpg?w=740&t=st=1691727327~exp=1691727927~hmac=b31ece1d7b5b132c636425ed1ca18021a102278957e7dee53e089036a0b7a78d'
												}
												alt='USer Image'
												className='h-52  rounded-full bg-purple-100'
											/>
										</div>
										<div className='flex flex-col items-center gap-3 '>
											<div className='flex items-center gap-3 '>
												<h3 className='text-center'>
													Discover New Possibilities
												</h3>
												{/* <span className='-mt-2'>
													<Icon color='warning' icon='PersonSearch' size={'3x'} />
												</span> */}
											</div>
											<div>
												<p className='text-center'>
												While your perfect match might be taking a moment to arrive, 
												this is the perfect opportunity to dive into a world of diverse profiles. 
												Every connection is a step closer to discovering your ideal partner. 
												Explore these profiles, broaden your horizons, and open the door to exciting new beginnings!
												</p>
											</div>
											<div className='w-[100%] flex justify-center'>
												<Button className='w-[75%]' color='warning' 
												onClick={()=>{
													navigate("/searchProfiles")
												}} >
													Explore Interests
												</Button>
											</div>
										</div>
									</div>
								</CardBody>
							</Card>
								 </div>
								</div>
							</div>
							
						</div>
				// </div> 
		);
	}
	  else if( isProfileCompleted == 'false'){
		return(
			<div className='w-[100%]'>
		{/* <AsideBar />  */}
			{/* <div className={asideStatus ? `md:ml-[15rem] ` : `md:ml-[5rem] mt-[5rem] md:mt-0`}> */}
				{/* <DashboardHeader />  */}
			<div className="flex justify-center items-center min-h-[80vh]">
				
				<div className='w-[75%]'>
					<Card>
						<CardBody>
							<div className='flex flex-col items-center justify-center gap-3'>
								<div>
									<img
										src={
											'https://facit-modern.omtanke.studio/static/media/wanna7.c92d88f942e3d84190de.webp'
										}
										alt='USer Image'
										className='w-36 h-36 border-black border-1 rounded-full bg-purple-100'
									/>
								</div>
								<div className='flex flex-col items-center gap-3 '>
									<div className='flex items-center gap-3 '>
										<h3 className='text-center'>
											Find Your Perfect Match: Complete Your Profile Now!{' '}
										</h3>
										{/* <span className='-mt-2'>
											<Icon color='warning' icon='AccountBox' size={'3x'} />
										</span> */}
									</div>
									<div>
										<p className='text-center'>
											Please complete your profile to continue. By
											providing us with comprehensive details about
											yourself, your interests, and your preferences, you
											enhance your chances of discovering the perfect
											match tailored to your unique personality and
											aspirations.
										</p>
									</div>
									<div className='w-[100%] flex justify-center'>
										<Button className='w-[75%]' color='warning' 
										onClick={()=>{
											navigate("/")
										}} >
											Complete Your Profile Now
										</Button>
									</div>
								</div>
							</div>
						</CardBody>
					</Card>
				</div>
			</div>
				
			</div>
		// </div> 

		)
	  }
	  else if(isProfileCompleted==""){
		return(
			<div className='w-[100%]'>
			{/* <AsideBar />  */}
			{/* <div className={asideStatus ? `md:ml-[15rem] ` : `md:ml-[5rem] mt-[5rem] md:mt-0`}> */}
				{/* <DashboardHeader />  */}
			<div className="flex justify-center items-center min-h-[80vh]">
				
				<div className='w-[75%]'>
				<div>
			 <ShimmerContentBlock
			title
			text
			cta
			thumbnailWidth={370}
			thumbnailHeight={370}
		  />
		   <ShimmerContentBlock
			title
			text
			cta
			thumbnailWidth={370}
			thumbnailHeight={370}
		  />
		   <ShimmerContentBlock
			title
			text
			cta
			thumbnailWidth={370}
			thumbnailHeight={370}
		  />
		   <ShimmerContentBlock
			title
			text
			cta
			thumbnailWidth={370}
			thumbnailHeight={370}
		  />
	
		  </div>
					
				</div>
			</div>	
			</div>
		//  </div>
			
		);
	  }
};

export default MyMatches;
