import React, { useLayoutEffect, useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Header, { HeaderLeft, HeaderRight } from '../../../layout/Header/Header';
import Popovers from '../../../components/bootstrap/Popovers';
import Button, { IButtonProps } from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import LANG, { getLangWithKey, ILang } from '../../../lang';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import showNotification from '../../../components/extras/showNotification';
import Icon from '../../../components/icon/Icon';
import Spinner from '../../../components/bootstrap/Spinner';
import InputGroup from '../../../components/bootstrap/forms/InputGroup';
import Input from '../../../components/bootstrap/forms/Input';
import { useSearchContext } from '../../../contexts/searchContext';

let baseUrl = '';
switch (process.env.REACT_APP_STATUS) {
	case 'DEV':
		baseUrl = process.env.REACT_APP_DEV_URL;
		break;
	case 'STAGE':
		baseUrl = process.env.REACT_APP_STAGE_URL;
		break;
	case 'PROD':
		baseUrl = process.env.REACT_APP_PROD_URL;
		break;
}

const DashboardHeader = () => {
	const [userData, setUserData] = useState({
		image: 'https://facit-modern.omtanke.studio/static/media/wanna7.c92d88f942e3d84190de.webp',
		name: 'USER',
	});
	const navigate = useNavigate();

	useEffect(() => {
		const getUserImageAndSetInHeader = async () => {
			try {
				const headers = {
					authorization: localStorage.getItem('token'),
				};
				const response = await axios.get(`${baseUrl}/api/v1/profile/getProfileDetails`, {
					headers,
				});

				console.log(response.data.result);
				if (response.data.result) {
					const userImage = response.data.result.images[0];
					const userName = response.data.result.name;
					setUserData({
						...userData,
						name: userName,
						image: userImage || userData.image,
					});
				}
			} catch (e) {
				console.log(e);
			}
		};
		getUserImageAndSetInHeader();
	}, []);

	const { name, setName } = useSearchContext();
	const [tempN, setTempN] = useState('');

	const handleSearchInputChange = (event) => {
		const inputName = event.target.value;
		setTempN(inputName);
	};

	const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	const styledBtn = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};

	const { i18n } = useTranslation();

	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng).then(() =>
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon={getLangWithKey(lng)?.icon} size='lg' className='me-1' />
					<span>{`Language changed to ${getLangWithKey(lng)?.text}`}</span>
				</span>,
				'You updated the language of the site. (Only "Aside" was prepared as an example.)',
			),
		);
	};

	/**
	 * Language attribute
	 */
	useLayoutEffect(() => {
		document.documentElement.setAttribute('lang', i18n.language);
	});

	const [userSubDetails, setUserSubDetails] = useState([]);
	const [activePackName, setActivePackName] = useState('');
	const [activePackData, setActivePackData] = useState({});

	useEffect(() => {
		const getUserSubscriptionDetails = async () => {
			try {
				const headers = {
					authorization: localStorage.getItem('token'),
				};
				const resultApi = await axios.get(
					`${baseUrl}/api/v1/userSubscription/getSubscriptionDetails`,
					{ headers },
				);

				for (let i = 0; i < resultApi.data.result.length; i++) {
					if (resultApi.data.result[i]._id === resultApi.data.ActiveSubscriptionPack) {
						setActivePackData(resultApi.data.result[i]);
						setActivePackName(resultApi.data.result[i].packageId.name);
					}
				}
				setUserSubDetails(resultApi.data);
				setUserDetails(null, resultApi.data.result[i].packageId.name, null);
			} catch (e) {
				console.log(e);
			}
		};
		getUserSubscriptionDetails();
	}, []);

	return (
		<Header>
			<div className='w-100'>
				<HeaderRight>
					<div className='flex items-center justify-between mx-3'>
						<div className='w-[80%] md:w-[60%]'>
							<InputGroup>
								<Button color='light'>
									<Icon icon='search' size='2x' />
								</Button>
								<Input
									type='text'
									placeholder='Look For Profiles'
									value={tempN}
									onChange={handleSearchInputChange}
								/>
								<Button
									style={{ backgroundColor: '#ffae00b5', color: 'black' }}
									onClick={() => {
										setName(tempN);
										setTempN('');
										navigate('/searchProfiles');
									}}>
									{' '}
									Search
								</Button>
							</InputGroup>
						</div>

						<div className='flex flex-row justify-between items-center'>
							<div style={{ width: 60, height: 60 }}>
								<img
									src={userData.image}
									alt='No Profile Image selected please upload in 1st Place'
									style={{ width: 50, height: 50, borderRadius: '50%' }}
								/>
							</div>
							<div className='hidden md:block'>
								<h5 style={{ fontWeight: 600, color: '#700000' }}>
									{userData.name}
								</h5>
								<p style={{ fontWeight: 600, color: '#ffae00b5' }}>
									{activePackName != '' ? activePackName : 'BASIC'}
								</p>
							</div>
						</div>
					</div>
				</HeaderRight>
			</div>
		</Header>
	);
};

export default DashboardHeader;
